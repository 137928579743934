<template>
  <AuthLayout>
    <template #content>
      <Loader v-if="loading" />
      <div class="md:w-[350px] md:mx-auto">
        <img src="/icons/logo.svg" alt="Logo UNIT" class="h-7 w-auto object-contain object-left-top mb-5 mr-auto" />
      </div>
      <div class="registration-page__half--wrap">
        <template v-if="!showSMSCode">
          <h1 class="title self-start mb-8">{{ mainTitle }}</h1>
          <ul class="flex w-full gap-2 sm:gap-6 mb-5 justify-between">
            <li
              class="account-type flex-grow max-w-[100px] sm:min-w-[112px] h-[132px]"
              :class="isActiveTab(EAccountTypes.customer)"
              @click.prevent="setActiveTab(EAccountTypes.customer)"
            >
              <div class="circle">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.4814 6.99433C15.8891 6.99433 16.2195 6.66379 16.2195 6.25622C16.2195 5.84845 15.8891 5.51811 15.4814 5.51811C15.0738 5.51811 14.7433 5.84845 14.7433 6.25622C14.7433 6.66379 15.0738 6.99433 15.4814 6.99433Z"
                    fill="#A1AEF6"
                  />
                  <path
                    d="M26.7718 7.67336H19.1809V5.13426C19.1803 4.47997 18.9199 3.85248 18.4572 3.38989C17.9944 2.92713 17.3671 2.66681 16.7126 2.66602H14.2446C13.5901 2.66684 12.9628 2.92715 12.5 3.38989C12.0372 3.85244 11.7769 4.47997 11.7763 5.13426V7.67336H4.23857C3.46835 7.67418 2.72982 7.98042 2.18509 8.52514C1.64037 9.06986 1.3341 9.8084 1.33331 10.5786V26.4274C1.33414 27.1976 1.64038 27.9362 2.18509 28.4809C2.72982 29.0256 3.46835 29.3319 4.23857 29.3327H26.7715C27.5417 29.3319 28.2803 29.0256 28.825 28.4809C29.3697 27.9362 29.676 27.1976 29.6768 26.4274V10.5786C29.6759 9.8084 29.3697 9.06986 28.825 8.52514C28.2803 7.98041 27.5417 7.67415 26.7715 7.67336H26.7718ZM14.2419 4.13048H16.7101C16.9733 4.13048 17.2256 4.2349 17.4116 4.42107C17.5975 4.60704 17.7022 4.85932 17.7022 5.12254V10.865H13.2529V5.13414C13.2529 4.87114 13.3573 4.61866 13.5433 4.43267C13.7294 4.2467 13.9817 4.14208 14.2449 4.14208L14.2419 4.13048ZM28.2011 26.4272C28.2011 26.8064 28.0506 27.1697 27.7826 27.4378C27.5145 27.7057 27.151 27.8563 26.7721 27.8563H4.23912C3.86018 27.8563 3.49669 27.7057 3.22854 27.4378C2.96061 27.1697 2.81006 26.8063 2.81006 26.4272V10.5784C2.81006 10.1993 2.96061 9.83597 3.22854 9.56782C3.49668 9.29989 3.86016 9.14934 4.23912 9.14934H11.7768V10.8618H10.3743C9.96673 10.8618 9.63619 11.1923 9.63619 11.5999C9.63619 12.0074 9.96673 12.338 10.3743 12.338H20.5839C20.9915 12.338 21.3221 12.0074 21.3221 11.5999C21.3221 11.1923 20.9915 10.8618 20.5839 10.8618H19.1814V9.14934H26.7724C27.1513 9.14934 27.5148 9.29989 27.783 9.56782C28.0509 9.83596 28.2014 10.1993 28.2014 10.5784L28.2011 26.4272Z"
                    fill="#A1AEF6"
                  />
                  <path
                    d="M11.9859 20.1C12.536 19.5289 12.8387 18.764 12.8286 17.9711C12.8187 17.1785 12.4966 16.4214 11.9325 15.8643C11.3682 15.3073 10.6073 14.9948 9.81436 14.9948C9.02147 14.9948 8.26048 15.3072 7.69641 15.8643C7.13212 16.4214 6.8102 17.1785 6.80013 17.9711C6.79024 18.764 7.09299 19.5289 7.64284 20.1C6.91174 20.493 6.30069 21.0764 5.87438 21.7885C5.44828 22.5007 5.22279 23.315 5.22194 24.145C5.22194 24.5525 5.55228 24.8831 5.96005 24.8831C6.36762 24.8831 6.69816 24.5525 6.69816 24.145C6.69816 23.0316 7.29213 22.0027 8.25637 21.4461C9.22039 20.8894 10.4083 20.8894 11.3725 21.4461C12.3367 22.0028 12.9307 23.0316 12.9307 24.145C12.9307 24.5525 13.2613 24.8831 13.6688 24.8831C14.0764 24.8831 14.4069 24.5525 14.4069 24.145C14.4061 23.315 14.1806 22.5007 13.7543 21.7885C13.3282 21.0764 12.7169 20.493 11.9858 20.1H11.9859ZM8.27762 18.0156C8.27762 17.6077 8.43969 17.2164 8.72823 16.928C9.01676 16.6395 9.40808 16.4774 9.81604 16.4774C10.2238 16.4774 10.6151 16.6395 10.9037 16.928C11.1922 17.2164 11.3543 17.6077 11.3543 18.0156C11.3543 18.4236 11.1922 18.8149 10.9037 19.1035C10.6151 19.3918 10.2238 19.5539 9.81604 19.5539C9.40806 19.5539 9.01678 19.3918 8.72823 19.1035C8.43969 18.8149 8.27762 18.4236 8.27762 18.0156Z"
                    fill="#A1AEF6"
                  />
                  <path
                    d="M24.1115 15.5891H17.0609C16.6534 15.5891 16.3228 15.9196 16.3228 16.3272C16.3228 16.7348 16.6534 17.0653 17.0609 17.0653H24.1115C24.5192 17.0653 24.8496 16.7348 24.8496 16.3272C24.8496 15.9196 24.5192 15.5891 24.1115 15.5891Z"
                    fill="#A1AEF6"
                  />
                  <path
                    d="M24.1115 19.1289H17.0609C16.6534 19.1289 16.3228 19.4592 16.3228 19.867C16.3228 20.2745 16.6534 20.6051 17.0609 20.6051H24.1115C24.5192 20.6051 24.8496 20.2745 24.8496 19.867C24.8496 19.4592 24.5192 19.1289 24.1115 19.1289Z"
                    fill="#A1AEF6"
                  />
                  <path
                    d="M24.1115 22.666H17.0609C16.6534 22.666 16.3228 22.9965 16.3228 23.4041C16.3228 23.8119 16.6534 24.1422 17.0609 24.1422H24.1115C24.5192 24.1422 24.8496 23.8119 24.8496 23.4041C24.8496 22.9965 24.5192 22.666 24.1115 22.666Z"
                    fill="#A1AEF6"
                  />
                </svg>
              </div>
              <span>Заказчик</span>
            </li>
            <li
              class="account-type flex-grow max-w-[100px] sm:min-w-[112px] h-[132px]"
              :class="isActiveTab(EAccountTypes.staff)"
              @click.prevent="setActiveTab(EAccountTypes.staff)"
            >
              <div class="circle">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.2349 12.6976H2.66666V8.45203L16.0167 2.66602L29.2349 8.45358V12.6976ZM3.77525 11.5885H28.1257V9.17851L16.0141 3.87548L3.77525 9.17954V11.5885Z"
                    fill="#A1AEF6"
                  />
                  <path
                    d="M29.2349 29.3327H2.66666V26.0055L29.2349 26.0057V29.3327ZM3.77525 28.2238H28.1257V27.1148H3.77525V28.2238Z"
                    fill="#A1AEF6"
                  />
                  <path d="M8.72763 27.115H4.29804V11.5885H8.72763V27.115ZM5.40689 26.006H7.61837V12.6977H5.40689V26.006Z" fill="#A1AEF6" />
                  <path d="M27.5815 27.115H23.1519V11.5885H27.5815V27.115ZM24.261 26.006H26.4724V12.6977H24.261V26.006Z" fill="#A1AEF6" />
                  <path d="M18.1546 27.115H13.725V11.5885H18.1546V27.115ZM14.834 26.006H17.0455V12.6977H14.834V26.006Z" fill="#A1AEF6" />
                </svg>
              </div>
              <span>Сотрудник</span>
            </li>
            <li class="account-type flex-grow max-w-[100px] sm:min-w-[112px] h-[132px]" :class="isActiveTab(EAccountTypes.worker)">
              <a :href="lkWorkerUrl" class="flex-grow flex items-center flex-col gap-3">
                <div class="circle">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M25.812 10.8239C25.812 10.82 25.8081 10.8119 25.8081 10.8081C25.7842 10.7445 25.7445 10.6887 25.7007 10.6371C25.6968 10.6332 25.6968 10.629 25.6926 10.6251L18.4499 2.85727C18.3386 2.73791 18.1793 2.66629 18.0122 2.66629L7.15594 2.66602C6.15304 2.66602 5.33333 3.48181 5.33333 4.48863V27.5101C5.33333 28.513 6.14913 29.3327 7.15594 29.3327H24.029C25.032 29.3327 25.8517 28.5169 25.8517 27.5101L25.8514 11.0349C25.8514 10.9591 25.8356 10.8917 25.8117 10.8239L25.812 10.8239ZM18.5892 4.75911L23.8819 10.4338H19.2179C18.8717 10.4338 18.5891 10.1512 18.5891 9.80507L18.5892 4.75911ZM24.6578 27.5098C24.6578 27.856 24.3752 28.1385 24.0291 28.1385H7.15595C6.8098 28.1385 6.52721 27.856 6.52721 27.5098V4.49191C6.52721 4.14576 6.8098 3.86317 7.15595 3.86317H17.3954V9.80856C17.3954 10.8115 18.2112 11.6312 19.218 11.6312H24.6579L24.6578 27.5098Z"
                      fill="#A1AEF6"
                    />
                    <path
                      d="M10.6337 15.5915C10.6337 15.9218 10.9005 16.1883 11.2306 16.1883H19.9499C20.2803 16.1883 20.5467 15.9215 20.5467 15.5915C20.5467 15.2612 20.28 14.9947 19.9499 14.9947H11.2313C10.9048 14.9947 10.6342 15.2614 10.6342 15.5915H10.6337Z"
                      fill="#A1AEF6"
                    />
                    <path
                      d="M11.2314 17.8121C10.9011 17.8121 10.6346 18.0789 10.6346 18.4089C10.6346 18.7393 10.9013 19.0058 11.2314 19.0058H19.9508C20.2811 19.0058 20.5476 18.739 20.5476 18.4089C20.5476 18.0786 20.2811 17.8121 19.9546 17.8121H11.2314Z"
                      fill="#A1AEF6"
                    />
                    <path
                      d="M11.2314 20.6298C10.9011 20.6298 10.6346 20.8966 10.6346 21.2266C10.6346 21.5569 10.9013 21.8234 11.2314 21.8234H19.9508C20.2811 21.8234 20.5476 21.5567 20.5476 21.2266C20.5476 20.8963 20.2811 20.6298 19.9546 20.6298H11.2314Z"
                      fill="#A1AEF6"
                    />
                    <path
                      d="M15.5925 23.4475H11.231C10.9007 23.4475 10.6342 23.7142 10.6342 24.0443C10.6342 24.3746 10.901 24.6411 11.231 24.6411H15.5925C15.9228 24.6411 16.1893 24.3743 16.1893 24.0443C16.1893 23.7139 15.9189 23.4475 15.5925 23.4475Z"
                      fill="#A1AEF6"
                    />
                  </svg>
                </div>
                <span>Самозанятый</span>
              </a>
            </li>
          </ul>
          <div class="login-form">
            <UInput
              class="input__login"
              :label="phonePlaceholder"
              :value="formatPhone"
              v-model="user.phone"
              mask="phone"
              @paste="handlePaste"
              @keydown="handleKeyDown"
              @keypress.enter="login"
            />
            <div
              class="error-block-message -mt-[30px] mb-3"
              v-if="
                loginError ||
                (v$.user.phone.$dirty && v$.user.phone.required.$invalid) ||
                (v$.user.phone.$dirty && v$.user.phone.minLength.$invalid)
              "
            >
              <span v-if="loginError">{{ loginError }}</span
              >
              <span
              class="error-block-message block"
                v-if="
                  (v$.user.phone.$dirty && v$.user.phone.required.$invalid) || (v$.user.phone.$dirty && v$.user.phone.minLength.$invalid)
                "
                >Обязательное поле</span
              >
            </div>
            <div class="flex flex-grow md:flex-grow-0">
              <UICheckbox v-model="personalPolitics" id="personalPolitics" :value="personalPolitics" classLabel="auto-height" />
              <p class="text-xs text-grey1 mb-4">
                Нажимая на кнопку {{ loginView ? '«Войти»' : '«Зарегистрироваться»' }}, вы соглашаетесь с
                <a href="https://theunit.ru/pravila" class="underline" target="_blank">Правилами электронной площадки </a>
                и
                <a href="https://theunit.ru/personal-data-policy" class="underline" target="_blank"
                  >Политикой в отношении обработки персональных данных</a
                >.
                <p v-if="isCompanyStaff || !loginView" class="mt-1">
                  Вы даете <b class="underline" target="_blank">согласие</b> на обработку персональных данных.
                </p> 
              </p>
            </div>
            <template v-if="loginView">
              <UButton label="Войти" class="btn__login !w-full my-3" :disabled="!personalPolitics" @click.prevent="login" />
              <p class="login-form__no-sms__text text-center mb-5 md:mb-0">
                <span>Нет аккаунта?</span>&nbsp;
                <button type="button" class="btn-link" @click.prevent="loginView = false">Зарегистрироваться</button>
              </p>
            </template>
            <template v-else>
              <UButton label="Зарегистрироваться" class="btn__login !w-full my-3" :disabled="!personalPolitics" @click.prevent="toSmsReg" />
              <p class="login-form__no-sms__text text-center">
                <span>Уже есть аккаунт?</span>&nbsp;
                <button type="button" class="btn-link" @click.prevent="loginView = true">Войти</button>
              </p>
            </template>
          </div>
        </template>
        <template v-else>
          <h1 class="title self-start mb-1">Введите код из СМС</h1>
          <p class="subtitle mb-6">Код отправлен на номер {{ user.phone }}</p>

          <div v-if="!showRegistrationSMSCode" class="login-form">
            <div class="error-block-message" v-if="codeError">Неверный код, попробуйте ещё раз</div>
            <input
              class="h-11 pl-2 rounded-lg flex-shrink-0 w-full min-w-[100px] max-w-[320px] sm:max-w-[360px] border-grey border hover:border-lightBlue focus-within:border-lightBlue"
              type="text"
              maxlength="4"
              minLength="4"
              placeholder="Пароль из СМС"
              v-model="user.code"
              @keypress.enter="loginConfirm"
            />

            <p class="login-form__no-sms__text mt-3 mb-[40px] flex-grow md:flex-grow-0">
              Не пришло СМС?
              <span v-if="timer">Отправить повторно через {{ timer }} с.</span>
              <span v-else class="btn-link" @click="sendSMS">Отправить повторно смс-код</span>
            </p>

            <div class="flex flex-col-reverse gap-4">
              <UButton label="Отмена" color="secondary" @click.prevent="showSMSCode = false" class="!w-full mr-3" />
              <UButton label="Продолжить" class="!w-full" @click.prevent="loginConfirm" />
            </div>
          </div>
          <div v-else class="login-form">
            <div class="error-block-message" v-if="codeError">Неверный код, попробуйте ещё раз</div>
            <input
              class="h-11 pl-2 mb-2 rounded-lg flex-shrink-0 w-full min-w-[100px] max-w-[320px] sm:max-w-[360px] border-grey border hover:border-lightBlue focus-within:border-lightBlue"
              type="text"
              maxlength="4"
              minLength="4"
              placeholder="Пароль из СМС"
              v-model="user.code"
              @keypress.enter="regConfirm"
            />
            <p class="login-form__no-sms__text flex flex-grow md:flex-grow-0">
              Не пришло СМС?
              <span v-if="timer">Отправить повторно через {{ timer }} с.</span>
              <span v-else class="btn-link" @click="toSmsReg">Отправить повторно смс-код</span>
            </p>
            <div class="flex flex-col mt-[40px]">
              <UButton label="Продолжить" :disabled="!user.code" @click.prevent="regConfirm" class="!w-full mb-4" />
              <p class="login-form__no-sms__text text-center">
                <button type="button" class="btn-link" @click="closeSMS">У меня уже есть аккаунт</button>
              </p>
            </div>
          </div>
        </template>
      </div>
    </template>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import AuthLayout from '@/views/Registration/AuthLayout.vue'
import Loader from '@/components/Loader/Loader.vue'
import { UInput, UButton, UICheckbox } from 'unit-uikit'
import { useAuthStore } from '@/stores/auth'
import { useStaffStore } from '@/stores/staff'

import useValidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { EAccountTypes } from '@/types/api-values'

const POLITICS_ERROR = 'Пожалуйста, принимите правила электронной площадки'

export default defineComponent({
  components: {
    Loader,
    UButton,
    UInput,
    UICheckbox,
    AuthLayout
  },
  data() {
    const name = this.$route?.redirectedFrom?.name
    return {
      staffStore: useStaffStore(),
      authStore: useAuthStore(),
      loading: false,
      v$: useValidate(),
      loginView: name !== 'referral',
      isCompanyStaff: false,
      showSMSCode: false,
      showRegistrationSMSCode: false,
      timer: 60,
      timeout: null as any,
      user: {
        phone: '',
        name: '',
        code: '',
        account_type: name === 'referral' ? EAccountTypes.staff : EAccountTypes.customer
      },
      codeError: false,
      loginError: '',
      personalPolitics: false,
      EAccountTypes
    }
  },
  methods: {
    closeSMS() {
      this.loginView = true
      this.showRegistrationSMSCode = false
      this.showSMSCode = false
    },
    sendSMS() {
      this.authStore.login(this.user).then(
        () => {
          // Move to page for input sms code
          this.startTimer()
        },
        (error: any) => {
          console.error(error)
        }
      )
    },
    toSmsReg() {
      if (!this.personalPolitics) {
        this.loginError = POLITICS_ERROR
        return
      }
      this.loginError = ''
      this.v$.user.phone.$touch()
      if (this.v$.user.phone.$invalid) {
        return
      }

      const user = this.user
      const phone = user?.phone?.replaceAll('-', '')
      const companyId = this.staffStore.getReferral()

      switch (this.user?.account_type) {
        case EAccountTypes.staff:
          this.user.name = this.user.phone
          this.user.name = this.user.name.slice(0, 10)

          this.loading = true

          this.authStore
            .register({
              name: user.name,
              phone: user.phone,
              account_type: user.account_type,
              company_id: companyId,
              is_invite: true
            })
            .then(
              (data: any) => {
                if (data.position && data.company_name) {
                  this.staffStore.updateCompany({
                    position: data.position,
                    name: data.company_name,
                    username: phone
                  })
                  this.loading = false
                  this.showSMSCode = true
                  this.showRegistrationSMSCode = true
                  this.startTimer()
                } else {
                  this.loading = false
                  this.loginError = 'Для регистрации получите приглашение от компании'
                }
              },
              (error: any) => {
                this.loading = false
                const errorText = error.response?.data?.non_field_errors
                if (errorText) {
                  this.loginError = errorText
                } else {
                  this.loginError = 'Для регистрации получите приглашение от компании'
                }
              }
            )
          break

        case EAccountTypes.customer:
          this.loading = true
          axios
            .post(API.SIGNUP, { phone: phone, account_type: EAccountTypes.customer })
            .then(() => {
              this.loading = false
              this.showSMSCode = true
              this.showRegistrationSMSCode = true
              this.startTimer()
            })
            .catch((err: any) => {
              this.loading = false
              const errorText = err.response?.data?.non_field_errors || err.response?.data?.response?.non_field_errors
              if (errorText) {
                this.loginError = errorText
              }
            })
          break

        default:
          this.showSMSCode = true
          this.showRegistrationSMSCode = true
          break
      }
    },
    login() {
      if (!this.personalPolitics) {
        this.loginError = POLITICS_ERROR
        return
      }
      this.loginError = ''
      this.v$.user.phone.$touch()
      if (this.v$.user.phone.$invalid) {
        return
      }

      const user = this.user

      this.loading = true

      this.authStore.login(user).then(
        () => {
          // Move to page for input sms code
          this.loading = false
          this.showSMSCode = true
          this.startTimer()
        },
        (error: any) => {
          this.loading = false
          const errorText = error.response?.data?.non_field_errors
          if (errorText) {
            this.loginError = errorText
          }
        }
      )
    },
    regConfirm() {
      const user = this.user
      const phone = user?.phone?.replaceAll('-', '')

      switch (this.user?.account_type) {
        case EAccountTypes.staff:
          axios
            .post(API.SIGNUP_CHECK_CODE, {
              phone,
              code: user.code,
              account_type: EAccountTypes.staff
            })
            .then((response: any) => {
              if (response.data.is_valid) {
                this.$router.push('/registration')
                this.$router.push({
                  name: 'staff.inn'
                })
              }
            })
            .catch((error: any) => {
              this.codeError = true
              console.error(error)
            })
          break
        case EAccountTypes.customer:
          axios
            .post(API.SIGNUP_CHECK_CODE, {
              phone,
              code: user.code,
              account_type: EAccountTypes.customer
            })
            .then((response: any) => {
              if (response.data.is_valid) {
                localStorage.setItem('userPhone', user.phone)
                sessionStorage.setItem('regSmsCode', user.code)
                this.$router.push('/registration')
              }
            })
            .catch((error: any) => {
              this.codeError = true
              console.error(error)
            })

          break

        default:
          break
      }
    },
    loginConfirm() {
      const user = this.user

      this.authStore.loginConfirm(user).then(
        () => {
          this.$router.push({
            name: 'SelectCompany'
          })
        },
        (error: any) => {
          this.codeError = true
          console.error(error)
        }
      )
    },
    startTimer() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timer = 60
      this.timeout = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }, 1000)
    },
    setActiveTab(type: EAccountTypes.customer) {
      this.user.account_type = type
    //  @ts-ignore
      this.user.account_type === EAccountTypes.staff ? this.isCompanyStaff = true : this.isCompanyStaff = false
    },
    isActiveTab(type: EAccountTypes.customer) {
      return type === this.user?.account_type ? '-active' : ''
    },
    handleKeyDown(evt: any) {
      if (evt.keyCode === 8 && (evt.target.selectionStart === 2 || evt.target.selectionStart === 3)) {
        evt.preventDefault()
      }
    },
    handlePaste(event: any) {
      event.preventDefault()
      let _pastePhone = (event.clipboardData || (window as any).clipboardData).getData('text').replace(/[^0-9]/g, '')

      if (_pastePhone.length >= 10 && _pastePhone.startsWith('8')) {
        _pastePhone = '7' + _pastePhone.slice(1)
      }

      this.user.phone = _pastePhone
    }
  },
  computed: {
    formatPhone() {
      if (this.user.phone.length === 0) {
        this.user.phone = '+7'
      }

      return this.user.phone
    },
    phonePlaceholder() {
      if (this.loginView) {
        return 'Введите номер'
      }
      return 'Введите номер телефона ответственного лица'
    },
    mainTitle() {
      return this.loginView ? 'Войти' : 'Регистрация'
    },
    lkWorkerUrl() {
      return process.env.VUE_APP_CLIENT_URL
    }
  },
  validations() {
    return {
      user: {
        phone: { required, minLength: minLength(16) }
      }
    }
  },
  watch: {
    personalPolitics(val) {
      if (val && this.loginError === POLITICS_ERROR) {
        this.loginError = ''
      }
    },
    'user.code'() {
      this.codeError = false
    }
  }
})
</script>

<style lang="postcss" scoped>
.registration-page {
  &__half--wrap {
    @apply flex flex-col w-[328px] sm:w-[384px] mb-12 sm:mb-auto mt-0 flex-grow md:my-0 mx-auto md:justify-center;
  }

  &__forgot-pw {
    @apply self-end mt-2 mr-0 mb-4 ml-0 font-medium text-sm leading-4 text-right;
  }
}

.input__login {
  @apply mb-[30px];
}

.login-form {
  @apply flex flex-col flex-grow md:flex-grow-0;
}

.login-form__no-sms__text {
  @apply not-italic font-normal text-xs1 leading-snug text-black;
}

.error-block {
  &__message {
    @apply text-xs text-error top-full text-left;
  }
}

:deep(.input-container) {
  @apply !pl-0 !h-[52px] !w-full !max-w-full;
  .input {
    @apply mt-1 h-[50px] leading-[50px] text-md;
  }
}

.title {
  @apply not-italic font-medium text-2xl leading-none text-black;
}

.subtitle {
  @apply not-italic font-normal text-sm leading-none text-grey;
  align-self: self-start;
}

.btn-link {
  @apply underline text-accent;

  &:hover {
    @apply cursor-pointer text-accentDarker;
  }
}

.account-type {
  @apply text-gray-300 flex flex-col justify-center items-center py-6 px-0 gap-3 rounded-xl not-italic font-medium text-sm leading-none;
  background-color: #f7fafd;
  border: 1px dashed #c9cddd;

  &:hover {
    @apply cursor-pointer text-accentDarker;
  }

  &.-active {
    @apply text-accent border-accent;
  }
}

.circle {
  @apply flex justify-center items-center;
  width: 52px;
  height: 52px;
  background: linear-gradient(180deg, #dde3fa 0%, #f3f7fc 100%);
  border-radius: 50%;
}
</style>
