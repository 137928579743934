import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import ObjectsService from '@/services/objects.service'

export const useObjectsStore = defineStore('objects', () => {
  const objects = ref([])
  const projectFilter = ref(null)

  const objectOptions = computed(() => {
    if (projectFilter.value) {
      const _objects = objects.value.filter((item: any) => {
        return item.project?.id + '' === projectFilter.value + ''
      })
      return [
        {
          id: 'allObjects',
          name: 'Показать все'
        },
        ..._objects
      ]
    }
    return [
      {
        id: 'allObjects',
        name: 'Показать все'
      },
      ...objects.value
    ]
  })

  async function fetcObjects(force?: boolean) {
    try {
      if (objects.value?.length && !force) {
        return objects.value
      }
      const results = await ObjectsService.getObjects()
      objects.value = results || []
      return results
    } catch (error) {
      return 'error'
    }
  }

  function $reset() {
    projectFilter.value = null
    objects.value = []
  }

  return {
    objectOptions,
    projectFilter,
    fetcObjects,
    objects,
    $reset
  }
})
