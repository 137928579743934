<template>
  <Loader v-if="loading" />
  <div class="finance-archive">
    <UInput id="searchLastName" class="!w-full lg:!w-[180px]" name="search" searchInput placeholder="Поиск по фамилии"
      :value="searchLastName" v-model="searchLastName" />
    <UInput id="searchFirstName" class="!w-full lg:!w-[170px]" name="search" searchInput placeholder="Поиск по имени"
      :value="searchFirstName" v-model="searchFirstName" />
    <div class="relative !w-full lg:max-w-[200px]">
      <span class="z-[10] text-xs1 top-[1px] leading-5 text-gray-300 absolute ml-2 whitespace-nowrap">Объект</span>
      <UMultiselect v-model="selectedObject" :options="objectOptions" :close-on-select="true" :multiple="false"
        :searchable="true" class="!w-full lg:!w-auto" placeholder="Выберите объект" label="name" track-by="id">
        <template #noOptions>Список объектов пуст</template>
        <template #noResult>Ничего не найдено</template>
      </UMultiselect>
    </div>
    <UDateRange class="!w-full basis-full lg:!max-w-[300px]" :start="filterDates.start_date"
      :finish="filterDates.finish_date" @update-start-date="(date: string) => { filterDates.start_date = date }"
      @update-finish-date="(date: string) => { filterDates.finish_date = date }" title="Период оплаты"
      startPlaceHolder="От" endPlaceHolder="До" />
    <div class="flex">
      <div class="flex px-4 items-center">
        <span v-if="totalAmount">Итого:
          <span class="whitespace-nowrap">{{ formatPrice(totalAmount) }}&nbsp;₽</span>
        </span>
      </div>
      <UButton :disabled="!transactions.length" @click="downloadFiles" label="Выгрузить" class="mr-4 ml-auto" />
    </div>
  </div>
  <EmptyPagePlaceholder pageType="finance" title="Финансы" v-if="!transactions.length && !loading" />
  <UTable class="min-h-[300px] lg:min-h-[auto]" v-else colspan="6" :callback="loadArchive">
    <template #thead>
      <tr>
        <th>№ Платежа</th>
        <th>Сумма операции и комиссия</th>
        <th>Исполнитель</th>
        <th>Реестр заказов и тип операции</th>
        <th>Статус операции</th>
        <th>Чек</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="transaction in transactions" :key="transaction.id">
        <td>
          {{ transaction.id }}
          <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatDate(transaction.created) }}</p>
        </td>
        <td>
          <div class="flex">
            <UTooltip v-if="transaction.suborder?.withholding_tax" percent class="!static mr-3 self-center"
              :tooltip="d['payment-fee']" />
            <div>
              <span class="whitespace-nowrap">{{ formatPrice(transaction.amount) }}&nbsp;₽</span>
              <p class="font-normal text-xs1 left-3 text-grey mt-1 whitespace-nowrap">{{
                formatPrice(transaction.commission) }}&nbsp;₽</p>
            </div>
          </div>
        </td>
        <td>{{ formatName(transaction.worker) }}</td>
        <td>
          <p>Заказ №{{ transaction.suborder?.id }} к договору №{{ transaction.suborder?.agreement }}</p>
          <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ transaction.suborder?.name }}</p>
        </td>
        <td class="text-success">
          <div class="flex items-center">
            <div>
              <span>Оплачено</span>
              <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatDate(transaction.updated, true, true) }}</p>
            </div>
          </div>
        </td>
        <td>
          <a :href="transaction.check_image" target="_blank" class="checkLink">
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.8357 5.55533C21.6394 5.32876 16.916 0 11 0C5.08399 0 0.360701 5.32876 0.164291 5.55533C-0.0547636 5.80858 -0.0547636 6.19142 0.164291 6.44467C0.360701 6.67124 5.08408 12 11 12C16.916 12 21.6393 6.67124 21.8357 6.44467C22.0548 6.19142 22.0548 5.80858 21.8357 5.55533ZM11 10.6667C8.51251 10.6667 6.4883 8.57293 6.4883 6C6.4883 3.42707 8.51251 1.33333 11 1.33333C13.4875 1.33333 15.5117 3.42707 15.5117 6C15.5117 8.57293 13.4875 10.6667 11 10.6667Z"
                fill="currentColor" />
            </svg>
          </a>
        </td>
      </tr>
    </template>
  </UTable>

  <UModal :show="showPopup" @cancel="showPopup = false" title="Создание выгрузки">
    <template #content>
      <p class="text-sm leading-5 text-grey">
        Выгрузка документов будет выполнена в соответствии с заданными фильтрами и доступна в истории экспорта в разделе
        документы
      </p>

      <UInput ref="uploadTitle" class="!w-full" placeholder="Название" label="Название выгрузки" :value="uploadName"
        v-model="uploadName" />
      <div class="my-2">
        <button class="underline" :disabled="creatingInProcess" @click.prevent="createExcel">Скачать отчет в exel</button>
      </div>
    </template>
    <template #buttons>
      <div class="flex">
        <UButton label="Отмена" color="secondary" @click.prevent="showPopup = false" class="!w-full mr-5" />
        <UButton label="Создать" :disabled="creatingInProcess" class="!w-full" @click.prevent="createUploads" />
      </div>
    </template>
  </UModal>

  <UModal :show="successCreated" @cancel="successCreated = false" @success="() => {
    $router.push('/documents/history')
  }
    " title="Выгрузка успешно создана!" successLabel="Перейти в историю экспорта">
  </UModal>

  <UModal :show="failedcreate" @cancel="() => {
    failedcreate = false
  }
    " @success="failedcreate = false" title="Извините, что-то пошло не так!">
    <template #content>
      <p class="leading-5">Попробуйте повторить попытку позже или свяжитесь со службой поддержки</p>
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { API } from '@/utils/API'
import * as Sentry from '@sentry/browser'
import dayjs from 'dayjs'
import { formatPrice, formatToServerDate, formatName, formatDate } from '@/utils/helpers'
import debounce from '@/utils/debounce'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import Loader from '@/components/Loader/Loader.vue'
import { UTable, UModal, UButton, UInput, UISelect, UDateRange, UTooltip, UMultiselect } from 'unit-uikit'
import d from '@/dictionary'
import axios from 'axios'
import { useObjectsStore } from '@/stores/objects'


export default defineComponent({
  components: {
    UISelect,
    UDateRange,
    EmptyPagePlaceholder,
    Loader,
    UTable,
    UModal,
    UButton,
    UInput,
    UTooltip,
    UMultiselect
  },
  data() {
    return {
      objectsStore: useObjectsStore(),
      showPopup: false,
      successCreated: false,
      failedcreate: false,
      filterData: {},
      unloadingData: {},
      transactions: [] as any,
      totalAmount: '',
      uploadName: '',
      creatingInProcess: false,
      loading: true,
      allObjects: null as any,
      selectedObject: {
        id: 'allObjects',
        name: 'Показать все'
      },
      searchFirstName: '',
      searchLastName: '',
      objectOptions: [] as any,
      filterDates: {
        start_date: null,
        finish_date: null
      } as any,
      onlyXls: false,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      debounceLoadOrdersWithParams: null as any,
      d
    }
  },
  methods: {
    formatDate,
    formatToServerDate,
    loadOrdersWithParams() {
      this.scrollData.requestCount = 0
      this.scrollData.page = 0
      this.transactions = []
      this.loadArchive()
    },
    async loadArchive() {
      if (this.transactions.length < this.scrollData.count) {
        let queryParams = ''
        if (this.searchFirstName) {
          queryParams += `&first_name=${this.searchFirstName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (this.searchLastName) {
          queryParams += `&last_name=${this.searchLastName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        const startDate = this.filterDates.start_date ? new Date(this.filterDates.start_date) : null
        const finishDate = this.filterDates.finish_date ? new Date(this.filterDates.finish_date) : null
        if (startDate) {
          queryParams += `&start_date=${formatToServerDate(startDate)}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (finishDate) {
          queryParams += `&end_date=${formatToServerDate(finishDate)}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.selectedObject && this.selectedObject?.id !== 'allObjects') {
          queryParams += `&object_id=${this.selectedObject.id}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&status=paid` + queryParams
        const response = await axios.get(API.GET_TRANSACTIONS_ARCHIVE + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.totalAmount = data.total_amount
          this.scrollData.requestCount = data.count
          const transactions = data.results
          this.transactions.push(...transactions)
        }
      }
    },
    downloadFiles() {
      this.uploadName = `Выгрузка_выплаты_${dayjs(new Date()).format('DD.MM.YY_HH:mm:ss')}`
      this.showPopup = true
    },
    createExcel() {
      this.onlyXls = true
      this.createUploads()
    },
    createUploads() {
      this.creatingInProcess = true

      let queryString = ''

      if (this.searchFirstName) {
        queryString += `first_name=${this.searchFirstName}`
      }

      if (this.searchLastName) {
        queryString += `${queryString ? '&' : ''}last_name=${this.searchLastName}`
      }

      if (this.selectedObject && this.selectedObject?.id !== 'allObjects') {
        queryString += `${queryString ? '&' : ''}object_id=${this.selectedObject.id}`
      }

      const startDate = this.filterDates.start_date ? new Date(this.filterDates.start_date) : null
      const finishDate = this.filterDates.finish_date ? new Date(this.filterDates.finish_date) : null
      if (startDate) {
        queryString += `${queryString ? '&' : ''}start_date=${formatToServerDate(startDate)}`
      }
      if (finishDate) {
        queryString += `${queryString ? '&' : ''}end_date=${formatToServerDate(finishDate)}`
      }

      axios
        .post(API.FINANCIAL_UNLOADING + '?' + queryString, {
          unloading_name: this.uploadName,
          query_link: API.GET_ALL_DOCS + '?' + queryString.replaceAll(' ', ''),
          only_xls: this.onlyXls
        })
        .then(() => {
          this.showPopup = false
          this.successCreated = true
          this.creatingInProcess = false
          this.onlyXls = false
        })
        .catch((e: any) => {
          Sentry.captureException(e)
          this.failedcreate = true
          this.showPopup = false
          this.creatingInProcess = false
          this.onlyXls = false
        })
    },
    formatName,
    formatPrice(price: number | string) {
      return formatPrice(price)
    }
  },
  created() {
    this.selectedObject = {
      id: 'allObjects',
      name: 'Показать все'
    }

    this.objectsStore.fetcObjects()
      .then((res) => {
        const objects = res

        this.allObjects = res
        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
      })
      .catch((e: any) => {
        console.error(e)
      })

    axios
      .get(API.GET_TRANSACTIONS_ARCHIVE + `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&status=paid`)
      .then((response: { data: any }) => {
        this.totalAmount = response.data.total_amount
        this.scrollData.count = response.data.count
        this.transactions = response.data.results || []
        this.loading = false
      })
      .catch((error: any) => {
        console.error(error)
        this.loading = false
      })
  },
  watch: {
    'filterDates.start_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    'filterDates.finish_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchFirstName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchLastName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    selectedObject() {
      if (this.selectedObject?.id !== 'allObjects') {
        // @ts-ignore
        const _object = this.allObjects.filter((item: any) => {
          return item.id + '' === this.selectedObject?.id + ''
        })
        const projectId = _object && _object[0] && _object[0].project?.id
        // @ts-ignore
        if (projectId && this.selectedProject + '' !== projectId + '') {
          // @ts-ignore
          this.selectedProject = projectId
        }
      }

      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.finance-archive {
  @apply flex gap-3 mb-5 flex-wrap xl:flex-nowrap;
}

.checkLink {
  @apply text-grey;

  &:hover {
    @apply text-black cursor-pointer;
  }

  &.isDisabled {
    @apply text-cancelled;

    &:hover {
      @apply text-cancelled cursor-not-allowed;
    }
  }
}

:deep(.modal) {
  @apply !gap-2;
}

:deep(.modal .-buttons button:first-child) {
  @apply hidden;
}

:deep(.multiselect__option span) {
  @apply ml-0;
}

:deep(.multiselect__option::before),
:deep(.multiselect__option--selected::before),
:deep(.multiselect__option--selected::after) {
  @apply hidden;
}

:deep(.multiselect__single) {
  @apply mt-1;
}
</style>
