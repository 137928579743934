<template>
  <div class="mb-4">
    <header class="flex justify-between items-center mb-3">
      <div class="result-item">
        <span class="title">
          {{ formatDate(comment.created, true, true) }}
        </span>
        <h3 class="body">{{ title }}</h3>
      </div>
      <div class="file-item">{{ workerTitle }}</div>
    </header>
    <div>
      <div class="result-item mb-3" v-if="comment.reason">
        <p class="title">Причина:</p>
        <p class="body">{{ reason }}</p>
      </div>
      <div class="result-item mb-3">
        <p class="title">{{ commentTitle }}:</p>
        <p class="body">{{ comment.comment }}</p>
      </div>
    </div>
    <footer v-if="comment?.documents?.length">
      <a class="file-item" target="_blank" v-for="(file, id) in comment.documents" :key="id" :href="getFileUrl(file)">{{
        getFileName(file)
      }}</a>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { SUBORDER_CHANGE_REASON, SUBORDER_CHANGE_TYPES } from '@/utils/consts'
import { formatDate } from '@/utils/helpers'

export default defineComponent({
  props: {
    comment: Object as any,
    worker: String
  },
  methods: {
    formatDate,
    getFileUrl(item: any) {
      const URL_CLEAR = process.env.VUE_APP_URL
      const file = item?.file
      if (file) {
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    },
    getFileName(item: any) {
      const file = item?.file
      if (file) {
        function replacer(match: any, p1: any, p2: any, p3: any, p4: any) {
          return [[p2, p4]].join('.')
        }
        return file.replace(/(.*\/)([a-zA-Z]*)(.*)(.[a-z]{3,4})$/, replacer)
      }
      return ''
    }
  },
  computed: {
    reason() {
      const value = this.comment?.reason || []
      const result: any = []
      value.forEach((r: any) => {
        const filteredReason = SUBORDER_CHANGE_REASON.filter((item: any) => item.value === r)
        const res = (filteredReason && filteredReason[0] && filteredReason[0].label) || 'Другая причина'
        result.push(res)
      })
      return result && result.length ? result.join(', ') : 'Другая причина'
    },
    title() {
      if (this.comment?.isResult) {
        return 'Дата загрузки'
      }
      if (this.comment?.type === SUBORDER_CHANGE_TYPES.RETURN) {
        return 'Возврат в работу'
      }
      if (this.comment?.type === SUBORDER_CHANGE_TYPES.CHANGE) {
        return 'Изменение цены или объёма'
      }
      return ''
    },
    commentTitle() {
      if (this.comment?.isResult) {
        return 'Комментарий от исполнителя'
      }
      return 'Комментарий заказчика'
    },
    workerTitle() {
      if (this.comment?.isResult) {
        return this.worker
      }
      return 'UNIT'
    }
  }
})
</script>

<style lang="postcss" scoped>
.result-item {
  @apply flex font-bold text-xs2 leading-4;

  &>.title {
    @apply mr-1 text-black;
  }

  &>.body {
    @apply font-normal;
  }
}

.file-item {
  @apply mr-2 inline-block p-1.5 bg-backgroundMainColor rounded-sm font-normal not-italic text-xs leading-3 tracking-title text-grey1;
}
</style>
