<template>
  <UModal @cancel="confirmAction()" :show="showModal" title="Недостаточно прав доступа на совершение действия!">
    <template #content>
      <p class="text-error">
        {{ errorMsg || '' }}
      </p>
    </template>
    <template #buttons>
      <UButton class="w-full" label="Продолжить" @click="confirmAction()" />
    </template>
  </UModal>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useCompanyStore } from '@/stores/company'
import { storeToRefs } from 'pinia'
import { UModal, UButton } from 'unit-uikit'

const { accessDenied, accessDeniedMsg } = storeToRefs(useCompanyStore())

const showModal = ref(false)
const errorMsg = ref('')

const confirmAction = () => {
  showModal.value = false
  accessDenied.value = false
  accessDeniedMsg.value = ''
}

onMounted(() => {
  showModal.value = accessDenied.value
  errorMsg.value = accessDeniedMsg.value || ''
})
</script>
