<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useCompanyStore } from '@/stores/company'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { UTabs, UModal, UButton } from 'unit-uikit'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'

const pageReady = ref(false)

const order = ref({})

const companyStore = useCompanyStore()
const orderStore = useOrderStore()
const { isProcurationEnded } = storeToRefs(companyStore)
const { duplicateId } = storeToRefs(orderStore)

const route = useRoute()
const router = useRouter()

onMounted(() => {
  pageReady.value = true
})

const id = computed(() => {
  return route.params.id
})

if (id.value) {
  orderStore
    .fetchOrder(id.value as string)
    .then((response) => {
      order.value = response
    })
    .catch((e) => {
      console.error(e)
    })
}

const showCopyOrderDialog = ref(false)

const showDuplicateOrder = () => {
  showCopyOrderDialog.value = true
}
const duplicateOrder = () => {
  duplicateId.value = route.params.id as string
  router.push({
    name: 'addOrder'
  })
}

watch(id, () => {
  if (id.value) {
    orderStore
      .fetchOrder(id.value as string)
      .then((response) => {
        order.value = response
      })
      .catch((e) => {
        console.error(e)
      })
  }
})

const tabs = computed(() => {
  return [
    {
      routeName: 'order.info',
      routeParams: {
        id: id.value
      },
      label: 'Инфо'
    },
    {
      routeName: 'order.docs',
      routeParams: {
        id: id.value
      },
      label: 'Заказы'
    }
  ]
})
</script>

<template>
  <div>
    <main-page-header :title="`Заказ №${id || ''} ${order.name || ''}`" class="page-title">
      <div id="order-app-header" class="ml-auto"></div>
    </main-page-header>
  </div>

  <UTabs :tabs="tabs" />

  <router-view :key="id"></router-view>

  <UModal
    :show="showCopyOrderDialog"
    title="Дублирование заказа"
    successLabel="Создать"
    @cancel="showCopyOrderDialog = false"
    @success="duplicateOrder"
  >
    <template #content>
      <p>Вы точно хотите создать дублированный заказ?</p>
    </template>
  </UModal>
  <Teleport to="#order-app-header" v-if="pageReady">
    <UButton
      data-dublicate-orders="true"
      color="secondary"
      label="Дублировать реестр заказов"
      :disabled="isProcurationEnded"
      @click="showDuplicateOrder"
    />
  </Teleport>
</template>
