<script setup lang="ts">
import { computed } from 'vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs } from 'unit-uikit'
import { useRoute } from 'vue-router'

const route = useRoute()

const title = computed(() => {
  if (route.name === 'objects.edit') {
    return 'Объект'
  }
  return 'Проекты'
})

const showNav = computed(() => {
  const path = route.path
  return !(path.includes('edit') || path.includes('add'))
})

const tabs = [
  {
    routeName: 'projects.list',
    label: 'Проекты'
  },
  {
    routeName: 'objects.list',
    label: 'Объекты'
  }
]
</script>

<template>
  <main-page-header :title="title" class="page-title" />
  <UTabs v-if="showNav" :tabs="tabs" />
  <router-view></router-view>
</template>
