<script setup lang="ts">
import { UButton } from 'unit-uikit'

defineProps({
  bills: {
    type: Array,
    default: []
  }
})

const emits = defineEmits(['clear', 'pay'])
</script>

<template>
  <div class="actions-container gap-5 pl-3 pr-3">
    <p>Выбрано исполнителя: {{ bills.length }}</p>

    <button class="clear-btn" type="button" @click="emits('clear')">Снять выделение</button>

    <div class="flex-grow" />

    <u-button label="Оплатить выбранные" @click="emits('pay')" />
  </div>
</template>

<style lang="postcss" scoped>
.actions-container {
  @apply shadow-main flex items-center flex-shrink-0 bg-white -mb-8;
  border-radius: 8px 8px 0px 0px;
  height: 68px;
}
.clear-btn {
  @apply text-accent;
}
.clear-btn:hover {
  @apply text-accentDarker;
}
</style>
