import axios from 'axios'
import { API } from '@/utils/API'

export default {
  async getCompanies() {
    const res = await axios.get(API.AUTH_CHOOSECOMPANY)
    return res.data
  },

  async getCompanyBalance() {
    const res = await axios.get(API.GET_BILLS_BALANCE)
    return res.data
  }
}
