import { ref, computed, Ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import CompanyService from '@/services/company.service'
import { useAuthStore } from '@/stores/auth'

export const useCompanyStore = defineStore('company', () => {
  const authStore = useAuthStore()
  const { payload } = storeToRefs(authStore)

  type TPersonInfo = {
    id: number
  }

  const companies = ref([]) as Ref<TPersonInfo[]>
  const isProcurationEnded = ref(false)
  const accessDenied = ref(false)
  const accessDeniedMsg = ref('')
  // balance
  const depositMoney = ref(0)
  const reservedMoney = ref(0)
  const freeMoney = computed(() => {
    return depositMoney.value - reservedMoney.value
  })

  const activeCompany = computed(() => {
    if (payload && payload.value?.company_id) {
      return companies.value.find((c) => {
        return c.id + '' === payload.value?.company_id + ''
      })
    }
    return null
  })

  function $reset() {
    companies.value = []
    isProcurationEnded.value = false
    accessDenied.value = false
    accessDeniedMsg.value = ''
    depositMoney.value = 0
    reservedMoney.value = 0
  }

  async function fetcCopmanies(force?: boolean) {
    try {
      if (companies.value.length && !force) {
        return companies.value
      }
      const results = await CompanyService.getCompanies()
      companies.value = results || []
      return results || 'error'
    } catch (error) {
      return 'error'
    }
  }

  async function fetcCompanyBalance() {
    try {
      const data = await CompanyService.getCompanyBalance()
      depositMoney.value = data ? data.company_deposit || 0 : 0
      reservedMoney.value = data ? data.total_reserve || 0 : 0
      return data || 'error'
    } catch (error) {
      return 'error'
    }
  }

  return {
    isProcurationEnded,
    accessDenied,
    accessDeniedMsg,
    companies,
    activeCompany,
    depositMoney,
    reservedMoney,
    freeMoney,
    fetcCopmanies,
    fetcCompanyBalance,
    $reset
  }
})
