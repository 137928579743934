<template>
  <div class="landing-wrapper">
    <div class="landing-header">
      <img src="/icons/logo.svg" class="logo h-9 align-top" alt="Logo UNIT" />
      <ul class="list">
        <li class="item">
          <a href="https://theunit.ru/orders">Найти Задания</a>
        </li>
        <li class="item">
          <a href="https://theunit.ru/">Для бизнеса</a>
        </li>
        <li class="item">
          <a href="https://theunit.ru/selfemployer">Для самозанятых</a>
        </li>
        <li class="item">
          <a href="https://lk.theunit.ru">Вход в личный кабинет</a>
        </li>
      </ul>
    </div>
    <img src="/img/macbook_landing.png" id="mac" alt="qr" v-if="isMobileDevice" />
    <h1 class="main-title" :class="{ 'padding-title': isMobileDevice }">{{ getMainTitle }}</h1>
    <div class="phone-header">
      <h1 class="title">{{ getMainTitle }}</h1>
      <img src="/icons/burger1.svg" class="burger" />
    </div>
    <div class="choose-account" v-if="!isMobileDevice">
      <div class="choose-account__item worker">
        <h2 class="title">Исполнитель</h2>
        <p class="description">Аккаунт для самозанятых лиц. Выбирайте заказы от надежных компаний и получайте своевременные выплаты</p>
        <UButton label="Войти как исполнитель" @click="goToLogin('worker')" v-if="clientWidth > 600" />
        <div class="go-btn" v-if="clientWidth < 600">
          <a href="https://work.theunit.ru/" class="go-btn bg-white">Продолжить</a>
        </div>
      </div>
      <div class="choose-account__item customer">
        <h2 class="title">Заказчик</h2>
        <p class="description">
          Аккаунт для юридических лиц. Создавайте задания, приглашайте исполнителей, контролируйте выполнение работ и оплаты
        </p>
        <UButton label="Войти как заказчик" color="secondary" @click="goToLogin('customer')" v-if="clientWidth > 600" />
        <div class="customer-btn" v-else @click="isMobileDevice = true">
          <p class="customer-btn">Войти как заказчик</p>
        </div>
      </div>
    </div>
    <div class="choose-mobile" v-else>
      <h3 class="phone-title">
        Зайдите на этот адрес с вашего компьютера вручную <a class="link" href="https://lk.theunit.ru/" target="_blank">theunit.ru</a>
      </h3>
      <div class="main">
        <div class="qr">
          <img src="/img/qr_code.png" class="qr-icon" alt="qr" />
          <p class="text">
            Отсканируйте QR-код или введите адрес сайта вручную
            <a class="link" href="https://work.theunit.ru/" target="_blank">work.theunit.ru</a>
          </p>
        </div>
        <p class="description">Вы можете продолжить работу в мобильной версии</p>
        <div class="continue-btn">
          <a href="https://work.theunit.ru/" class="continue-btn">Продолжить</a>
        </div>
      </div>
      <img src="/img/tasks_iphone.png" class="iphone" alt="iphone" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UButton } from 'unit-uikit'

export default defineComponent({
  components: {
    UButton
  },
  data() {
    return {
      isMobileDevice: false,
      showMac: false
    }
  },
  methods: {
    goToLogin(userType: string) {
      if (userType === 'worker') {
        if (this.clientWidth > 600) {
          this.isMobileDevice = true
        }
      } else {
        if (userType === 'customer') {
          if (this.clientWidth > 600) {
            this.$router.push('/login')
          } else {
            this.isMobileDevice = true
          }
        }
      }
    }
  },
  computed: {
    getMainTitle() {
      return this.isMobileDevice ? 'Сайт для самозанятых доступен только на мобильном устройстве' : 'Выберите тип аккаунта'
    },
    clientWidth() {
      return document.documentElement.clientWidth
    }
  }
})
</script>

<style lang="postcss" scoped>
.landing-wrapper {
  @apply relative flex flex-col items-center h-screen w-full overflow-hidden;
  background: url('../../assets/landing_bg.png');

  @media (max-width: 768px) {
    @apply py-6 px-5;
  }
}

.landing-header {
  @apply flex py-0 px-[10%] items-center justify-between h-20 w-full mb-[50px];

  @media (max-width: 768px) {
    @apply hidden;
  }

  > .logo {
    @media (max-width: 768px) {
      @apply hidden;
    }
  }

  > .list {
    @apply flex;

    > .item {
      @apply font-bold text-base leading-1.1 items-center text-right mr-5;
    }
  }
}

.choose-account {
  @apply flex;

  @media (max-width: 768px) {
    @apply flex-col;
  }

  &__item {
    @apply flex py-0 px-[10%] justify-between flex-col;

    &:first-child {
      @apply border-r-2 border-r-white;

      @media (max-width: 768px) {
        @apply mb-7;
      }
    }

    > .title {
      @apply font-bold text-[32px] leading-[39px] flex items-center mb-8;

      @media (max-width: 768px) {
        @apply text-2xl leading-[29px] mb-5;
      }

      @media (max-width: 350px) {
        @apply text-lg leading-[27px] mb-[17px];
      }
    }

    > .description {
      @apply font-normal text-2xl leading-11 tracking-title mb-10;

      @media (max-width: 768px) {
        @apply text-lg leading-8 mb-6;
      }

      @media (max-width: 500px) {
        @apply text-md leading-[32px] mb-5;
      }

      @media (max-width: 350px) {
        @apply text-md leading-[25px] mb-3.5;
      }
    }

    > .login-btn {
      @media (max-width: 768px) {
        @apply mr-0 w-full;
      }
    }
  }
}

.choose-mobile {
  @apply absolute bottom-0 flex w-full py-0 px-[10%];

  @media (max-width: 768px) {
    @apply relative p-0 flex-col;
  }

  > .phone-title {
    @apply hidden font-normal text-xl leading-8 tracking-title mb-[57px];

    > .link {
      @apply text-accent;
    }

    @media (max-width: 768px) {
      @apply block;
    }
  }

  > .main {
    > .qr {
      @apply flex items-center;

      @media (max-width: 768px) {
        @apply hidden;
      }

      > .qr-icon {
        @apply mr-[50px] mb-10;
      }

      > .text {
        @apply font-normal text-[28px] leading-11;

        > .link {
          @apply text-accent;
        }
      }
    }

    > .description {
      @apply font-normal text-[28px] leading-[34px] flex items-center tracking-title mb-10;

      @media (max-width: 768px) {
        @apply hidden;
      }
    }
  }

  > .iphone {
    @media (max-width: 1000px) {
      @apply hidden;
    }
  }
}

.padding-title {
  @apply py-0 px-[17%];
}

.phone-header {
  @apply hidden items-start;

  @media (max-width: 768px) {
    @apply !flex w-full justify-between;
  }

  > .title {
    @apply font-bold text-[46px] leading-[56px] mb-[130px];

    @media (max-width: 768px) {
      @apply font-bold text-2xl leading-[29px] text-left mb-[35px] self-start;
    }

    @media (max-width: 400px) {
      @apply font-bold text-[20px] leading-7 text-left mb-[30px] self-start;
    }
  }
}

.main-title {
  @apply block font-bold text-[46px] leading-[56px] text-center mb-[130px];

  @media (max-width: 1000px) {
    @apply text-[27px];
  }

  @media (max-width: 768px) {
    @apply hidden;
  }
}

#mac {
  @apply hidden;

  @media (max-width: 768px) {
    @apply block absolute right-0 bottom-[15%];
  }
}

.worker-btn {
  @apply flex justify-center whitespace-nowrap text-white items-center py-4 px-5 min-w-[171px] max-w-[200px] h-[60px] bg-accent rounded;
}

.customer-btn {
  @apply flex justify-center whitespace-nowrap text-white items-center py-4 px-5 min-w-[171px] max-w-[200px] h-[60px] bg-black rounded;
}

.continue-btn {
  @apply flex justify-center text-white items-center py-4 px-5 w-[171px] h-[60px] bg-accent rounded;

  @media (max-width: 768px) {
    @apply hidden;
  }
}

.go-btn {
  @apply !flex justify-center text-white items-center w-[171px] h-[52px] bg-accent rounded;
}
</style>
