<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { formatDate, formatPrice } from '@/utils/helpers'
import { UButton, UTable, UDateRange } from 'unit-uikit'
import Loader from '@/components/Loader/Loader.vue'
import dayjs from 'dayjs'
import axios from 'axios'
import { API } from '@/utils/API'
import debounce from '@/utils/debounce'

const router = useRouter()
const loading = ref(false)

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

const _today = new Date()
const _yesterday = new Date(new Date().setDate(_today.getDate() - 1))

const dds: any = ref([])
const filterDates = ref({
  start_date: _yesterday,
  finish_date: _today
})

const incomPayments: any = ref([])
const outgoPayments: any = ref([])
const incomSum = ref(0)
const outgoSum = ref(0)
const balanceBefore = ref('')
const balanceAfter = ref('')

onMounted(() => {
  loadData()
})

const getMinDate = () => {
  const dayMilliseconds = 24 * 60 * 60 * 1000
  const yearMilliseconds = dayMilliseconds * 365

  const currentDate = new Date()
  return new Date(currentDate.setTime(currentDate.getTime() - yearMilliseconds))
}

const getSum = (dds: any) => {
  dds.forEach((item: any) => {
    incomPayments.value.push(+item.incomming)
    outgoPayments.value.push(+item.outgo)
  })
  incomSum.value = incomPayments.value.reduce((accumulator: number, currentValue: number) => {
    return accumulator + currentValue
  }, 0)
  outgoSum.value = outgoPayments.value.reduce((accumulator: number, currentValue: number) => {
    return accumulator + currentValue
  }, 0)
}

const getBalance = (dds: any, isBalanceBefore: boolean) => {
  if (isBalanceBefore) {
    balanceBefore.value = dds[0]?.balance_before
  } else {
    balanceAfter.value = dds.at(-1)?.balance_after
  }
}

const loadData = () => {
  scrollData.count = 0
  scrollData.page = 1
  dds.value = []

  loading.value = true

  const startDate = dayjs(filterDates.value.start_date).format('YYYY-MM-DD')
  const finishDate = dayjs(filterDates.value.finish_date).format('YYYY-MM-DD')

  axios
    .get(
      API.GET_DDS_LIST +
      `?page=${scrollData.page}&count=${scrollData.size}` +
      `&date_start=${startDate}T00:00:00` +
      `&date_end=${finishDate}T23:59:59.99`
    )
    .then((res) => {
      scrollData.count = res.data.count || 0
      dds.value = res.data.results
      getSum(dds.value)
      getBalance(dds.value, true)
      getBalance(dds.value, false)
      loading.value = false
    })
    .catch((e) => {
      console.error(e)
      loading.value = false
    })
}

const loadDataOnScroll = async () => {
  if (dds.value.length < scrollData.count) {
    let queryParams = ''

    const startDate = dayjs(filterDates.value.start_date).format('YYYY-MM-DD')
    const finishDate = dayjs(filterDates.value.finish_date).format('YYYY-MM-DD')

    if (startDate) {
      queryParams += `&date_start=${startDate}T00:00:00`
    }
    if (finishDate) {
      queryParams += `&date_end=${finishDate}T23:59:59.99`
    }

    scrollData.page++

    const response = await axios.get(API.GET_DDS_LIST + `?page=${scrollData.page}&count=${scrollData.size}` + queryParams)
    const data = response.data
    if (data.results && data.count) {
      scrollData.requestCount = data.count
      const result = data.results
      getSum(result)
      dds.value.push(...result)
      getBalance(dds.value, false)
    }
  }
}

const downloadReport = () => {
  const startDate = dayjs(filterDates.value.start_date).format('YYYY-MM-DD')
  const finishDate = dayjs(filterDates.value.finish_date).format('YYYY-MM-DD')

  loading.value = true
  axios
    .post(API.GET_DDS_LIST, { date_start: `${startDate} 00:00:00`, date_end: `${finishDate} 23:59:59` })
    .then(() => {
      loading.value = false
      router.push('/documents/history')
    })
    .catch((e) => {
      console.error(e)
      loading.value = false
    })
}

const loadDataWithParams = () => {
  scrollData.page = 0
  incomPayments.value = []
  outgoPayments.value = []
  dds.value = []
  loadData()
}

const debounceloadDataWithParams = debounce(() => {
  loadDataWithParams()
})

watch(
  () => filterDates.value.start_date,
  () => {
    debounceloadDataWithParams()
  }
)

watch(
  () => filterDates.value.finish_date,
  () => {
    debounceloadDataWithParams()
  }
)
</script>
<template>
  <Loader v-if="loading" />

  <div class="flex flex-wrap xl:flex-nowrap gap-3 mb-2">
    <UDateRange :minDate="getMinDate()" :maxDate="new Date()" class="max-w-[280px] !h-[48px] mr-8"
      :start="filterDates.start_date" :finish="filterDates.finish_date"
      @update-start-date="(date: string) => { filterDates.start_date = date }"
      @update-finish-date="(date: string) => { filterDates.finish_date = date }"
      title="Выберите начало и окончание периода" startPlaceHolder="От" endPlaceHolder="До" />
    <UButton @click="downloadReport()" label="Скачать отчет" />
  </div>
  <UTable v-if="dds.length" colspan="5" :callback="loadDataOnScroll">
    <template #thead>
      <tr>
        <th class="w-[140px]">Дата</th>
        <th class="w-[140px]">Поступления</th>
        <th class="w-[140px]">Оплаты</th>
        <th class="w-[140px]">Баланс до</th>
        <th class="w-[140px]">Баланс после</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="item in dds" :key="item.id" class="whitespace-nowrap">
        <td>
          <span> {{ formatDate(item.date, false) }}</span>
        </td>
        <td>
          <span class="whitespace-nowrap"> {{ `${formatPrice(item.incomming) ? formatPrice(item.incomming) + '&nbsp;₽' :
            ''}` }}</span>
        </td>
        <td>
          <span class="whitespace-nowrap"> {{ `${formatPrice(item.outgo) ? formatPrice(item.outgo) + '&nbsp;₽' : ''}`
          }}</span>
        </td>
        <td>
          <span class="whitespace-nowrap">
            {{ `${formatPrice(item.balance_before) ? formatPrice(item.balance_before) + '&nbsp;₽' : ''}` }}</span>
        </td>
        <td>
          <span class="whitespace-nowrap">
            {{ `${formatPrice(item.balance_after) ? formatPrice(item.balance_after) + '&nbsp;₽' : ''}` }}</span>
        </td>
      </tr>
      <tr v-if="dds.length" class="total-sum">
        <td><span class="font-medium text-base"> ИТОГО:</span></td>
        <td class="whitespace-nowrap">{{ formatPrice(incomSum.toFixed(2)) }}&nbsp;₽</td>
        <td class="whitespace-nowrap">{{ formatPrice(outgoSum.toFixed(2)) }}&nbsp;₽</td>
        <td class="whitespace-nowrap">{{ formatPrice(balanceBefore) }}&nbsp;₽</td>
        <td class="whitespace-nowrap">{{ formatPrice(balanceAfter) }}&nbsp;₽</td>
      </tr>
    </template>
  </UTable>
</template>
<style lang="postcss" scoped>
.total-sum {
  @apply sticky bottom-[-1px];

  & td {
    @apply border-b-accent border-b bg-gray-50 font-medium;
  }
}
</style>
