import { useOrderStore } from '@/stores/order'

export default [
  {
    path: '',
    name: 'workers.list',
    component: () => import('@/views/Workers/WorkersList.vue'),
    beforeEnter() {
      const orderStore = useOrderStore()
      orderStore.targetProject = null
      orderStore.targetObject = null
    }
  },
  {
    path: 'favourites',
    name: 'workers.favourites',
    component: () => import('@/views/Workers/WorkersList.vue')
  },
  {
    path: 'invited',
    name: 'workers.invited',
    component: () => import('@/views/Workers/WorkersList.vue')
  },
  {
    path: 'linked',
    name: 'workers.linked',
    component: () => import('@/views/Workers/WorkersList.vue')
  },
  {
    path: 'blacklist',
    name: 'workers.blacklist',
    component: () => import('@/views/Workers/WorkersList.vue')
  },
  {
    path: 'reestr',
    name: 'workers.reestr',
    component: () => import('@/views/Workers/WorkersList.vue')
  },
  {
    path: 'add',
    name: 'workers.add',
    component: () => import('@/views/Workers/Add.vue'),
    children: [{
      path: '',
      name: 'workers.add.new',
      component: () => import('@/views/Workers/AddWorkers.vue')
    }, {
      path: 'invited',
      name: 'workers.add.invited',
      component: () => import('@/views/Workers/InvitedSMSList.vue')
    }]
  },
  {
    path: 'invite',
    name: 'workers.invite',
    component: () => import('@/views/Workers/Add.vue'),
    children: [{
      path: '',
      name: 'workers.invite.new',
      component: () => import('@/views/Workers/Invite.vue')
    }]
  },
  {
    path: 'edit/:id',
    name: 'workers.proxy',
    component: () => import('@/views/Workers/Worker.vue'),
    children: [{
      path: '',
      name: 'workers.edit',
      component: () => import('@/views/Workers/Profile/WorkerEdit.vue')
    }, {
      path: 'tasks',
      name: 'workers.tasks',
      component: () => import('@/views/Workers/Profile/WorkerTasks.vue')
    }, {
      path: 'docs',
      name: 'workers.docs',
      component: () => import('@/views/Workers/Profile/WorkerDocs.vue')
    }, {
      path: 'payments',
      name: 'workers.payments',
      component: () => import('@/views/Workers/Profile/WorkerPayments.vue')
    }]
  }
]
