import { ref } from 'vue'
import useValidate from '@vuelidate/core'
import { required, maxLength, minLength } from '@vuelidate/validators'

export function useProjectForm() {
  const rules = {
    name: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(200)
    },
    budget: { required },
    description: { required }
  }

  const project = ref({
    name: '',
    budget: '',
    description: '',
    company: ''
  })

  const v$ = useValidate(rules, project)

  return { project, v$ }
}
