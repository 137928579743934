<template>
  <Loader v-if="loading" />

  <h2 class="order-title mb-4">Распределение заказов по исполнителям ({{ workersAmount }})</h2>
  <div class="mb-3 flex gap-3 overflow-hidden flex-shrink-0">
    <UInput large id="searchLastName" class="!basis-full lg:!basis-1/2 !flex-grow-0 !flex-shrink"
      placeholder="Введите фамилию исполнителя" label="Поиск по фамилии" v-model="searchLastName"
      :value="searchLastName" />
    <UInput large id="searchFirstName" class="!basis-full lg:!basis-1/2 !flex-grow-0 !flex-shrink"
      placeholder="Введите имя исполнителя" label="Поиск по имени" v-model="searchFirstName" :value="searchFirstName" />
  </div>

  <WorkerWarningLimits :tax="order?.withholding_tax" :limitWithTax="order?.monthly_limit_with_tax"
    :limitWithoutTax="order?.monthly_limit_without_tax" :orders="workers" />

  <div class="form" v-if="ready">
    <div class="scroll-bar h-full" ref="orderLIstWrapper" @scroll="onScroll">
      <table class="w-full">
        <thead class="overflow-y-visible">
          <th class="bg-white w-full">Исполнитель</th>
        </thead>
        <tbody class="table-body" ref="orderLIstContent">
          <tr v-for="worker in workers" :key="worker.id" :class="{
            'field-selected': checkedWorkers.indexOf(worker.id) != -1,
            'border-2 border-error': disableAddWorker(worker)
          }">
            <td class="flex justify-between items-center">
              <WorkerTableItem :isDisabledAdd="disableAddWorker(worker)" :startDate="startDate" :worker="worker" />
              <button :disabled="disableAddWorker(worker)" v-if="worker.personaldata['is_self_employed']"
                class="worker-select-btn hover:opacity-60" :class="{
                  'opacity-30 hover:opacity-30 cursor-not-allowed': disableAddWorker(worker)
                }" type="button" @click="addWoker(worker.id)">
                +
              </button>
            </td>
          </tr>
          <tr v-if="infiniteLoader">
            <td colspan="2" class="text-center">Загружается</td>
          </tr>
          <tr v-else-if="!infiniteLoader && !workers.length">
            <td colspan="2" class="text-center">Не найдено</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="hidden">{{ selectedWorkersData.length }}</div>
    <div class="scroll-bar h-full">
      <table class="w-full">
        <thead class="overflow-y-visible">
          <th class="bg-white w-full">Выбранные исполнители ({{ checkedWorkers.length }} из {{ workersAmount }})</th>
        </thead>
        <tbody class="table-body">
          <template v-for="worker in checkedWorkersData" :key="worker.id">
            <tr :class="{
              'border-2 border-error': disableAddWorker(worker)
            }">
              <td class="flex justify-between items-center">
                <div class="worker-description">
                  <WorkerTableItem :class="{
                    'opacity-30': disableAddWorker(worker)
                  }" :startDate="startDate" :worker="worker" />
                </div>
                <button class="worker-select-btn hover:opacity-60" type="button"
                  @click="removeWoker(worker.id)">—</button>
              </td>
            </tr>
          </template>
          <tr v-if="!checkedWorkersData.length">
            <td colspan="2" class="text-center">Не выбрано</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import WorkerTableItem from './OrderComponents/WorkerTableItem.vue'
import WorkerWarningLimits from '@/views/Orders/OrderComponents/WorkerWarningLimits.vue'
import { APIAddWorkersBudget, APIAddWorkersOrder } from '@/interfaces/main'
import dayjs from 'dayjs'
import Loader from '@/components/Loader/Loader.vue'
import onScroll from '@/utils/infiniteScroll'
import debounce from '@/utils/debounce'
import { UInput } from 'unit-uikit'

export default defineComponent({
  name: 'AddOrder2',
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    v$: Object,
    budget: {
      type: Object as PropType<APIAddWorkersBudget>,
      required: true
    },
    specialErrors: Object
  },
  data() {
    return {
      loading: true,
      workers: [] as any,
      checkedWorkers: [] as any[],
      checkedWorkersData: [] as any[],
      searchFirstName: '',
      searchLastName: '',
      isAllWorkers: true,
      ready: false,
      workersAmount: null as any,
      startDate: '',
      endDate: '',
      suborders: {},
      infiniteLoader: false,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 10,
        page: 1
      },
      debounceLoadWorkersWithParams: null as any
    }
  },
  components: {
    WorkerTableItem,
    UInput,
    Loader,
    WorkerWarningLimits
  },
  methods: {
    async getWorkersInfo(workersList: any) {
      this.loading = true
      const asyncRes = await Promise.all(
        workersList.map(async (id: any) => {
          const response = await axios.get(API.PROFILE_WORKER(id))

          const worker = response?.data
          if (worker) {
            worker.id = id
          }
          return worker
        })
      )
      this.loading = false
      return asyncRes
    },
    loadWorkersWithParams() {
      this.scrollData.page = 0
      this.workers = []
      this.loadWorkers()
    },
    loadWorkers() {
      if (!this.infiniteLoader) {
        if (this.workers.length < this.scrollData.count) {
          this.infiniteLoader = true
          this.scrollData.page++

          this.startDate = dayjs(new Date(this.order.start_date)).format('YYYY-MM-DD')
          this.endDate = dayjs(new Date(this.order.finish_date)).format('YYYY-MM-DD')

          let queryString = `?page=${this.scrollData.page}&count=${this.scrollData.size}&complete_data=true&personaldata__is_self_employed=true`

          if (this.searchFirstName) {
            queryString += `&first_name=${this.searchFirstName}`

            if (this.workers.length && !(this.workers.length < this.scrollData.requestCount)) {
              this.infiniteLoader = false
              return
            }
          }
          if (this.searchLastName) {
            queryString += `&last_name=${this.searchLastName}`

            if (this.workers.length && !(this.workers.length < this.scrollData.requestCount)) {
              this.infiniteLoader = false
              return
            }
          }
          axios
            .get(API.ORDER_PREINVITED_WORKERS + queryString + '&verified=true')
            .then((response) => {
              this.scrollData.requestCount = response.data.count
              const workers = response.data.results.map((item: any) => {
                item.id = item.personaldata?.user
                return item
              })
              this.workers.push(...workers).sort((a: any, b: any) => (a.last_name > b.last_name ? 1 : -1))

              this.infiniteLoader = false
            })
            .catch(() => {
              this.infiniteLoader = false
            })
        }
      }
    },
    onScroll() {
      if (this.workers.length < this.scrollData.count) {
        onScroll(this.$refs.orderLIstWrapper, this.$refs.orderLIstContent, this.loadWorkers)
      }
    },
    updateOrder() {
      this.order.suborder = this.checkedWorkersData.filter((w: any) => {
        return this.checkedWorkers.includes(w.id)
      })
    },
    addWoker(id: any) {
      if (!this.checkedWorkers.includes(id) && this.checkedWorkers.length < this.workersAmount) {
        this.checkedWorkers.push(id)
      }
    },
    removeWoker(id: any) {
      this.checkedWorkers = this.checkedWorkers.filter((item: any) => item !== id)
    },
    removeWorkersOutOfLimit() {
      this.checkedWorkersData.forEach((item: any) => {
        if (this.disableAddWorker(item)) {
          this.removeWoker(item.id)
        }
      })
    },
    disableAddWorker(worker: any) {
      if (this.order.withholding_tax) {
        return worker.month_limit <= 0
      }
      return worker.month_limit_without_tax <= 0
    }
  },
  created() {
    this.workersAmount = this.budget?.workersAmount

    this.startDate = dayjs(new Date(this.order?.start_date)).format('YYYY-MM-DD')
    this.endDate = dayjs(new Date(this.order?.finish_date)).format('YYYY-MM-DD')

    axios
      .get(
        API.ORDER_PREINVITED_WORKERS + `?count=${this.scrollData.size}&complete_data=true&verified=true&personaldata__is_self_employed=true`
      )
      .then((response) => {
        this.scrollData.count = response.data.count
        this.scrollData.requestCount = response.data.count

        this.workers = response.data.results
          .map((item: any) => {
            item.id = item.personaldata?.user
            if (this.order?.suborder?.length) {
              this.order.suborder = this.order?.suborder.map((suborder: any) => {
                if (suborder.id + '' === item.id + '') {
                  suborder.personaldata = item.personaldata
                  suborder.first_name = item.first_name
                  suborder.last_name = item.last_name
                  suborder.middle_name = item.middle_name
                }
                return suborder
              })
            }
            return item
          })
          .sort((a: any, b: any) => (a.last_name > b.last_name ? 1 : -1))

        this.loading = false
        this.ready = true
        this.infiniteLoader = false
      })
      .catch((e) => {
        console.error(e)
        this.loading = false
        this.ready = true
        this.infiniteLoader = false
      })
  },
  beforeUpdate() {
    this.removeWorkersOutOfLimit()
  },
  watch: {
    ready() {
      if (this.ready && this.order?.suborder?.length) {
        this.checkedWorkers = this.order.suborder.map((order: any) => {
          return order.id
        })
      }
    },
    searchFirstName() {
      if (this.debounceLoadWorkersWithParams) {
        this.debounceLoadWorkersWithParams()
      } else {
        this.debounceLoadWorkersWithParams = debounce(() => {
          this.loadWorkersWithParams()
        })
        this.debounceLoadWorkersWithParams()
      }
    },
    searchLastName() {
      if (this.debounceLoadWorkersWithParams) {
        this.debounceLoadWorkersWithParams()
      } else {
        this.debounceLoadWorkersWithParams = debounce(() => {
          this.loadWorkersWithParams()
        })
        this.debounceLoadWorkersWithParams()
      }
    }
  },
  computed: {
    async selectedWorkersData() {
      let idsWorkersActiveAgreement: any = []

      const aggrementsData = await axios.post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, {
        workers: this.checkedWorkers
      })

      if (aggrementsData?.data) {
        aggrementsData.data.forEach((worker: any) => {
          idsWorkersActiveAgreement.push(worker.worker_id)
        })
      }

      const workers = await this.getWorkersInfo(this.checkedWorkers)

      workers.map((item: any) => {
        if (idsWorkersActiveAgreement.includes(item.id)) {
          item.agreement = [true]
        }
      })

      this.checkedWorkersData = workers
      this.updateOrder()
      return workers
    }
  }
})
</script>

<style lang="postcss" scoped>
.form {
  @apply items-start !flex-row gap-[18px] overflow-hidden;
}

.scroll-bar {
  @apply shadow-main border-l-0 border-white rounded-lg bg-white overflow-x-hidden overflow-y-auto basis-1/2;

  &::-webkit-scrollbar {
    @apply w-2 h-8;
  }

  &::-webkit-scrollbar-track {
    @apply bg-white;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-accent/30;
    @apply rounded-full;
  }

  &::-webkit-scrollbar-thumb:hover {
    @apply bg-accent;
  }
}

table td {
  @apply pl-6 pr-10;
}

.field-selected td {
  @apply bg-gray-100;
}

.citizenship {
  @apply font-normal pl-1 text-xs leading-3 text-black;
}

.worker-select-btn {
  @apply min-w-[36px] min-h-[36px] w-9 h-9 bg-accent leading-4 text-white text-center text-3xl rounded-lg;
}
</style>
