<script setup lang="ts">
import { ref, watch } from 'vue'
import { UTable, UAlert, UInput, UISelect, UDateRange } from 'unit-uikit'
import { EReplenishmentAccount, REPLENISHMENT_ACCOUNT_LABELS } from '@/types/api-values'

import { formatDate, formatPrice, formatToServerDate } from '@/utils/helpers'

import axios from 'axios'
import { API } from '@/utils/API'
import debounce from '@/utils/debounce'
import { useObjectsStore } from '@/stores/objects'
import { storeToRefs } from 'pinia'

const filterDates = ref({
  start_date: null,
  finish_date: null
}) as any

const accounts = ref([]) as any
const billingAccount = ref('')
const selectedStatus = ref('')
const selectedAmount = ref('')
const selectedObject = ref(null)
const objectsStore = useObjectsStore()
objectsStore.fetcObjects()
const { objectOptions } = storeToRefs(objectsStore)

const loading = ref(false)

const statusOptions = [
  {
    id: '',
    name: 'Все'
  },
  {
    id: EReplenishmentAccount.not_paid,
    name: REPLENISHMENT_ACCOUNT_LABELS[EReplenishmentAccount.not_paid]
  },
  {
    id: EReplenishmentAccount.bank_transfered,
    name: REPLENISHMENT_ACCOUNT_LABELS[EReplenishmentAccount.bank_transfered]
  },
  {
    id: EReplenishmentAccount.deleted,
    name: REPLENISHMENT_ACCOUNT_LABELS[EReplenishmentAccount.deleted]
  }
]

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

function normalizeAccounts(serverAccount: any) {
  if (serverAccount.account_file) {
    serverAccount.account_file = serverAccount.account_file.replace('https%3A', 'https:/')
  }
  return serverAccount
}

const loadNewAccountsToBill = () => {
  scrollData.count = 0
  scrollData.page = 1
  accounts.value = []

  axios
    .get(API.GET_BILLING_ACCOUNTS + `?page=${scrollData.page}&count=${scrollData.size}`)
    .then((response) => {
      scrollData.count = response.data.count || 0
      accounts.value = response.data?.results?.map(normalizeAccounts) || []
      loading.value = false
    })
    .catch((e) => {
      console.error(e)
      loading.value = false
    })
}

loadNewAccountsToBill()

const loadAccountsToBill = async () => {
  if (accounts.value.length < scrollData.count) {
    let queryParams = ''

    if (billingAccount.value) {
      queryParams += `&number=${billingAccount.value}`

      if (accounts.value.length && !(accounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    if (selectedAmount.value) {
      queryParams += `&amount=${selectedAmount.value}`

      if (accounts.value.length && !(accounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    if (selectedStatus.value) {
      queryParams += `&status=${selectedStatus.value}`

      if (accounts.value.length && !(accounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    if (selectedObject.value && selectedObject.value !== 'allObjects') {
      queryParams += `&object_id=${selectedObject.value}`

      if (accounts.value.length && !(accounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    const startDate = filterDates.value.start_date ? new Date(filterDates.value.start_date) : null
    const finishDate = filterDates.value.finish_date ? new Date(filterDates.value.finish_date) : null

    if (startDate) {
      queryParams += `&start_date=${formatToServerDate(startDate)}`

      if (accounts.value.length && !(accounts.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (finishDate) {
      queryParams += `&end_date=${formatToServerDate(finishDate)}`

      if (accounts.value.length && !(accounts.value.length < scrollData.requestCount)) {
        return
      }
    }

    scrollData.page++
    const queryString = `?page=${scrollData.page}&count=${scrollData.size}` + queryParams
    try {
      const response = await axios.get(API.GET_BILLING_ACCOUNTS + queryString)
      const data = response.data
      if (data.results && data.count) {
        scrollData.requestCount = data.count
        const result = data.results.map(normalizeAccounts)
        accounts.value.push(...result)
      }
    } catch (e) {}
  }
}

const getAlertType = (status: EReplenishmentAccount) => {
  switch (status) {
    case EReplenishmentAccount.not_paid:
      return 'info'
    case EReplenishmentAccount.bank_transfered:
      return 'success'
    case EReplenishmentAccount.deleted:
      return 'error'

    default:
      return 'info'
  }
}

const openEditMenu = (id: string | number) => {
  accounts.value = accounts.value.map((item: any) => {
    const itemId = item.id
    if (id + '' === itemId + '') {
      item.showEditMenu = !item.showEditMenu
    } else {
      item.showEditMenu = false
    }
    return item
  })
}

const closeEditMenu = () => {
  accounts.value = accounts.value.map((item: any) => {
    item.showEditMenu = false
    return item
  })
}

const loadAccountsToBillWithParams = () => {
  scrollData.page = 0
  accounts.value = []
  loadAccountsToBill()
}

const debounceloadAccountsToBillWithParams = debounce(() => {
  loadAccountsToBillWithParams()
})

watch([billingAccount, selectedAmount, selectedStatus, selectedObject], debounceloadAccountsToBillWithParams)

watch(
  () => filterDates.value.start_date,
  () => {
    debounceloadAccountsToBillWithParams()
  }
)

watch(
  () => filterDates.value.finish_date,
  () => {
    debounceloadAccountsToBillWithParams()
  }
)

const getObjectsName = (ids: []) => {
  return ids
    .map((id) => {
      const option = objectOptions.value.find((option) => {
        return option.id + '' === id + ''
      })
      return option?.name || id
    })
    .join(', ')
}

const removeBillAccount = (id: string) => {
  if (id) {
    axios
      .patch(API.UPDATE_BILLING_ACCOUNT(id), {
        status: EReplenishmentAccount.deleted
      })
      .then(() => {
        loadNewAccountsToBill()
      })
      .catch()
  }
}

const downloadFile = (item: any) => {
  if (item.fileProcessing) {
    return
  }
  item.fileProcessing = true
  const id = item?.id
  if (id) {
    axios
      .get(API.GET_BILLING_ACCOUNT_FILE(id))
      .then((res: any) => {
        const { file } = res.data
        if (file) {
          item.account_file = file
        }
        const a = document.createElement('a')
        a.href = file
        a.target = '_blank'
        a.setAttribute('download', file + '.zip')
        // document.body.appendChild(a)
        a.click()
        item.fileProcessing = false
      })
      .catch(() => {
        item.fileProcessing = false
      })
  } else {
    item.fileProcessing = false
  }
}
</script>

<template>
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>

  <div class="flex flex-wrap xl:flex-nowrap gap-3 mb-2">
    <UInput
      id="billingAccount"
      class="basis-1/5 flex-grow"
      placeholder="Введите номер"
      label="Поиск по номеру"
      v-model="billingAccount"
      :value="billingAccount"
    />

    <UISelect
      id="objectSelector"
      class="basis-1/5 flex-grow"
      label="Объект"
      unselectedLabel="Выберите объект"
      v-model="selectedObject"
      :value="selectedObject"
      :options="objectOptions"
    />

    <UDateRange
      class="max-w-[230px] flex-grow"
      :start="filterDates?.start_date"
      :finish="filterDates?.finish_date"
      @update-start-date="(date: string) => { filterDates.start_date = date }"
      @update-finish-date="(date: string) => { filterDates.finish_date = date }"
      title="Дата"
      startPlaceHolder="От"
      endPlaceHolder="До"
    />

    <UInput
      id="selectedAmount"
      class="basis-1/5 flex-grow"
      placeholder="Сумма"
      label="Поиск по сумме"
      v-model="selectedAmount"
      :value="selectedAmount"
    />

    <UISelect
      id="accountStatusSelector"
      class="basis-1/5 flex-grow"
      label="Статус"
      unselectedLabel="Любой"
      v-model="selectedStatus"
      :value="selectedStatus"
      :options="statusOptions"
    />
  </div>

  <u-table colspan="7" :callback="loadAccountsToBill">
    <template #thead>
      <tr>
        <th class="w-[150px]">№ платёжного поручения</th>
        <th>Объект</th>
        <th class="w-[120px]">Дата</th>
        <th class="w-[140px]">Сумма</th>
        <th class="w-[170px]">Статус</th>
        <th class="w-[110px]"></th>
        <th class="w-[60px]"></th>
      </tr>
    </template>
    <template #tbody>
      <tr
        v-for="item in accounts"
        :key="item.id"
        class="whitespace-nowrap"
        :class="item.status === EReplenishmentAccount.deleted ? 'deleted' : ''"
      >
        <td>
          <span class="block overflow-hidden text-ellipsis" v-tooltip> № {{ item.payment_number }}</span>
        </td>
        <td>
          <span v-if="objectOptions.length" class="block overflow-hidden text-ellipsis" v-tooltip>{{
            getObjectsName(item.objects_list || [])
          }}</span>
        </td>
        <td>{{ formatDate(item.date, false) }}</td>
        <td>
          <span class="whitespace-nowrap">{{ `${formatPrice(item.amount)}&nbsp;₽` }}</span>
        </td>
        <td>
          <u-alert :type="getAlertType(item.status)" :label="REPLENISHMENT_ACCOUNT_LABELS[item.status]" class="mr-2" />
        </td>
        <td>
          <template v-if="item.status !== EReplenishmentAccount.deleted">
            <a v-if="item.account_file" :href="item.account_file" target="_blank" download class="download-btn">Скачать</a>
            <a v-else href="" class="download-btn" @click.prevent="downloadFile(item)">
              <span v-if="item.fileProcessing">Создаётся..</span>
              <span v-else>Скачать</span>
            </a>
          </template>
        </td>
        <td>
          <button
            type="button"
            class="edit-btn relative"
            v-if="item.status === EReplenishmentAccount.not_paid"
            @click="openEditMenu(item.id)"
          >
            <img class="" src="/icons/extra.svg" alt="Редактировать" />
            <div v-if="item.showEditMenu" class="edit-menu flex flex-col">
              <button type="button" class="btn-transparent" @click="removeBillAccount(item.id)">Удалить</button>
            </div>
          </button>
        </td>
      </tr>
    </template>
  </u-table>
</template>

<style lang="postcss" scoped>
.edit-menu {
  @apply fixed right-[50px] transform-none;
}

td,
th {
  @apply overflow-hidden text-ellipsis;
  padding-left: 6px;
  padding-right: 6px;
}

.download-btn {
  @apply whitespace-nowrap cursor-pointer text-accent;

  &:hover {
    @apply text-accentDarker;
  }
}

:deep(table) {
  @apply table-fixed;
}

tr.deleted {
  @apply opacity-50;
}
</style>
