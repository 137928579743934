<script setup lang="ts">
import { onMounted, onUnmounted, computed, ref, watch } from 'vue'
import { useRouter, useRoute, Route } from 'vue-router'
import { useCompanyStore } from '@/stores/company'
import { useOrderStore } from '@/stores/order'
import { storeToRefs } from 'pinia'
import { UTooltip, UButton, UISelect, UInput, UModal, UICheckbox, UTable, UTextarea, UMultiselect } from 'unit-uikit'
import { APIGetTasks } from '@/interfaces/main'
import DocItem from './DocItem.vue'
import Loader from '@/components/Loader/Loader.vue'
import jsonToFormData from '@/utils/jsonToForm'
import WorkResults from '@/components/Modals/WorkResults.vue'
import UploadDocs from '@/components/Button/UploadDocs.vue'
import ChangeSuborderModal from '@/components/Modals/ChangeSuborderModal.vue'
import FileItemView from '@/components/Button/FileItemView.vue'
import CancelOrderReason from '@/components/Modals/CancelOrder.vue'
import ConfirmSignModal from '@/components/Modals/ConfirmSignModal.vue'
import ErrorTooltip from '@/components/Tooltips/ErrorTooltip.vue'
import { API } from '@/utils/API'
import axios from 'axios'
import COUNTRIES, { showPassportFieldByCountry } from '@/utils/Countries'
import { getSuborderStatus, getSuborderStatusColor } from './utils'
import { formatDate } from '@/views/Workers/Profile/helpers'
import { SUBORDER_CHANGE_REASON, SUBORDER_CHANGE_TYPES } from '@/utils/consts'
import { ESuborderStatus, EDocsStatus } from '@/types/api-values'
import { formatPrice } from '@/utils/helpers'
import d from '@/dictionary'

const router = useRouter()
const route: Route = useRoute()
const companyStore = useCompanyStore()
const orderStore = useOrderStore()
const { isProcurationEnded } = storeToRefs(companyStore)

const pageReady = ref(false)
const processing = ref(false)
const searchString = ref('')
const statusFilter = ref('')
const statusFilterOptions = [
  {
    id: 'status_all',
    name: 'Все'
  },
  {
    id: 'status_signed',
    name: 'Подписанные'
  },
  {
    id: 'status_not-signed',
    name: 'Не подписанные'
  }
]

const loading = ref(false)

const interval: any = ref(null)
const allDocsSigned = ref(true)

const tasks: any = ref([])
const checkedTasks = ref([])
const subOrderId = ref('')
const order = ref(null) as any

const cancelOrderReason = ref('')
const reason_for_cancellation = ref('')
const reasonComment = ref('')
const selectedReason = ref([])
const backToWorkFiles = ref([])
const backToWorkOptions = SUBORDER_CHANGE_REASON
const editableTask = ref(null) as any
const submitTry = ref(false)
const unitTypesList = ref([]) as any
const pageX = ref(0)
const pageY = ref(0)
const isEditableSuborder = ref(false)
const editableSuborder = ref(null) as any
const failComment = ref('')
const isDepositExceed = ref(false)
const freeMoney = ref(0)
const cancelTaskReason = ref(null) as any
const isReadyToPay = ref(false)

const selectAll = ref(false)
const isShowPopup = ref(false)
const isShowPopupAct = ref(false)
const isShowReturnToWorkModal = ref(false)
const isShowChangeSuborderModal = ref(false)
const isShowResults = ref(false)
const acceptanceCertificateExists = ref(false)
const showConfirmSignModal = ref(false)
const showConfirmSignModalChange = ref(false)
const failedEdit = ref(false)

const longPullingTime = 10000

const getCitizenship = (citizenship: any) => {
  return COUNTRIES[citizenship]
}

const formatToServerDate = (date: string) => {
  if (!date) {
    return ''
  }
  const value = new Date(date)
  return `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`
}

const getPriceForServer = (price: any) => {
  const _price = (price + '').replaceAll(',', '.').replaceAll(' ', '')
  if (_price && +_price > 0) {
    return _price
  }
  return 0
}

onMounted(() => {
  updateDocs()

  pageReady.value = true

  getTasks()

  orderStore
    .fetchOrder(route?.params?.id)
    .then((data: any) => {
      order.value = data
    })
    .catch((e: any) => {
      console.error(e)
    })

  axios.get(API.GET_UNIT_TYPES).then((res: any) => {
    unitTypesList.value = res.data
  })

  axios.get(API.GET_BILLS_BALANCE).then((res: any) => {
    const depositMoney = res.data ? res.data.company_deposit : 0
    const reservedMoney = res.data ? res.data.total_reserve : 0
    freeMoney.value = depositMoney - reservedMoney
  })
})

onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value)
  }
})

const updateDocs = () => {
  processing.value = true
  axios
    .get(API.GET_ORDER_DOCUMENTS(route?.params?.id))
    .then((res: any) => {
      getTasks()

      res.data.forEach((doc: any) => {
        if (
          (doc.agreement_status && doc.agreement_status[0] && doc.agreement_status[0].status !== 'signed') ||
          (doc.task_order_status && doc.task_order_status[0] && doc.task_order_status[0].status !== 'signed') ||
          doc.acceptance_certificate_status[0]?.status !== 'signed'
        ) {
          allDocsSigned.value = false
        }
      })

      if (!allDocsSigned.value) {
        interval.value = setInterval(() => {
          if (processing.value) {
            return
          }

          processing.value = true
          axios
            .get(API.GET_ORDER_DOCUMENTS(route?.params?.id))
            .then((res: any) => {
              const routeName = route.name

              if (routeName !== 'order.docs') {
                clearInterval(interval.value)
                return
              }

              allDocsSigned.value = true

              getTasks()

              res.data.forEach((doc: any) => {
                if (
                  (doc.agreement_status && doc.agreement_status[0] && doc.agreement_status[0].status !== 'signed') ||
                  (doc.task_order_status && doc.task_order_status[0] && doc.task_order_status[0].status !== 'signed') ||
                  doc.acceptance_certificate_status[0]?.status !== 'signed'
                ) {
                  allDocsSigned.value = false
                }
              })
              if (allDocsSigned.value) {
                clearInterval(interval.value)
              }
              processing.value = false
            })
            .catch(() => {
              processing.value = false
            })
        }, longPullingTime)
      }
      processing.value = false
    })
    .catch(() => {
      processing.value = false
    })
}

const orderReadyForPay = () => {
  isReadyToPay.value = tasks.value.some((task: any) => {
    return task.acceptance_certificate?.status === 'signed'
  })
}

const filteredTasks = computed(() => {
  let tasksFiltered = tasks.value
  if (searchString.value) {
    tasksFiltered = tasksFiltered.filter((task: any) => {
      const worker = task.worker?.worker_full_name?.toLowerCase() || ''
      const id = (task.task_order?.id + '').toLowerCase()
      return worker.indexOf(searchString.value.toLowerCase()) !== -1 || id.indexOf(searchString.value.toLowerCase()) !== -1
    })
  }

  if (statusFilter.value && statusFilter.value !== 'status_all') {
    tasksFiltered = tasksFiltered.filter((task: any) => {
      if (statusFilter.value === 'status_signed' && task.acceptance_certificate && task.acceptance_certificate_status) {
        return task.acceptance_certificate_status[0].status === 'signed'
      }
      if (statusFilter.value === 'status_not-signed' && task.acceptance_certificate_status) {
        return !task.acceptance_certificate || task.acceptance_certificate_status[0].status === 'not_signed'
      }
    })
  }

  return tasksFiltered.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
})

const disableCancelOrder = computed(() => {
  const disable =
    !(reason_for_cancellation.value && reason_for_cancellation.value !== 'Другое') &&
    !(reason_for_cancellation.value === 'Другое' && cancelOrderReason.value.length > 0)
  return disable
})

const getTaskWorker = computed(() => {
  if (subOrderId.value) {
    const task = tasks.value.filter((item: any) => {
      return item.id + '' === subOrderId.value
    })
    const result = task && task[0] && task[0].worker && task[0].worker.worker_full_name
    return result || ''
  }
  return ''
})

const results = computed(() => {
  if (subOrderId.value) {
    const task = tasks.value.filter((item: any) => {
      return item.id + '' === subOrderId.value
    })
    const result = task && task[0] && task[0].results
    return result || []
  }
  return []
})

const returns = computed(() => {
  if (subOrderId.value) {
    const task = tasks.value.filter((item: any) => {
      return item.id + '' === subOrderId.value
    })
    const result = task && task[0] && task[0].returns
    return result || []
  }
  return []
})

watch(selectAll, () => {
  if (selectAll.value) {
    checkedTasks.value = tasks.value
      .filter((task: any) => {
        return (
          !task.acceptance_certificate &&
          task.status !== ESuborderStatus.cancelled &&
          task.start_work_notification?.status !== 'finished' &&
          checkDocSigned(task)
        )
      })
      .map((task: any) => {
        return task.id
      })
  } else {
    checkedTasks.value = []
  }
})

watch(
  () => route?.params?.id,
  () => {
    getTasks()

    orderStore
      .fetchOrder(route?.params?.id)
      .then((data: any) => {
        order.value = data
      })
      .catch((e: any) => {
        console.error(e)
      })
  }
)

const closeEditMenu = () => {
  tasks.value = tasks.value.map((task: any) => {
    task.showEditMenu = false
    return task
  })
}

const openEditMenu = (event: MouseEvent, id: number | string) => {
  pageX.value = event.pageX
  pageY.value = event.pageY
  tasks.value = tasks.value.map((task: any) => {
    if (id + '' === task.id + '') {
      task.prevUnitType = task.unit_type
      task.prevStartDate = task.start_date
      task.prevFinishtDate = task.finish_date
      task.prevName = task.name
      task.prevPrice = task.price
      task.prevQuantity = task.quantity
      task.showEditMenu = !task.showEditMenu
    } else {
      task.showEditMenu = false
    }
    return task
  })
}

const getTasks = () => {
  const orderId = route?.params?.id
  if (orderId) {
    axios.get(API.GET_SUBORDER_BY_ORDER(orderId)).then((res: { data: [APIGetTasks] }) => {
      tasks.value = res.data.map((sub: any) => {
        if (!sub.name) {
          sub.name = sub.initial_name
        }
        if (!sub.start_date) {
          sub.start_date = formatDate(sub.initial_start_date)
        }
        if (!sub.finish_date) {
          sub.finish_date = formatDate(sub.initial_finish_date)
        }
        if (!(sub.quantity && +sub.quantity > 0)) {
          sub.quantity = sub.initial_quantity
        }
        return sub
      })
      loading.value = false
      orderReadyForPay()
    })
  }
}

const cancelChangeSuborder = () => {
  isShowResults.value = false
  isShowReturnToWorkModal.value = false
  isShowChangeSuborderModal.value = false
  selectedReason.value = []
  reasonComment.value = ''
  backToWorkFiles.value = []
  editableTask.value = null
  submitTry.value = false
}
const changeSuborder = (data: any) => {
  data.id = editableSuborder.value?.id
  data.initial_price = editableSuborder.value?.initial_price
  data.status = editableSuborder.value?.status
  isEditableSuborder.value = false
  editableSuborder.value = null
  editableTask.value = data
  isShowChangeSuborderModal.value = true
}
const showPhotoResults = ($event: any) => {
  subOrderId.value = $event.currentTarget.dataset.suborder
  isShowResults.value = true
}
const showReturnToWorkModal = ($event: any) => {
  if ($event?.currentTarget?.dataset?.suborder) {
    subOrderId.value = $event.currentTarget.dataset.suborder
  }
  isShowReturnToWorkModal.value = true
}
const showPopup = ($event: any, target: any) => {
  editableTask.value = target
  subOrderId.value = $event.currentTarget.dataset.suborder
  isShowPopup.value = true
}
const showPopupAct = ($event: any) => {
  subOrderId.value = $event.currentTarget.dataset.suborder
  isShowPopupAct.value = true
}

const setShangeSuborder = (task: any) => {
  isEditableSuborder.value = true
  editableSuborder.value = task
}
const viewReason = (task: any) => {
  cancelTaskReason.value = task
}
const getUnitType = (unitType: any) => {
  if (unitTypesList.value) {
    const type = unitTypesList.value.filter((u: any) => {
      return u.id + '' === unitType + ''
    })
    return (type && type[0] && type[0].name) || ''
  }
  return ''
}
const getStartDate = (task: any) => {
  return formatDate(task.start_date || task.initial_start_date)
}
const getFinishDate = (task: any) => {
  return formatDate(task.finish_date || task.initial_finish_date)
}
const getPrice = (task: any) => {
  if (task.price && +task.price > 0) {
    return task.price
  }
  return task.initial_price
}

const checkStatus = (status: any) => {
  const _status = status?.toLowerCase() || ''
  return _status.toLowerCase() === EDocsStatus.signed
}

const checkDocSigned = (task: any) => {
  const agreement = task.agreement_status && task.agreement_status[0]
  const order = task.task_order_status && task.task_order_status[0]
  return checkStatus(agreement?.status) && checkStatus(order?.status)
}

const returnToWork = () => {
  processing.value = false
  if (processing.value) {
    return
  }

  processing.value = true

  submitTry.value = true
  const filesCount = backToWorkFiles?.value.length

  if (subOrderId.value) {
    const returnedTaskArr = tasks.value.filter((task: any) => task.id + '' === subOrderId.value + '')
    const task = returnedTaskArr && returnedTaskArr[0]
    const price = task.price ? (task.price + '').replaceAll(',', '.') : ''

    const data = {
      name: task.name,
      start_date: formatToServerDate(task.start_date),
      finish_date: formatToServerDate(task.finish_date),
      price: getPriceForServer(price),
      quantity: task.quantity,
      type: SUBORDER_CHANGE_TYPES.RETURN,
      reason: selectedReason.value.map((reason: any) => reason.value),
      comment: reasonComment.value,
      documents: filesCount ? backToWorkFiles.value : [],
      budget_total: task.initial_price,
      description: task.description,
      new_status: ESuborderStatus.work
    }

    const bodyFormData = jsonToFormData(data)
    const headers: any = {}
    if (filesCount) {
      headers['Content-Type'] = 'multipart/form-data'
    }

    axios
      .post(API.UPDATE_SUBORDER(subOrderId.value), filesCount ? bodyFormData : data, {
        headers
      })
      .then(() => {
        isShowReturnToWorkModal.value = false
        selectedReason.value = []
        reasonComment.value = ''
        backToWorkFiles.value = []
        editableTask.value = null
        tasks.value = []
        getTasks()
        processing.value = false
      })
      .catch(() => {
        processing.value = false
        isShowChangeSuborderModal.value = false
        selectedReason.value = []
        reasonComment.value = ''
        backToWorkFiles.value = []
        editableTask.value = null
        failedEdit.value = true
      })
  } else {
    processing.value = false
  }
}

const confirmSign = () => {
  processing.value = false
  if (processing.value) {
    return
  }
  processing.value = true
  if (checkedTasks.value.length) {
    const orderId = route?.params?.id

    loading.value = true

    axios
      .post(API.POST_SIGN_DOCS(orderId), { sudorder_ids: checkedTasks.value })
      .then((res: any) => {
        if (res.status === 201) {
          acceptanceCertificateExists.value = false
          showConfirmSignModal.value = false
          checkedTasks.value = []
          setTimeout(() => {
            getTasks()
          }, 2000)
        }
        processing.value = false
      })
      .catch(() => {
        acceptanceCertificateExists.value = true
        showConfirmSignModal.value = false
        checkedTasks.value = []
        processing.value = false
        loading.value = false
      })
  } else {
    processing.value = false
  }
}

const confirmSignChange = () => {
  processing.value = false
  if (processing.value) {
    return
  }
  processing.value = true

  submitTry.value = true

  if (editableTask.value) {
    const target = editableTask.value
    const filesCount = backToWorkFiles?.value?.length
    const price = target.price ? (target.price + '').replaceAll(',', '.') : ''

    const data = {
      name: target.name,
      start_date: formatToServerDate(target.start_date),
      finish_date: formatToServerDate(target.finish_date),
      price: getPriceForServer(price),
      quantity: target.quantity,
      type: SUBORDER_CHANGE_TYPES.CHANGE,
      reason: selectedReason.value.map((reason: any) => reason.value),
      comment: reasonComment.value,
      documents: filesCount ? backToWorkFiles.value : [],
      unit_type: target.unit_type,
      description: target.description,
      budget_total: target.initial_price,
      new_status: target.status
    }

    const bodyFormData = jsonToFormData(data)
    const headers: any = {}
    if (filesCount) {
      headers['Content-Type'] = 'multipart/form-data'
    }

    loading.value = true

    axios
      .post(API.UPDATE_SUBORDER(target.id), filesCount ? bodyFormData : data, { headers })
      .then(() => {
        isShowChangeSuborderModal.value = false
        selectedReason.value = []
        reasonComment.value = ''
        backToWorkFiles.value = []
        editableTask.value = null
        showConfirmSignModalChange.value = false
        isDepositExceed.value = false
        getTasks()
        processing.value = false
      })
      .catch((e: any) => {
        const statusCode = e?.response?.status
        if (statusCode === 400) {
          isDepositExceed.value = true
        }
        isShowChangeSuborderModal.value = false
        selectedReason.value = []
        reasonComment.value = ''
        backToWorkFiles.value = []
        editableTask.value = null
        showConfirmSignModalChange.value = false
        failedEdit.value = true
        processing.value = false
        loading.value = false
      })
  } else {
    processing.value = false
  }
}

const cancelSuborder = () => {
  const id = subOrderId.value
  const comment = reason_for_cancellation.value === 'Другое' ? `Другое: ${cancelOrderReason.value}` : reason_for_cancellation.value

  const data = {
    type: SUBORDER_CHANGE_TYPES.CANCEL,
    reason: ['another_reason'],
    comment: comment,
    new_status: ESuborderStatus.cancelled
  }

  loading.value = true

  axios
    .patch(API.CANCEL_SUBORDER(id), data)
    .then(() => {
      isShowChangeSuborderModal.value = false
      selectedReason.value = []
      reasonComment.value = ''
      backToWorkFiles.value = []
      editableTask.value = null
      isShowPopup.value = false
      getTasks()
    })
    .catch((error: any) => {
      const errors = error?.response?.data

      failComment.value = errors ? errors.join(', ') : ''

      isShowPopup.value = false
      isShowChangeSuborderModal.value = false
      selectedReason.value = []
      reasonComment.value = ''
      backToWorkFiles.value = []
      editableTask.value = null
      failedEdit.value = true
      loading.value = false
    })
}

const cancelSuborderAct = () => {
  const id = subOrderId.value

  loading.value = true

  axios
    .patch(API.CANCEL_SUBORDER_ACT(id), {})
    .then(() => {
      isShowPopupAct.value = false
      getTasks()
    })
    .catch(() => {
      isShowPopupAct.value = false
      loading.value = false
    })
}

const toOmvd = (worker: any) => {
  router.push({
    name: 'omvd.all',
    query: {
      lastName: worker.split(' ')[0],
      firstName: worker.split(' ')[1]
    }
  })
}
</script>
<template>
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>

  <div class="flex flex-wrap lg:flex-nowrap gap-5 mb-3">
    <UInput class="!w-full lg:!w-auto" name="search" searchInput placeholder="Поиск" :value="searchString"
      v-model="searchString" />
    <UISelect id="statusSelector" class="lg:mr-4 !w-full lg:!w-auto" label="Статус подписания"
      unselectedLabel="Выберите статус" v-model="statusFilter" :value="statusFilter || ''"
      :options="statusFilterOptions" />
    <UButton v-if="pageReady && order?.status !== 'finished'" label="Создать акты" :disabled="!checkedTasks.length"
      @click="showConfirmSignModal = true" />
    <div v-if="order?.withholding_tax"
      class="flex mb-2 px-3 py-2 bg-grey1 items-center text-xs text-white font-medium rounded max-w-fit self-end">
      <UTooltip percent percentWhite class="!static mr-3 self-center" :tooltip="d['payment-fee']" />
      <span>{{ d['bank-fee-tooltip'] }}</span>
    </div>
    <router-link v-if="isReadyToPay"
      class="text-base whitespace-nowrap font-medium underline text-accent hover:opacity-60 ml-auto self-center"
      :to="{ name: 'finance.order' }">
      Перейти к оплате
    </router-link>
    <span v-else
      class="cursor-not-allowed text-base whitespace-nowrap font-medium text-grey opacity-60 ml-auto self-center">Перейти
      к оплате</span>
  </div>

  <div class="flex flex-col overflow-hidden flex-grow">
    <Loader v-if="!tasks?.length || loading" />
    <UTable v-if="filteredTasks?.length">
      <template #thead>
        <th>
          <div class="flex items-center">
            <UICheckbox v-if="order?.status !== 'finished' && filteredTasks.length > 1" v-model="selectAll" id="selectAll"
              :value="false" />
            Исполнитель
          </div>
        </th>
        <th>Документы к подписанию</th>
        <th>Период</th>
        <th class="whitespace-nowrap">Объем работ</th>
        <th class="whitespace-nowrap">Тип ед.</th>
        <th>
          <span class="relative">
            Стоимость
            <UTooltip tooltip='"На руки" c налогом, получит исполнитель на карту' />
          </span>
        </th>
        <th v-if="order?.photo_after_completion">Фото</th>
        <th v-if="order?.status !== 'finished'"></th>
      </template>
      <template #tbody>
        <tr v-for="task of filteredTasks" :key="task.id"
          :class="{ 'task-cancelled': task.status === ESuborderStatus.cancelled }" class="relative">
          <td class="text-xs1">
            <div class="flex">
              <div v-if="task.status !== ESuborderStatus.cancelled">
                <template v-if="!task.acceptance_certificate && order?.status !== 'finished'">
                  <UICheckbox class="mr-2" v-model="checkedTasks" :id="task.id" :value="task.id" v-if="checkDocSigned(task) &&
                    task.start_work_notification?.status !== 'rejected' &&
                    task.start_work_notification?.status !== 'exists' &&
                    task.start_work_notification?.status !== 'finished'
                    " />
                  <template v-else-if="!checkDocSigned(task)">
                    <div class="relative flex items-center mr-5">
                      <UTooltip class="!static"
                        tooltip="Чтобы создать акт исполнителю необходимо подписать договор и задание"
                        direction="right" />
                    </div>
                  </template>
                  <div class="relative flex items-center mr-5" v-else-if="task.start_work_notification?.status === 'rejected' ||
                    task.start_work_notification?.status === 'exists' ||
                    task.start_work_notification?.status === 'finished'
                    ">
                    <UTooltip class="!static"
                      tooltip="Чтобы создать акт, необходимо подать уведомление 7 в контролирующий орган, отметить статус подачи “Подано” в разделе Уведомления МВД"
                      direction="right" />
                  </div>
                </template>
                <template v-else-if="task.acceptance_certificate && task.acceptance_certificate?.status === 'signed'">
                  <img class="mr-3 min-w-[24px]" src="/icons/success.svg" alt="OK icon" />
                </template>
                <template v-else>
                  <div class="mr-9"></div>
                </template>
              </div>
              <div :class="{ 'ml-10': task.status === 'cancelled' }" class="flex flex-col whitespace-nowrap">
                <span class="text-sm mb-2">{{ task.worker?.worker_full_name }}</span>
                <span>{{ getCitizenship(task.worker?.worker_citizenship) }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="flex">
              <div class="flex relative mr-8">
                <template v-if="showPassportFieldByCountry('start_work_notification', task.worker?.worker_citizenship)">
                  <div @click="toOmvd(task.worker.worker_full_name)" v-if="task.start_work_notification?.status === 'rejected' ||
                    task.start_work_notification?.status === 'exists' ||
                    task.start_work_notification?.status === 'finished'
                    ">
                    <ErrorTooltip doc7 class="tooltip-notification7"
                      :tooltip="`Уведомление 7 не подано в контролирующий орган. Пожалуйста, воспользуйтесь разделом Уведомления МВД для подачи уведомления 7 отметьте статус подачи “Подано”. Без этого невозможно создать акт.`" />
                  </div>

                  <a v-else-if="(task.start_work_notification?.file && task.start_work_notification?.status !== 'rejected') ||
                    task.start_work_notification?.status !== 'exists'
                    " :href="task.start_work_notification?.file" target="_blank"
                    data-tooltip="Приложение №7, cкачается при клике" class="tooltip-icon tooltip-notification7">
                    <img src="/icons/doc7.svg" alt="Приложение 7" class="!min-w-[32px]" />
                  </a>
                  <div v-else class="tooltip-icon tooltip-notification7 mr-2" data-tooltip="Приложение №7 создается...">
                    <img src="/icons/doc7.svg" alt="Приложение 7" class="min-w-[32px] grayscale" />
                  </div>

                  <a v-if="task.end_work_notification?.file" :href="task.end_work_notification?.file"
                    class="tooltip-icon tooltip-notification8" data-tooltip="Приложение №8, cкачается при клике"
                    target="_blank">
                    <img src="/icons/doc8.svg" alt="App icon" class="!min-w-[32px]" />
                  </a>
                  <div v-else class="tooltip-icon tooltip-notification8" data-tooltip="Приложение №8 создается...">
                    <img src="/icons/doc8.svg" alt="App icon" class="!min-w-[32px] grayscale" />
                  </div>
                </template>
                <div class="mr-16" v-else></div>
              </div>
              <div class="flex gap-5">
                <DocItem title="Договор" :document="task.agreement"
                  :class="{ 'document-cancelled': task.status === 'cancelled' }" :orderStatus="order?.status"
                  :status="task.agreement_status && task.agreement_status[0] && task.agreement_status[0]?.status"
                  @update="updateDocs" />
                <DocItem title="Задание" :document="task.task_order"
                  :class="{ 'document-cancelled': task.status === 'cancelled' }" :orderStatus="order?.status"
                  :status="task.task_order_status && task.task_order_status[0] && task.task_order_status[0]?.status"
                  @update="updateDocs" />
                <DocItem title="Акт" :document="task.acceptance_certificate"
                  :class="{ 'document-cancelled': task.status === 'cancelled' }" :orderStatus="order?.status" :status="task.acceptance_certificate_status &&
                    task.acceptance_certificate_status[0] &&
                    task.acceptance_certificate_status[0]?.status
                    " @update="updateDocs" />
              </div>
            </div>
          </td>
          <td class="whitespace-nowrap">
            <div class="flex flex-col">
              <span>
                {{ getStartDate(task) }}
              </span>
              <span>
                {{ getFinishDate(task) }}
              </span>
            </div>
          </td>
          <td>{{ task.quantity }}</td>
          <td class="whitespace-nowrap">{{ getUnitType(task.unit_type || task.unit_types?.id) }}</td>
          <td class="relative">
            <div class="whitespace-nowrap">{{ formatPrice(getPrice(task)) }}&nbsp;₽</div>
            <div class="absolute -top-5 z-[1]">
              <span :class="{
                '!border-lightGreen, !text-success': task.status === 'finished' || task.status === 'pending_payment',
                '!border-lightGreen, !text-accent':
                  task.status === 'awaiting_signing' || task.status === 'in_work' || task.status === 'active',
                '!border-lightRed, !text-error opacity-40': task.status === 'cancelled',
                '!bg-transparent': task.status === 'cancelled'
              }" class="status-item" :style="{
  backgroundColor: getSuborderStatusColor(task.status)
}">{{ getSuborderStatus(task.status) }}</span>
            </div>
          </td>
          <td v-if="order?.photo_after_completion">
            <template v-if="task?.results">
              <img src="/icons/photo-icon.svg" class="photo-result hover:opacity-60 cursor-pointer" alt="Фото"
                :data-suborder="task.id" @click="showPhotoResults" />
            </template>
          </td>
          <td>
            <div class="flex justify-end">
              <div class="w-10" v-if="order?.status !== 'finished'">
                <button type="button" class="edit-btn" @click="openEditMenu($event, task.id)"
                  :class="{ '!hidden': task.status === ESuborderStatus.cancelled }">
                  <img class="" src="/icons/extra.svg" alt="Редактировать" />
                  <div v-if="task.showEditMenu" class="edit-menu">
                    <button class="btn-transparent" :disabled="!!task.acceptance_certificate"
                      @click="setShangeSuborder(task)">
                      Изменить
                    </button>
                    <button class="btn-transparent" :data-suborder="task.id" :disabled="!order?.photo_after_completion"
                      @click="showPhotoResults">
                      Результат работы
                    </button>
                    <button id="backToWork" class="btn-transparent" :data-suborder="task.id" :disabled="task.status === ESuborderStatus.active ||
                      task.status === ESuborderStatus.work ||
                      task.acceptance_certificate?.status?.toLowerCase() === 'signed'
                      " @click="showReturnToWorkModal">
                      Вернуть в работу
                    </button>
                    <button class="btn-transparent" :disabled="!(task.status === ESuborderStatus.cancelled)"
                      :data-suborder="task.id" @click="viewReason(task)">
                      Причина отмены
                    </button>

                    <button class="btn-transparent" data-revoke-act-orders="true" :data-suborder="task.id" :disabled="!(task.acceptance_certificate && task.acceptance_certificate.status?.toLowerCase() !== 'signed') ||
                      isProcurationEnded
                      " @click="showPopupAct">
                      Отозвать акт
                    </button>
                    <button class="btn-transparent" :data-suborder="task.id"
                      :disabled="!(!task.acceptance_certificate || task.acceptance_certificate.status?.toLowerCase() !== 'signed')"
                      @click="showPopup($event, task)">
                      Отменить заказ
                    </button>
                  </div>
                </button>
              </div>
            </div>
          </td>
          <div class="order-wrap">
            <div class="flex order-info">
              <span class="mr-2 whitespace-nowrap">Заказ:</span>
              <p class="w-[calc(70vw_-_105px)]">
                <b id="task_id" class="font-medium"> №{{ task.id }}</b> {{ task.name }}
              </p>
            </div>
          </div>
        </tr>
      </template>
    </UTable>
    <span class="bg-white block p-4" v-else>Нет данных</span>
  </div>

  <WorkResults :show="isShowResults" :closeAction="cancelChangeSuborder" :returnToWorkAction="() => {
    ; (isShowResults = false), showReturnToWorkModal
  }
    " :returns="returns" :results="results" :worker="getTaskWorker" />
  <UModal title="Укажите причину отмены заказа" @cancel="isShowPopup = false" :show="isShowPopup">
    <template #content>
      <p class="text-grey">При отмене заказа необходимо указать причину расторжения договора с исполнителем.</p>
      <div>
        <label class="gap-x-2 items-center flex">
          <input type="radio" name="option1" v-model="reason_for_cancellation"
            value="Исполнитель не согласен с текущими условиями" />
          <span>Исполнитель не согласен с текущими условиями</span>
        </label>
        <label class="gap-x-2 items-center flex">
          <input type="radio" name="option1" v-model="reason_for_cancellation"
            value="Исполнитель отказался выполнять задание" />
          <span>Исполнитель отказался выполнять заказ</span>
        </label>
        <label class="gap-x-2 items-center flex">
          <input type="radio" name="option1" v-model="reason_for_cancellation" value="Некачественное выполнение работы" />
          <span>Некачественное выполнение работы</span>
        </label>
        <label class="gap-x-2 items-center flex">
          <input type="radio" name="option1" v-model="reason_for_cancellation" value="Не полное выполнение работы" />
          <span>Не полное выполнение работы</span>
        </label>
        <label class="gap-x-2 items-center flex">
          <input type="radio" name="option1" v-model="reason_for_cancellation" value="Мошенничество" />
          <span>Мошенничество</span>
        </label>
        <div class="gap-x-2 items-center flex">
          <input type="radio" name="option1" v-model="reason_for_cancellation" value="Другое" />
          <label for="option1" class="mr-3">Другое</label>
          <input type="text" class="w-full rounded-lg p-3 text-sm border" placeholder="Введите причину"
            v-model="cancelOrderReason" />
        </div>
      </div>
      <UButton @click="cancelSuborder" :disabled="disableCancelOrder" label="Отменить заказ" />
    </template>
    <template #buttons>
      <div></div>
    </template>
  </UModal>
  <UModal class="back-to-work" title="Вернуть в работу" @cancel="cancelChangeSuborder" :show="isShowReturnToWorkModal">
    <template #content>
      <div class="relative mb-4">
        <span class="absolute input-title">Причина</span>
        <UMultiselect v-model="selectedReason" :options="backToWorkOptions" placeholder="Выберите причину"
          :multiple="true" :searchable="false" :close-on-select="true" label="label" track-by="value" :showLabels="false">
        </UMultiselect>
      </div>

      <div class="error-block-wrapper mb-4">
        <label class="input-wrapper !h-36">
          <h3 class="input-wrapper__title">Комментарий</h3>
          <UTextarea maxLength="1000" name="reasonComment" id="reasonComment"
            placeholder="Дополните комментарием причину возвращения заказа в работу" v-model="reasonComment"
            :value="reasonComment" />
        </label>
        <div class="error-block-message" v-if="submitTry && !reasonComment.length">Обязательное поле</div>
      </div>

      <template v-if="backToWorkFiles.length">
        <div class="mb-4">
          <FileItemView v-for="(file, id) in backToWorkFiles" :key="id" label="Документ" :file="file"
            v-model="backToWorkFiles" class="mb-1" />
        </div>
      </template>

      <div class="flex flex-col">
        <UploadDocs label="Прикрепить документ" id="uploadReturnToWorkDoc" class="mb-4 rounded-lg hover:bg-gray-100"
          v-model="backToWorkFiles" />
        <UButton label="Вернуть в работу" :disabled="!(reasonComment.length && selectedReason.length)"
          @click.prevent="returnToWork" />
      </div>
    </template>
    <template #buttons>
      <div></div>
    </template>
  </UModal>
  <UModal class="reason-of-change" title="Причина изменения" @cancel="cancelChangeSuborder"
    :show="isShowChangeSuborderModal">
    <template #content>
      <div class="relative mb-4">
        <span class="absolute input-title">Причина</span>
        <UMultiselect v-model="selectedReason" :options="backToWorkOptions" placeholder="Выберите причину"
          :multiple="true" :searchable="false" :close-on-select="true" label="label" track-by="value" :showLabels="false">
        </UMultiselect>
      </div>

      <div class="error-block-wrapper mb-4">
        <label class="input-wrapper !h-36">
          <h3 class="input-wrapper__title">Комментарий</h3>
          <UTextarea maxLength="1000" name="reasonComment" id="reasonComment"
            placeholder="Дополните комментарием причину изменения" v-model="reasonComment" :value="reasonComment" />
        </label>
        <div class="error-block-message" v-if="submitTry && !reasonComment.length">Обязательное поле</div>
      </div>

      <template v-if="backToWorkFiles.length">
        <div class="mb-4">
          <FileItemView v-for="(file, id) in backToWorkFiles" :key="id" label="Документ" :file="file"
            v-model="backToWorkFiles" class="mb-1" />
        </div>
      </template>
      <div class="flex flex-col">
        <UploadDocs label="Прикрепить документ" id="uploadReturnToWorkDoc" class="mb-4 rounded-lg hover:bg-gray-100"
          v-model="backToWorkFiles" />
        <UButton label="Сохранить изменения" :disabled="!(reasonComment.length && selectedReason.length)"
          @click.prevent="confirmSignChange" />
      </div>
    </template>
    <template #buttons>
      <div></div>
    </template>
  </UModal>

  <CancelOrderReason :order="order" :taskReturns="cancelTaskReason" @close-modal="cancelTaskReason = null" />

  <UModal :show="isShowPopupAct" title="Отозвать акт" successLabel="Отозвать" @cancel="isShowPopupAct = false"
    @success="cancelSuborderAct">
    <template #content>
      <p>Вы уверены, что хотите отозвать подписание Акта? После отзыва акта все объемы и суммы заказа вернутся в прежнее
        состояние</p>
    </template>
  </UModal>

  <UModal :show="failedEdit" title="Извините, что-то пошло не так!" cancelLabel="" @cancel="() => {
    failComment = ''
    failedEdit = false
  }
    " @success="() => {
    failComment = ''
    failedEdit = false
  }
    ">
    <template #content>
      <p v-if="failComment">{{ failComment }}</p>
      <p v-else-if="isDepositExceed" class="text-error">Стоимость заказа превышает депозит или свободный остаток</p>
      <p v-else>Попробуйте повторить попытку позже или свяжитесь со службой поддержки</p>
    </template>
  </UModal>

  <UModal :show="acceptanceCertificateExists" title="Извините, что-то пошло не так!" cancelLabel="" @cancel="() => {
    acceptanceCertificateExists = false
  }
    " @success="() => {
    acceptanceCertificateExists = false
  }
    ">
    <template #content>
      <p>Для данного списка заказов уже созданы акты</p>
    </template>
  </UModal>

  <ConfirmSignModal act :show="showConfirmSignModal" @closeModal="showConfirmSignModal = false"
    @successModal="confirmSign" />
  <ConfirmSignModal act :show="showConfirmSignModalChange" @closeModal="showConfirmSignModalChange = false"
    @successModal="confirmSignChange" />

  <template v-if="isEditableSuborder">
    <ChangeSuborderModal :tax="order?.withholding_tax" :limit="order?.withholding_tax ? 250000 : 600000" :show="true"
      :suborder="editableSuborder" @closeModal="isEditableSuborder = false" @successModal="changeSuborder" />
  </template>
</template>
<style lang="postcss" scoped>
:deep(table) {
  @apply !border-separate border-spacing-x-0 border-spacing-y-9;
}

:deep(table td) {
  @apply pl-5;
}

:deep(table td:first-child) {
  @apply pl-3;
}

.back-to-work :deep(.modal),
.reason-of-change :deep(.modal) {
  @apply !w-[500px];
}

.task-cancelled {
  @apply text-cancelled;

  span,
  td {
    @apply text-cancelled;
  }

  :deep(.tooltip-icon) img {
    @apply opacity-25;
  }
}

.edit-menu {
  @apply top-[23%] right-[2.5%] w-36 translate-x-0;

  & button {
    @apply min-w-max;
  }

  button:disabled:hover {
    @apply text-cancelled cursor-default;
  }
}

.status-item {
  @apply text-white whitespace-nowrap font-normal leading-5 rounded text-sm py-1 px-[6px] inline-block border border-current min-w-full w-fit text-center min-h-[27px];

  word-break: keep-all;
}

.order-wrap {
  @apply flex -top-8 max-h-[42px] overflow-y-auto left-0 px-3 py-2 bg-white w-full justify-between absolute text-xs1 leading-4;

  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-track {
    @apply bg-white;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-accent;
    @apply rounded-full;
  }

  &::-webkit-scrollbar-thumb:hover {
    @apply bg-accent/30;
  }
}

.order-info {
  @apply text-sm;
  word-break: break-word;
}

.input-title {
  @apply not-italic font-normal text-xs leading-5 text-grey1 left-2 whitespace-nowrap z-[99];
}

:deep(.tooltip-icon) {
  @apply !-right-[21px] !top-0;
}

.tooltip-icon {
  @apply inline-block text-center hover:cursor-pointer;

  &:after {
    @apply text-xs1 leading-4 opacity-0 p-2 invisible absolute top-0 left-2/4 whitespace-normal text-error min-w-[250px] z-10 rounded border border-error;
    content: attr(data-tooltip);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform: translate(-50%, -5px);
  }

  & img {
    @apply min-w-[32px] min-h-[32px];
  }
}

.tooltip-notification7.tooltip-icon,
.tooltip-notification8.tooltip-icon {
  @apply !right-0;
}

.tooltip-notification7.tooltip-icon:hover:after,
.tooltip-notification8.tooltip-icon:hover:after {
  @apply text-black border-0 font-normal leading-5 text-sm p-3 w-fit left-8 top-12 shadow-main;
}

.tooltip-notification7.tooltip-icon:hover:after,
.tooltip-notification8.tooltip-icon:hover:after {
  @apply block opacity-100 !z-[300] bg-white visible;
}

:deep(.documents-link) {
  @apply !text-black;

  &:hover {
    @apply cursor-not-allowed;
  }
}

:deep(.document) {
  &__not-signed {
    .documents-link {
      @apply text-black;

      &:hover {
        @apply cursor-pointer underline;
      }
    }
  }

  &__signed {
    .documents-link {
      @apply text-success;

      &:hover {
        @apply cursor-pointer underline;
      }
    }
  }
}

.document-cancelled {
  @apply !text-cancelled;

  span,
  td,
  div,
  a {
    @apply !text-cancelled;
  }

  :deep(.documents-link) {
    @apply !text-cancelled;
  }

  :deep(.copy-link-btn) {
    @apply hidden;
  }
}
</style>
