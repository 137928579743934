export default [
  {
    path: '',
    name: 'staff.inn',
    component: () => import('@/views/Registration/Staffer/Stages/ProfileInn.vue')
  },
  {
    path: 'citizen',
    name: 'staff.citizen',
    component: () => import('@/views/Registration/Staffer/Stages/ProfileCitizen.vue')
  },
  {
    path: 'info',
    name: 'staff.info',
    component: () => import('@/views/Registration/Staffer/Stages/ProfileInfo.vue')
  },
  {
    path: 'passport',
    name: 'staff.passport',
    component: () => import('@/views/Registration/Staffer/Stages/ProfilePassport.vue')
  },
  {
    path: 'photos',
    name: 'staff.photos',
    component: () => import('@/views/Registration/Staffer/Stages/ProfilePhotos.vue')
  },
  {
    path: 'selfie',
    name: 'staff.selfie',
    component: () => import('@/views/Registration/Staffer/Stages/ProfileSelfie.vue')
  },
  {
    path: 'check',
    name: 'staff.check',
    component: () => import('@/views/Registration/Staffer/Stages/ProfileCheck.vue')
  },
  {
    path: 'ready',
    name: 'staff.ready',
    component: () => import('@/views/Registration/Staffer/Stages/ProfileReady.vue')
  },
  {
    path: 'verify',
    name: 'staff.notready',
    component: () => import('@/views/Registration/Staffer/Stages/UnVerified.vue')
  }
]
