import COUNTRIES from '@/utils/Countries'
import { SERVER_COUNTRIES } from '@/utils/consts'

export function formatDate(date: any) {
  if (date) {
    const dateArr = date.split('-')
    return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`
  }
  return ''
}

export function formatDateToServerView(date: any) {
  if (date) {
    const dateArr = date.split('.')
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
  }
  return ''
}

export const genderOptions = [
  {
    id: 'Муж',
    name: 'Мужской'
  },
  {
    id: 'Жен',
    name: 'Женский'
  }
]

export const nationalities = [
  {
    id: SERVER_COUNTRIES.RUSSIA,
    name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
  },
  {
    id: SERVER_COUNTRIES.BELARUS,
    name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
  },
  {
    id: SERVER_COUNTRIES.ARMENIA,
    name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
  },
  {
    id: SERVER_COUNTRIES.KAZAKHSTAN,
    name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
  },
  {
    id: SERVER_COUNTRIES.KYRGYZSTAN,
    name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
  },
  {
    id: SERVER_COUNTRIES.UKRAINE,
    name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
  }
]
