<template>
  <div class="flex items-center" :class="getDocumentStatus(status)">
    <div class="flex flex-col items-end min-w-max">
      <div class="flex relative">
        <img
          v-if="document?.certificate"
          :class="{ grayscale: !document?.certificate && document?.file }"
          src="/icons/success.svg"
          alt="Подписано"
          class="mr-1 w-4 h-4"
        />
        <img v-else-if="document?.file && !document?.certificate" src="/icons/success.svg" alt="Подписано" class="mr-1 w-4 h-4 grayscale" />
        <div
          v-else-if="!document?.file"
          :class="{ 'tooltip-icon': !document?.category || document?.category === 'acceptance_certificate' }"
          class="mr-1 w-4 h-4"
          :data-tooltip="!document?.category || document?.category === 'acceptance_certificate' ? 'Акт не создан' : ''"
        >
          <img src="/icons/mistake_gray.svg" alt="Не подписано" />
        </div>

        <div>
          <a
            v-if="document?.file && !document?.certificate"
            class="text-sm font-normal documents-link"
            :href="document?.file"
            target="_blank"
            >{{ title }}</a
          >
          <a
            v-else-if="document?.status && !showNotSignLabel && document?.certificate"
            class="text-sm font-normal documents-link"
            :href="getCertificateLink(document)"
            target="_blank"
            >{{ title }}</a
          >
          <p v-else-if="!document?.file" class="text-sm font-normal cursor-not-allowed documents-link">{{ title }}</p>

          <p v-if="document?.id" class="whitespace-nowrap text-right leading-4 text-xs1">№{{ document.id }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { API } from '@/utils/API'
import { defineComponent } from 'vue'
import { EDocsStatus } from '@/types/api-values'

export default defineComponent({
  props: {
    title: String,
    document: Object,
    orderStatus: String,
    status: String
  },
  data() {
    return {
      processing: false
    }
  },
  methods: {
    EDocsStatus() {
      return EDocsStatus
    },
    getCertificateLink(document: any) {
      const file = document.certificate
      if (file) {
        const url = this.getDownloadLink(file)
        if (url) {
          return url
        }
      }
      return document?.deeplink
    },
    checkStatus(status: any) {
      const _status = status?.toLowerCase() || ''
      return _status.toLowerCase() === EDocsStatus.signed
    },
    getDocumentStatus(status: any) {
      if (status) {
        switch (status.toLowerCase()) {
          case EDocsStatus.not_signed:
            return 'document__not-signed'
          case EDocsStatus.exists:
          case EDocsStatus.active:
          case EDocsStatus.signed:
            return 'document__signed'
          default:
            return 'document__empty'
        }
      }
      return 'document__empty'
    },
    getDownloadLink(file: string) {
      if (file) {
        return API.GET_FILE(file)
      }
      return ''
    }
  },
  computed: {
    showNotSignLabel() {
      const status = this.document && this.document.status && this.document.status.toLowerCase()
      return status === EDocsStatus.not_signed
    },
    showCertificate() {
      const status = this.status && this.status.toLowerCase()
      return this.orderStatus !== 'finished' || status === EDocsStatus.signed
    }
  }
})
</script>

<style lang="postcss" scoped>
.create-doc-btn {
  @apply bg-accent rounded text-white h-8 leading-8 px-3.5 ml-2.5 hover:bg-accent/50;
}

.tooltip-icon {
  @apply inline-block text-center hover:cursor-pointer;

  &:after {
    @apply text-xs1 leading-4 opacity-0 p-2 invisible absolute top-0 left-2/4 whitespace-normal text-error min-w-[250px] z-10 rounded border border-error;
    content: attr(data-tooltip);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    transform: translate(-50%, -5px);
  }
  & img {
    @apply min-w-[16px] min-h-[16px];
  }
}

.tooltip-icon:hover:after {
  @apply text-black border-0 font-normal leading-5 text-sm p-3 w-fit left-8 top-12 shadow-main;
}

.tooltip-icon:hover:after {
  @apply block opacity-100 !z-[300] bg-white visible;
}
</style>
