<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { UButton, UModal, UFileDrop, UUploadProgress } from 'unit-uikit'

import axios from 'axios'
import { API } from '@/utils/API'
import jsonToFormData from '@/utils/jsonToForm'

const fileName = ref('')

const uploadPercentage = ref(0)
const onUploadProgress = (pE: ProgressEvent) => {
  const percent = Math.round((pE.loaded / pE.total) * 100) + ''
  uploadPercentage.value = parseInt(percent)
}

const emits = defineEmits(['close', 'success'])

const addFile = (file: File) => {
  fileName.value = file.name

  if (file) {
    const formData = jsonToFormData({
      procuration: file
    })
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    axios
      .post(API.GET_COMPANY_PROCURATIONS, formData, {
        headers,
        onUploadProgress
      })
      .then(() => {
        uploadPercentage.value = 100
        // file uploaded
        emits('success')
      })
      .catch((e) => {
        uploadPercentage.value = 0
        // uploading failed
        console.error(e)
      })
  }
}

defineProps({
  showModal: {
    type: Boolean,
    default: false
  },
  procurationActive: { type: Boolean },
  procurationOnCheck: { type: Boolean },
  procurationExpiring: { type: Boolean },
  procurationEnded: { type: Boolean },
  procurationDaysLeft: { type: [Number, String] },
  startDateNewProcuration: { type: String }
})

const closeUploadModal = () => {
  fileName.value = ''
  uploadPercentage.value = 0
  emits('close')
}

const route = useRoute()

const showLink = computed(() => {
  const name = route.name
  return name !== 'company.procuration'
})
</script>

<template>
  <UModal
    :show="showModal"
    :title="procurationEnded && procurationOnCheck ? 'Внимание' : 'Загрузите доверенность!'"
    @cancel="closeUploadModal"
    @success="closeUploadModal"
  >
    <template #content>
      <template v-if="showLink">
        <p v-if="procurationExpiring && !procurationEnded && procurationActive && procurationDaysLeft !== 0" class="font-medium mb-1">
          {{ `Доверенность на Представителя компании истекает через ${procurationDaysLeft} дней.` }}
        </p>
        <p v-if="procurationExpiring && !procurationEnded && procurationActive && procurationDaysLeft === 0" class="font-medium mb-1">
          Доверенность на Представителя компании истекает сегодня.
        </p>
        <p
          v-else-if="(!procurationActive && !procurationOnCheck) || (!procurationActive && procurationEnded && procurationOnCheck)"
          class="font-medium mb-1"
        >
          Доверенность на Представителя компании истекла.
        </p>
        <p class="mb-8" v-if="!procurationActive && procurationEnded && procurationOnCheck">
          Новая доверенность загружена. Дата начала срока действия <span class="font-medium"> {{ startDateNewProcuration }}</span
          >. До этого функционал личного кабинета Заказчика ограничен.
        </p>
        <p v-if="procurationActive && procurationExpiring && !procurationEnded" class="mb-4">
          При истечении срока действия доверенности функционал подписания документов и проведения платежей будет ограничен.
        </p>
        <p
          v-if="
            (procurationActive && procurationExpiring) ||
            (!procurationActive && !procurationOnCheck) ||
            (!procurationActive && procurationOnCheck)
          "
          class="mb-5"
        >
          Загрузите новую доверенность или свяжитесь со
          <a class="underline text-accent hover:opacity-80 hover:no-underline" href="">службой поддержки</a>
        </p>
      </template>
      <UFileDrop
        v-if="
          (!procurationOnCheck && !procurationEnded) ||
          (procurationActive && procurationExpiring) ||
          (!procurationActive && procurationOnCheck) ||
          (procurationEnded && !procurationOnCheck)
        "
        :disabled="uploadPercentage === 100"
        @add-file="addFile"
      />
      <UUploadProgress v-if="fileName" :label="fileName" :percent="uploadPercentage" />
      <p v-if="uploadPercentage === 100" class="not-italic font-normal text-xs1 text-success">
        Файл успешно загружен, время проверки составляет ~24 часа.
      </p>
      <p>
        Вы даете
        <a class="underline" href="https://disk.yandex.ru/i/DqJ8BPtjIlKq4w" target="_blank"> согласие </a>
        на обработку персональных данных для представителя заказчика
      </p>
    </template>
    <template #buttons>
      <UButton
        :class="{ '!w-full': procurationOnCheck }"
        class="mx-auto"
        label="Продолжить работу"
        v-show="fileName || (procurationOnCheck && procurationEnded)"
        :size="procurationOnCheck ? 'lg' : 'sm'"
        @click="closeUploadModal"
      />
    </template>
  </UModal>
</template>
