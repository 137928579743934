import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios, { InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { useAuthStore } from '@/stores/auth'
import { useCompanyStore } from '@/stores/company'
import { vTooltip } from 'unit-uikit'
// CSS IMPORTS
import 'unit-uikit/dist/style.css'
import './assets/styles/style.css'
import './assets/styles/main.postcss'
import './assets/styles/page-tabs.postcss'

// ASSETS IMPORTS
import * as Sentry from '@sentry/vue'

const app = createApp(App)
const VUE_APP_SENTRY = process.env.VUE_APP_SENTRY
const dsn = 'https://bb8351ed105e4fc7a9922b70a8eeffd3@sentry.theunit.ru/3'

Sentry.init({
  app,
  environment: VUE_APP_SENTRY,
  dsn,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app.config.globalProperties.axios = axios
app.use(createPinia()).use(router).directive('tooltip', vTooltip).mount('#app')

const _requestHandler = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const authStore = useAuthStore()

  if (config.headers) {
    config.headers.Authorization = authStore.authHeader
  } else {
    config.headers = <AxiosRequestHeaders>{
      Authorization: authStore.authHeader
    }
  }
  return config
}

axios.interceptors.request.use(
  async (request) => {
    const _request = await _requestHandler(request)
    return _request
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const code = error?.response?.data?.code
    if (code === 'token_not_valid') {
      const authStore = useAuthStore()
      authStore.refreshToken()
    }

    const statusCode = error?.response?.status
    const copmanyStore = useCompanyStore()
    if (statusCode === 403) {
      copmanyStore.accessDenied = true
      copmanyStore.accessDeniedMsg = error?.response?.data?.non_field_errors
    } else {
      copmanyStore.accessDenied = false
      copmanyStore.accessDeniedMsg = ''
    }
    return Promise.reject(error)
  }
)
