import axios from 'axios'

const addressURL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
const innURL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party'
const bankURL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank'
const subdivisionURL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit'
const token = '95ebb2c344529fd891f687eba609b43fb23bde7e'

// Меняем дефолтный заголовок. Если не создать instance, то не передается токен при запросе в дадату
const instance = axios.create({
  headers: { Authorization: 'Token ' + token }
})

// адреса
export function getSuggestions(query: string, countryCode = 'RU', callback: any) {
  instance
    .post(addressURL, { query, locations: { country_iso_code: countryCode } })
    .then((response) => {
      callback(response)
    })
    .catch((error) => console.error(error))
}

// компания по ИНН
export function getSuggestionsByInn(query: string, callback: any) {
  instance
    .post(innURL, { query })
    .then((response) => {
      callback(response)
    })
    .catch((error) => console.error(error))
}

// банк по БИК
export function getBankByBic(query: string, callback: any) {
  instance
    .post(bankURL, { query })
    .then((response) => {
      callback(response)
    })
    .catch((error) => console.error(error))
}

// по коду подразделения
export function getSuggestionsBySubdivision(query: string, callback: any) {
  instance
    .post(subdivisionURL, { query })
    .then((response) => {
      callback(response)
    })
    .catch((error) => console.error(error))
}
