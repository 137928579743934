<template>
  <Loader v-if="loading" />
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>
  <div class="flex flex-wrap 2xl:flex-nowrap mb-2 justify-between">
    <UInput class="invisible" searchInput placeholder="Быстрый поиск" />
    <router-link :to="`/omvd/trustedpersons/add`">
      <UButton size="sm" label="Создать" class="px-2 w-auto" />
    </router-link>
  </div>

  <EmptyPagePlaceholder pageType="omvd" title="Создать уведомления" v-if="!trustedPersons.length && !loading" />
  <UTable v-else colspan="5" :callback="loadTrustedPersons">
    <template #thead>
      <tr>
        <th>ФИО</th>
        <th>Должность</th>
        <th>Номер доверенности</th>
        <th>Дата доверенности</th>
        <th></th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="trustedPerson in trustedPersons" :key="trustedPerson.id">
        <td>{{ `${trustedPerson.last_name} ${trustedPerson.first_name} ${trustedPerson.middle_name}` }}</td>
        <td>{{ trustedPerson.position }}</td>
        <td>{{ trustedPerson.procuration_number }}</td>
        <td>
          {{
            `${trustedPerson.procuration_release_date.split('-')[2]}.${trustedPerson.procuration_release_date.split('-')[1]}.${
              trustedPerson.procuration_release_date.split('-')[0]
            } — ${trustedPerson.procuration_exp_date.split('-')[2]}.${trustedPerson.procuration_exp_date.split('-')[1]}.${
              trustedPerson.procuration_exp_date.split('-')[0]
            }`
          }}
        </td>
        <td>
          <button type="button" class="edit-btn relative" @click="openEditMenu(trustedPerson.id)">
            <img class="" src="/icons/extra.svg" alt="Редактировать" />
            <div v-if="trustedPerson.showEditMenu" class="edit-menu">
              <router-link :to="`/omvd/trustedpersons/edit/${trustedPerson.id}`">
                <button class="btn-transparent">Редактировать</button>
              </router-link>
              <button @click="showModalDeleteTrustedPerson(trustedPerson.id)" class="btn-transparent">Удалить</button>
            </div>
          </button>
        </td>
      </tr>
    </template>
  </UTable>

  <UModal
    :show="showModal"
    title="Удалить доверенное лицо?"
    successLabel="Удалить"
    @cancel="showModal = false"
    @success="deleteTrustedPerson(trustedPersonIdToDelete)"
  />

  <UModal :show="showSuccessModal" title="Успешно!" successLabel="Удалить" @cancel="afterDeleteTrustedPerson()">
    <template #content>
      <span>Доверенное лицо успешно удалено. Вы можете продолжить дальнейшую работу.</span>
    </template>
    <template #buttons>
      <UButton label="Продолжить" @click="afterDeleteTrustedPerson()" />
    </template>
  </UModal>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { API } from '@/utils/API'
import axios from 'axios'
import Loader from '@/components/Loader/Loader.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import { UTable, UInput, UButton, UModal } from 'unit-uikit'

export default defineComponent({
  components: {
    UTable,
    Loader,
    UInput,
    UButton,
    UModal,
    EmptyPagePlaceholder
  },
  emits: ['updateCounter'],
  data() {
    return {
      loading: false,
      trustedPersons: [] as any,
      selectAll: false,
      showModal: false,
      showSuccessModal: false,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      trustedPersonIdToDelete: '' as any
    }
  },
  mounted() {
    this.loadTrustedPersons()
  },
  methods: {
    async loadTrustedPersons() {
      this.loading = true
      axios
        .get(API.GET_TRUSTED_PERSONS)
        .then((response: any) => {
          this.trustedPersons = response.data
          this.loading = false
        })
        .catch((error: any) => {
          this.loading = false
          console.error(error)
        })
    },
    openEditMenu(id: string | number) {
      this.trustedPersons = this.trustedPersons.map((item: any) => {
        const itemId = item.id
        if (id + '' === itemId + '') {
          item.showEditMenu = !item.showEditMenu
        } else {
          item.showEditMenu = false
        }
        return item
      })
    },
    closeEditMenu() {
      this.trustedPersons = this.trustedPersons.map((item: any) => {
        item.showEditMenu = false
        return item
      })
    },
    showModalDeleteTrustedPerson(id: string | number) {
      this.showModal = true
      this.trustedPersonIdToDelete = id
    },
    deleteTrustedPerson(id: string | number) {
      this.showSuccessModal = true
      axios
        .delete(API.TRUSTED_PERSON(id))
        .then(() => {
          this.showModal = false
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    afterDeleteTrustedPerson() {
      this.showSuccessModal = false
      this.showModal = false
      this.loadTrustedPersons()
    }
  }
})
</script>
<style lang="postcss" scoped>
.edit-menu {
  @apply top-full right-0 translate-x-0 translate-y-0;
  & .btn-transparent {
    @apply min-w-max;
  }
}

:deep(.modal) {
  @apply !gap-2 min-w-[500px];
}

:deep(.modal .-buttons button:last-child) {
  @apply !bg-error;
}

:deep(.modal .-content) {
  @apply mb-8;
}
</style>
