<template>
  <UNotifications :callback="loadNotifications" v-if="showPopup">
    <template #header>
      <div class="notifications__header">
        <h1 class="notifications__title">
          <span class="mr-1">Уведомления</span>
          <span v-if="counter">
            <span class="text-sm text-grey">({{ counter }})</span>
          </span>
          <span v-if="counter" @click="readAll"
            class="ml-auto mr-3 self-center font-medium text-sm text-accent underline hover:no-underline">Прочитать
            все</span>
        </h1>
        <button class="hover:opacity-70" type="button" @click.prevent="$emit('close')">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
              fill="#EAECED" />
            <path
              d="M9.17035 15.5804C8.98035 15.5804 8.79035 15.5104 8.64035 15.3604C8.35035 15.0704 8.35035 14.5904 8.64035 14.3004L14.3004 8.64035C14.5904 8.35035 15.0704 8.35035 15.3604 8.64035C15.6504 8.93035 15.6504 9.41035 15.3604 9.70035L9.70035 15.3604C9.56035 15.5104 9.36035 15.5804 9.17035 15.5804Z"
              fill="#8491A5" />
            <path
              d="M14.8304 15.5804C14.6404 15.5804 14.4504 15.5104 14.3004 15.3604L8.64035 9.70035C8.35035 9.41035 8.35035 8.93035 8.64035 8.64035C8.93035 8.35035 9.41035 8.35035 9.70035 8.64035L15.3604 14.3004C15.6504 14.5904 15.6504 15.0704 15.3604 15.3604C15.2104 15.5104 15.0204 15.5804 14.8304 15.5804Z"
              fill="#8491A5" />
          </svg>
        </button>
      </div>
    </template>
    <template #content>
      <li class="notifications__list-item" v-for="notification in sortedNotifications" :key="notification.id"
        @click.prevent="readNotification(notification.id, notification.viewed)">
        <div class="text-left w-full flex">
          <div v-if="!notification.viewed" class="mr-2 mt-1 min-w-[8px] w-2 h-2 bg-accent rounded-full"></div>
          <div class="text-grey leading-4 text-xs1 mb-4 break-words overflow-auto">
            <span class="font-bold">{{ notification.payload.worker_full_name || ' ' }}</span> {{ notification.body }}
          </div>
        </div>
        <div class="flex justify-between w-full">
          <div class="whitespace-nowrap text-xs1">
            {{ getNotificationDate(notification.created_at) }}
          </div>
          <router-link v-if="notification.payload?.order_id" class="text-xs1 underline text-accent hover:opacity-60"
            :to="`/order/${notification.payload.order_id}/docs`">
            {{ getOrderInfo(notification) || '' }}
          </router-link>
        </div>
        <div class="flex mt-2 self-end">
          <router-link v-if="showPayBtn(notification)" :to="`/finance/order/${notification.payload.order_id}`">
            <UButton class="!h-8 !text-xs1" label="Оплатить" size="sm" />
          </router-link>
        </div>
      </li>
      <li v-if="!notifications.length && !loading">Нет уведомлений</li>
      <li class="flex justify-center mt-3" v-else-if="loading">
        <img class="animate-pulse" src="/icons/status_wait.svg" alt="wait icon" />
      </li>
    </template>
  </UNotifications>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import dayjs from 'dayjs'
const relativeTime = require('dayjs/plugin/relativeTime')
require('dayjs/locale/ru');
dayjs.extend(relativeTime)
dayjs.locale('ru')
import { UButton, UNotifications } from 'unit-uikit'
import { EOrderStatus, ESuborderStatus } from '@/types/api-values'

export default defineComponent({
  name: 'Notifications',
  components: {
    UNotifications,
    UButton
  },
  props: ['showPopup'],
  data() {
    return {
      loading: false,
      notifications: [] as any,
      counter: 0,
      notificationsNextPage: '',
      notificationsViewedNextPage: '',
      scrollData: {
        count: 0,
        size: 10,
        page: 1
      }
    }
  },
  created() {
    this.getNotificationsList()
  },
  methods: {
    readAll() {
      axios
        .post(API.READ_ALL_NOTIFICATIONS(), {})
        .then(() => {
          this.notifications = []
          this.getNotificationsList()
          this.$emit('change-counter')
        })
        .catch((e) => {
          console.error(e)
        })
    },
    showPayBtn(notification: any) {
      const correctType = notification?.type === 'suborder_status_awaiting_payments'
      const status = notification?.payload?.order_status
      const suborderStatus = notification?.payload?.suborder_status
      const correctStatus = status !== EOrderStatus.finished && status !== EOrderStatus.cancelled
      return correctType && correctStatus && suborderStatus !== ESuborderStatus.finished
    },
    async loadNotifications() {
      if (this.notifications.length < this.scrollData.count) {
        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}`

        if (this.notificationsNextPage) {
          const response = await axios.get(API.NOTIFICATIONS + queryString)

          const data = response.data
          if (data.results && data.count) {
            this.scrollData.count = data.count || 0
            const result: any = data.results
            this.notifications.push(...result)
          }
        }
        if (this.notificationsViewedNextPage) {
          const response = await axios.get(API.VIEWED_NOTIFICATIONS + queryString)

          const data = response.data
          if (data.results && data.count) {
            this.scrollData.count = data.count || 0
            const result: any = data.results
            this.notifications.push(...result)
          }
        }
      }
    },
    getNotificationsList() {
      this.loading = true
      axios
        .get(API.NOTIFICATIONS + `?page_size=${this.scrollData.size}`)
        .then((res) => {
          this.scrollData.count += res.data.count || 0
          this.notificationsNextPage = res.data.next || ''
          this.counter = res.data.count
          this.notifications.push(...res.data.results)
          this.loading = false
        })
        .catch((e) => {
          console.error(e)
          this.loading = false
        })
      axios
        .get(API.VIEWED_NOTIFICATIONS + `?page_size=${this.scrollData.size}`)
        .then((res) => {
          this.scrollData.count += res.data.count || 0
          this.notificationsViewedNextPage = res.data.next || ''
          this.notifications.push(...res.data.results)
          this.loading = false
        })
        .catch((e) => {
          console.error(e)
          this.loading = false
        })
    },
    getNotificationDate(date: any) {
      // @ts-ignore
      return `${dayjs(date).locale('ru').fromNow()} в ${dayjs(date).format('hh:mm:ss')}`
    },
    readNotification(id: string | number, isViewed: boolean) {
      if (!isViewed) {
        axios
          .patch(API.READ_NOTIFICATION(id), { viewed: true })
          .then(() => {
            this.notifications = []
            this.getNotificationsList()
            this.$emit('change-counter')
          })
          .catch((e) => {
            console.error(e)
          })
      }
    },
    getOrderInfo(notification: any) {
      if (notification.payload.suborder_id) {
        return `Заказ №${notification.payload.suborder_id} `
      } else {
        return ''
      }
    }
  },
  computed: {
    sortedNotifications() {
      let notificationsSortedByDate = this.notifications.sort((a: any, b: any) => dayjs(b.last_updated).diff(dayjs(a.last_updated)))
      let notificationsSortedByDateAndViwedStatus = notificationsSortedByDate.sort((a: any, b: any) => Number(a.viewed) - Number(b.viewed))
      return notificationsSortedByDateAndViwedStatus
    }
  }
})
</script>
