import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import ProjectsService from '@/services/projects.service'

export const useProjectsStore = defineStore('projects', () => {
  const projects = ref([])

  const projectOptions = computed(() => {
    return [
      {
        id: 'allProjects',
        name: 'Показать все'
      },
      ...projects.value
    ]
  })

  async function fetchProjects(force = false, queryString = '') {
    try {
      if (projects.value?.length && !force) {
        return projects.value
      }
      const results = await ProjectsService.getProjects(queryString)
      projects.value = results || []
      return results
    } catch (error) {
      return 'error'
    }
  }

  function $reset() {
    projects.value = []
  }

  return {
    projectOptions,
    fetchProjects,
    projects,
    $reset
  }
})
