import { ref, Ref } from 'vue'
import { ETransactionsStatus } from '@/types/api-values'
import axios from 'axios'
import { API } from '@/utils/API'
import { useCompanyStore } from '@/stores/company'

export function useLongPulling() {
  const ids = ref([]) as Ref<string[]>

  const addToPulling = (id: string, items: any) => {
    if (!ids.value.includes(id)) {
      ids.value.push(id)
      longPullingCheck(id, items)
    }
  }

  const removeFromPulling = (id: string) => {
    ids.value = ids.value.filter(i => i !== id)
  }

  const updateStatus = (item: any, status: ETransactionsStatus, check?: string) => {
    if (item && status) {
      item.status = status
    }
    if (item && check) {
      item.check_image = check
    }
  }

  const longPullingCheck = (id: string | number, items?: any) => {
    setTimeout(() => {
      axios.get(API.GET_BILL_INFO(id))
        .then((res: { data: any }) => {
          const { data } = res
          const status = data?.status
          const check = data?.check_image

          if ((status === ETransactionsStatus.processing || status === ETransactionsStatus.error || status === ETransactionsStatus.sent) && ids.value.includes(id + '')) {
            longPullingCheck(id, items)
            if (items?.value && items.value.length) {
              const item = items.value.find((i: any) => i.id + '' === id + '')
              updateStatus(item, status)
            }
          } else {
            if (items?.value && items.value.length) {
              const item = items.value.find((i: any) => i.id + '' === id + '')
              updateStatus(item, status, check)
            }
            removeFromPulling(id + '')
            useCompanyStore().fetcCompanyBalance()
          }
        })
        .catch(() => {
          removeFromPulling(id + '')
        })
    }, 6000)
  }

  const fetchBillsInfo = (ids: string[], items: any) => {
    ids.forEach(id => {
      axios.get(API.GET_BILL_INFO(id))
        .then((res: { data: any }) => {
          const { data } = res
          const status = data?.status
          const check = data?.check_image

          if (items?.value && items.value.length) {
            const item = items.value.find((i: any) => i.id + '' === id + '')
            if (item) {
              updateStatus(item, status, check)
            } else {
              items.value.unshift(data)
            }
          }
          if (status === ETransactionsStatus.processing || status === ETransactionsStatus.error || status === ETransactionsStatus.sent) {
            addToPulling(id + '', items)
          }
          if (status === ETransactionsStatus.paid) {
            useCompanyStore().fetcCompanyBalance()
          }
        })
        .catch(() => {
          useCompanyStore().fetcCompanyBalance()
        })
    })
  }

  const clear = () => {
    ids.value = []
  }

  return { addToPulling, removeFromPulling, fetchBillsInfo, clear }
}
