<template>
  <div class="form space-y-6">
    <h2 class="font-medium text-2xl">Основная информация</h2>
    <div class="flex gap-3">
      <div class="flex-grow">
        <UInput class="!max-w-full !w-full" :value="project" v-model="project" label="Проект" :disabled="true" />
      </div>
      <div class="flex-grow">
        <UInput class="!max-w-full !w-full" :value="object" v-model="object" label="Объект" :disabled="true" />
      </div>
      <div class="flex-grow">
        <UInput class="!max-w-full !w-full"
          :value="`${getFormatedDate(order?.start_date)}&mdash;${getFormatedDate(order?.finish_date)}`"
          label="Период работ" :disabled="true" />
      </div>
    </div>
    <UInput class="!max-w-full !w-full" :title="order?.name" :value="order?.name" label="Название заказа"
      :disabled="true" />
    <UInput class="!max-w-full !w-full" :title="order?.description" :value="order?.description"
      label="Описание услуг и работ" :disabled="true" />
    <div class="flex flex-wrap gap-3">
      <UInput class="!basis-1/4 flex-shrink flex-grow" :value="order?.address" label="Адрес выполнения услуг"
        :disabled="true" />
      <UInput class="!basis-1/4 flex-shrink flex-grow" :value="order?.special_requirements"
        label="Специализированные требования к качеству работ" :disabled="true" />
      <UInput class="!max-w-full !w-full" :value="`${order?.equipment.map((i: any) => i.name).join(', ')}`"
        label="Применяемая техника, материалы, требования к допуску Самозанятого к технике" :disabled="true" />
    </div>
    <div class="flex flex-wrap lg:flex-nowrap gap-3">
      <UInput class="!basis-1/5 !max-w-full flex-grow" :value="order?.quantity" label="Объем работ" :disabled="true" />
      <UInput class="!basis-1/5 !max-w-full flex-grow" :value="getUnitType(order?.unit_type)" label="Тип единицы"
        :disabled="true" />
      <UInput class="!basis-1/5 !max-w-full flex-grow" :value="order?.photo_after_completion ? 'Да' : 'Нет'"
        label="Фото по завершении работ" :disabled="true" />
      <UInput class="!basis-1/5 !max-w-full flex-grow" :disabled="true" :value="order?.withholding_tax ? 'Да' : 'Нет'"
        id="withholding_tax" :label="d['bank-fee-tooltip']" />
    </div>
    <UInput v-if="order?.photo_after_completion && order?.photo_comments" class="!max-w-full !w-full"
      :title="order?.photo_comments" :value="order?.photo_comments" label="Комментарий для исполнителя"
      :disabled="true" />
    <h2 class="font-medium text-2xl">Выбранные документы к заказу</h2>
    <div class="flex flex-wrap lg:flex-nowrap gap-3">
      <div class="flex-grow !max-w-none">
        <UISelect v-if="order" id="order_agreement_template" label="Шаблон рамочного договора"
          unselectedLabel="Выберите шаблон" v-model="order.agreement_template" :value="order.agreement_template || ''"
          :options="templates.agreement_template" :disabled="true" />
        <a target="_blank" :href="getTemplateFile(order?.agreement_template, templates.agreement_template)">Посмотреть
          шаблон</a>
      </div>

      <div class="flex-grow !max-w-none">
        <UISelect v-if="order" id="order_template_selector" label="Шаблон договора задания"
          unselectedLabel="Выберите шаблон" v-model="order.order_template" :value="order.order_template || ''"
          :options="templates.order_template" :disabled="true" />
        <a target="_blank" :href="getTemplateFile(order?.order_template, templates.order_template)">Посмотреть шаблон</a>
      </div>

      <div class="flex-grow !max-w-none">
        <UISelect v-if="order" id="order_acceptance_certificate_template" label="Шаблон акта"
          unselectedLabel="Выберите шаблон" v-model="order.acceptance_certificate_template"
          :value="order.acceptance_certificate_template || ''" :options="templates.acceptance_certificate_template"
          :disabled="true" />
        <a target="_blank"
          :href="getTemplateFile(order?.acceptance_certificate_template, templates.acceptance_certificate_template)">Посмотреть
          шаблон</a>
      </div>
    </div>
    <h2 class="font-medium text-2xl mb-4">Бюджет заказа</h2>
    <div class="budget-form !flex-col lg:!flex-row ">
      <div class="min-w-fit">
        <div class="relative mb-3">
          <UInput v-if="budget" class="!max-w-none !w-auto" :value="budget.workersAmount"
            placeholder="Кол-во исполнителей" label="Количество исполнителей" autocomplete="off" mask="positiveNumber"
            :disabled="true" />
        </div>
        <div class="relative mb-3">
          <UInput v-if="budget" class="!max-w-none !w-auto" :value="formatPrice(budget.allWorkersSum)"
            placeholder="0,00 ₽" label='Всем исполнителям "на руки"' mask="valueCurrency" :showCurrency="true"
            :disabled="true" />
        </div>
        <div class="flex gap-3 w-full max-w-full">
          <div class="relative mb-3 basis-1/2">
            <UInput v-if="budget" class="!max-w-none !w-auto" :value="formatPrice(budget.oneWorkerSum)"
              placeholder="0,00 ₽" label='Исполнителю "на руки"' mask="valueCurrency" :showCurrency="true"
              :disabled="true" />
          </div>
          <div class="relative mb-3 basis-1/2">
            <UInput class="!max-w-none !w-auto" :value="formatPrice(workerWithTax)" placeholder="0,00 ₽"
              label="Исполнителю с налогом" mask="valueCurrency" :showCurrency="true" :disabled="true"
              tooltip="Конечная сумма, которую необходимо заплатить исполнителю. Отображается в чеке" />
          </div>
        </div>
        <div class="relative">
          <UInput id="orderBudgetPlusTaxes" class="!max-w-none !w-auto" :value="formatPrice(budgetPlusTaxes)"
            v-model="budgetPlusTaxes" placeholder="0,00 ₽" label="Бюджет заказа включая налог и %" mask="valueCurrency"
            :showCurrency="true" :disabled="true" tooltip="Необходимо пополнить р/с компании на данную сумму" />
        </div>
      </div>
      <div class="budget-form__order-details">
        <div class="budget-form__order-details--title">Детали бюджета</div>
        <ul class="budget-form__order-details--list flex">
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">Налог самозанятого 6%</h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(workerTax) }}&nbsp;₽</p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">
              Комиссия платформы {{ formatPercentage(companyInfo.unitpay_commission) }}%
            </h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(platformComission) }}&nbsp;₽</p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">Итого на исполнителя (налог + %)</h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(outcomeTax) }}&nbsp;₽</p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">Всего налогов самозанятых 6%</h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(allWorkersTaxes) }}&nbsp;₽</p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">
              Всего комиссия платформы {{ formatPercentage(companyInfo.unitpay_commission) }}%
            </h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(finalPlatformCommision) }}&nbsp;₽</p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">Итого налог + % на всех</h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(fullOutcomeTax) }}&nbsp;₽</p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">Бюджет объекта</h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(companyInfo.object_budget) }}&nbsp;₽</p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">Резерв бюджета объекта</h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(companyInfo.object_reserve) }}&nbsp;₽
            </p>
          </li>
          <li class="budget-form__order-details--list__item">
            <h6 class="budget-form__order-details--list__item--label">Свободный остаток бюджета объекта</h6>
            <p class="budget-form__order-details--list__item--sum">{{ formatPrice(freeBalance) }}&nbsp;₽</p>
          </li>
        </ul>
      </div>
    </div>
    <h2 class="font-medium text-2xl">Распределение заказов по исполнителям для заказа</h2>
    <UTable class="!overflow-visible" v-if="order?.suborder.length">
      <template #thead>
        <tr>
          <th>Исполнители ({{ order.suborder.length }})</th>
          <th>Название заказа</th>
          <th>Период</th>
          <th class="whitespace-nowrap">Объем работ</th>
          <th>Тип единиц</th>
          <th>Бюджет</th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="worker in order?.suborder" :key="worker.id">
          <td>
            <div class="worker-description whitespace-nowrap">
              <h3>{{ getWorkerName(worker.last_name, worker.first_name, worker.middle_name) }}</h3>
              <p class="text-grey text-xs1 mt-1">Статус самозанятого</p>
            </div>
          </td>
          <td class="min-w-[200px] max-w-[300px]">{{ worker.name }}</td>
          <td class="whitespace-nowrap">{{ worker.start_date }} - {{ worker.finish_date }}</td>
          <td class="whitespace-nowrap">{{ worker.quantity }}</td>
          <td class="whitespace-nowrap">{{ getUnitType(worker.unit_types) }}</td>
          <td class="whitespace-nowrap">{{ formatPrice(worker.price) }}&nbsp;₽</td>
        </tr>
      </template>
    </UTable>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import { APIAddWorkersBudget, APIAddWorkersOrder } from '@/interfaces/main'
import { formatPrice, formatDate } from '@/utils/helpers'
import { UTable, UISelect, UInput } from 'unit-uikit'
import d from '@/dictionary'

export default defineComponent({
  components: {
    UTable,
    UISelect,
    UInput
  },
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    budget: {
      type: Object as PropType<APIAddWorkersBudget>,
      required: true
    }
  },
  data() {
    return {
      project: '',
      object: '',
      companyInfo: {} as any,
      oneWorkerSumWithTax: null,
      unitTypesList: [],
      templates: {
        agreement_template: [],
        order_template: [],
        acceptance_certificate_template: []
      },
      d
    }
  },
  methods: {
    formatPercentage(value: any) {
      if (value) {
        return ((value * 100).toFixed(2) + '').replace('.', ',')
      }
      return '0,00'
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatDate,
    getWorkerName(lastname: string, name: string, middlename: string) {
      return `${lastname} ${name} ${middlename || ''}`
    },
    getFormatedDate(datetime: number | string) {
      const date = new Date(datetime)
      return this.formatDate(date)
    },
    getUnitType(type: number) {
      const result = this.unitTypesList
        .filter((item: any) => {
          return item.id + '' === type + ''
        })
        .map((item: any) => {
          return item.name
        })
      return (result && result[0]) || ''
    },
    getTemplateFile(id: string | number, arrTemplates: [] | any) {
      let templateFile = ''
      arrTemplates.forEach((template: { id: string | number; name: string; file: string }) => {
        if (template.id + '' === id + '') {
          templateFile = template.file
        }
      })
      return templateFile
    }
  },
  computed: {
    budgetPlusTaxes() {
      return this.budget?.budgetPlusTaxes ? (this.budget?.budgetPlusTaxes + '').replaceAll(',', '.') : 0
    },
    workerWithTax() {
      const amount = +this.budget?.oneWorkerSum + +this.workerTax
      const workerWithTax = amount ? amount.toFixed(2) : 0
      return workerWithTax
    },
    outcomeTax(): any {
      // Итого на исполнителя (налог + %)”=(Исполнителю “на руки“)*(1+0,06)*(1+0,0475)
      if (this.budget?.workersAmount && this.budget?.budgetPlusTaxes) {
        const tax = this.budget?.budgetPlusTaxes / this.budget?.workersAmount
        const fullTaxes = tax ? tax.toFixed(2) : 0
        return fullTaxes
      }
      return 0
    },
    fullOutcomeTax() {
      // (Итого налог + % на всех)=(Всего налогов самозанятых 6%)+(Всего комиссия платформы 4,75%)
      return (+this.allWorkersTaxes + +this.finalPlatformCommision).toFixed(2)
    },
    platformComission() {
      // (Комиссия платформы 4,75%) = (Исполнителю “на руки“)*(1+0,06)*0,0475
      if (this.companyInfo && this.outcomeTax) {
        const commision = this.outcomeTax * this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    finalPlatformCommision() {
      // (Всего комиссия платформы 4,75%) = (Всем исполнителям “на руки”)*(1+0,06)*0,0475
      if (this.companyInfo && this.budget?.allWorkersSum) {
        const commision =
          (this.budget.allWorkersSum / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)) *
          this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    workerTax() {
      // (Налог самозанятого 6%) = (Исполнителю “на руки“)*0,06
      if (this.companyInfo && this.budget?.oneWorkerSum) {
        const tax = (this.budget.oneWorkerSum / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return tax ? tax.toFixed(2) : 0
      }
      return 0
    },
    allWorkersTaxes() {
      // (Всего налогов самозанятых 6%) = (Всем исполнителям “на руки”) * 0,06
      if (this.budget?.allWorkersSum) {
        const taxes = (this.budget?.allWorkersSum / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return taxes ? taxes.toFixed(2) : 0
      }
      return 0
    },
    freeBalance() {
      return this.companyInfo ? this.companyInfo.object_budget - this.companyInfo.object_reserve : 0
    }
  },
  created() {
    const objectId: any = this.order?.object
    axios
      .get(API.GET_FINANCIAL_INFO(objectId))
      .then((response: any) => {
        this.companyInfo = response.data
      })
      .catch((e: any) => {
        console.error(e)
      })

    axios.get(API.GET_UNIT_TYPES).then((res: any) => {
      this.unitTypesList = res.data
    })

    const getObjects = axios.get(API.GET_OBJECT)
    const getOrderData = axios.get(API.GET_ORDER_PREPARATION)
    getObjects
      .then((response: any) => {
        const objects = response.data
        const object = objects?.filter((obj: any) => {
          return obj.id + '' === this.order?.object + ''
        })
        this.object = object[0]?.name
        this.project = object[0]?.project?.name
      })
      .catch((error: any) => {
        console.error(error)
      })

    getOrderData
      .then((response: any) => {
        const agreementTemplateArr = response.data?.agreement_templates
        this.templates.agreement_template = agreementTemplateArr

        const orderTemplateArr = response.data?.order_templates
        this.templates.order_template = orderTemplateArr

        const certificateTemplateArr = response.data?.accpetance_certificate_templates
        this.templates.acceptance_certificate_template = certificateTemplateArr
      })
      .catch((error: any) => {
        console.error(error)
      })
  }
})
</script>

<style lang="postcss" scoped>
:deep(table) {
  @apply !table-auto;
}

.budget-form {
  @apply flex gap-3 mb-4 gap-y-4 lg:gap-y-0 w-full !overflow-visible;

  &__order-details {
    @apply rounded-lg bg-white p-4 shadow-main;

    &--list {
      @apply flex-wrap flex items-stretch;

      &__item {
        @apply mb-2 w-1/3 flex flex-col;

        &--label {
          @apply leading-4 not-italic overflow-hidden text-xs1 text-grey;
        }

        &--sum {
          @apply leading-1.1 text-grey mt-auto;
        }
      }
    }

    &--title {
      @apply text-black not-italic font-medium mb-3 text-lg leading-snug;
    }
  }
}
</style>
