<script setup lang="ts">
const closeEditMenu = ($event: any) => {
  const srcElement = $event.srcElement
  const editMenuClicked = srcElement && srcElement.closest('.edit-btn')
  const isFitterElementClicked = srcElement && srcElement.closest('.qs-datepicker-container')

  const isFitterElement =
    srcElement &&
    (srcElement.name === 'endDatefilter' ||
      srcElement.name === 'startDateFilter' ||
      srcElement.name === 'endDate' ||
      srcElement.name === 'startDate' ||
      srcElement.name === 'finishDateFilter')

  if (editMenuClicked || isFitterElement || isFitterElementClicked) {
    return
  }

  const closeElement = document.querySelector('.close-edit-menu') as HTMLElement

  if (closeElement) {
    closeElement.click()
  }
}
</script>

<template>
  <router-view @click="closeEditMenu" />
</template>

<style lang="postcss">
#app {
  @apply antialiased;
}
</style>
