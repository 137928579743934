<script lang="ts" setup>
import { ref, watch } from 'vue'
import { UModal, UInput, UButton } from 'unit-uikit'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  error: {
    type: String,
    default: ''
  },
  timer: {
    type: Number,
    default: 60
  },
  show: {
    type: Boolean,
    default: false
  },
  phone: {
    type: String,
    default: ''
  }
})

const sms = ref('')

const emits = defineEmits(['cancel', 'confirm', 'repeat'])

watch(
  () => props.show,
  () => {
    if (props.show === false) {
      sms.value = ''
    }
  }
)

const cancel = () => {
  emits('cancel')
}
</script>

<template>
  <UModal :show="show" @cancel="cancel" title="Введите код из смс">
    <template #content>
      <div class="text-grey text-sm leading-5">
        <p>Мы отправили вам временный код на номер {{ phone }}.</p>
        <p>Он потребуется для того, чтобы вы смогли подтвердить оплаты исполнителям.</p>
      </div>

      <UInput placeholder="Пароль из СМС" :value="sms" v-model="sms" />
      <p v-if="error" class="text-xs2 text-error">{{ error }}</p>
      <p class="text-xs2 leading-4 text-grey1 mt-2 mb-4">
        Не пришло СМС?
        <span v-if="timer">Отправить повторно через {{ timer }} с.</span>
        <a v-else class="btn-link" @click="emits('repeat')">Отправить повторно смс-код</a>
      </p>
    </template>
    <template #buttons>
      <div class="flex">
        <UButton class="!w-full mr-3" label="Отмена" color="secondary" @click.prevent="cancel" />
        <UButton :disabled="loading || !sms" class="!w-full" label="Продолжить" @click.prevent="emits('confirm', sms)" />
      </div>
    </template>
  </UModal>
</template>
