<template>
  <Loader v-if="loading" />

  <WarningMessage class="mb-3 self-start"
    message="Уведомляем о необходимости отнести приложение №8 в МВД до окончания срока действия договора" />

  <div class="flex flex-wrap gap-2 mb-2">
    <UISelect id="objectSelector" class="!mr-0 !w-full lg:!w-auto" label="Объект" unselectedLabel="Выберите объект"
      v-model="objects.id" :value="objects.id || ''" :options="objectsOptions" />
    <UISelect id="citizenshipSelector" class="!mr-0 !w-full lg:!w-auto 2xl:!max-w-[200px]" label="Гражданство"
      unselectedLabel="Выберите гражданство" v-model="citizenship" :value="citizenship" :options="nationalities" />

    <UInput class="!mr-0 !w-full lg:!w-auto 2xl:!max-w-[200px]" placeholder="Введите фамилию" label="Поиск по фамилии"
      v-model="searchLastName" :value="searchLastName" />
    <UInput class="!mr-0 !w-full lg:!w-auto 2xl:!max-w-[200px]" placeholder="Введите имя" label="Поиск по имени"
      v-model="searchFirstName" :value="searchFirstName" />
    <UInput class="!mr-0 !w-full lg:!w-auto 2xl:!max-w-[200px]" placeholder="Название МВД" label="Поиск по МВД"
      v-model="mvdSearch" :value="mvdSearch" />
    <slot name="downloadBtn"></slot>
  </div>

  <EmptyPagePlaceholder pageType="omvd" title="Создать уведомления" v-if="!notifications.length && !loading" />

  <UTable class="min-h-[300px] lg:min-h-[auto]" v-else colspan="7" :callback="loadDocs">
    <template #thead>
      <tr>
        <th class="w-[40px] p-0 pl-3">
          <UICheckbox v-model="selectAll" id="selectAll" value="false" />
        </th>
        <th class="w-[180px]">Исполнитель</th>
        <th class="w-[250px]">Отделение МВД</th>
        <th class="w-[240px]">Договор</th>
        <th class="w-[200px]"></th>
        <th class="w-[180px]">Статус</th>
        <th class="w-[120px]">Уведомление</th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="notification in notifications" :key="notification.id" class="bg-white">
        <td class="w-[40px] p-0 pl-3">
          <UICheckbox v-model="checkedNotifications" :id="notification.id"
            :value="getNotificationsIds(notification, 2)" />
        </td>
        <td class="overflow-hidden">
          <p class="whitespace-nowrap">
            <span class="block overflow-hidden text-ellipsis" v-tooltip>{{ formatName(notification.worker) }}</span>
          </p>
          <p class="font-normal text-xs1 left-3 text-grey mt-1">
            {{ getCitizenship(notification.worker?.citizenship) }}
            <span class="ml-2 text-[12px] font-bold"
              :class="notificationNeedToUpdate(notification) ? 'text-error' : ''">{{
                getRemainedDays(notification)
              }}</span>
          </p>
        </td>
        <td class="whitespace-nowrap">
          <span class="block overflow-hidden text-ellipsis" v-tooltip>
            {{ notification.omvd || 'Нет данных об отделении МВД' }}
          </span>
        </td>
        <td>
          <p class="xl:whitespace-nowrap">от {{ formatDate(notification.start_date) }} до {{
            formatDate(notification.exp_date) }}</p>
          <p class="font-normal text-xs1 left-3 text-grey mt-1">№ {{ notification.id }}</p>
        </td>
        <td>
          <div class="flex" v-if="notification.status === 'not_signed'">
            <p class="xl:whitespace-nowrap">Не подписан</p>
          </div>
          <div class="flex whitespace-nowrap" v-else>
            <DoctypeBtn label="Документ" :linkHref="notification.file" :dwnld="notification.name" class="mr-2" />
            <DoctypeBtn label="Сертификат" :linkHref="getCertificateLink(notification.certificate?.file)"
              :dwnld="notification.name" v-if="notification.certificate" />
          </div>
        </td>
        <td>
          <div class="flex flex-col gap-2">
            <UMultiselect v-model="notification.start_work_notification.status" :options="notificationsStatusOptions"
              placeholder="Не выбрано" :multiple="false" :searchable="false" :close-on-select="true" :allowEmpty="false"
              label="label" track-by="value" :showLabels="false"
              @select="switchStartNotificationStatus($event, notification)">
              <template #singleLabel :slot-scope="notification.start_work_notification">
                <strong>№7</strong> {{ notification.start_work_notification.status.label }}
              </template>
            </UMultiselect>
            <UMultiselect v-model="notification.end_work_notification.status" :options="notificationsStatusOptions"
              placeholder="Не выбрано" :multiple="false" :searchable="false" :close-on-select="true" :allowEmpty="false"
              label="label" track-by="value" :showLabels="false" @select="switchNotificationStatus($event, notification)">
              <template #singleLabel :slot-scope="notification.end_work_notification">
                <strong>№8</strong> {{ notification.end_work_notification.status.label }}
              </template>
            </UMultiselect>
          </div>
        </td>
        <td>
          <div class="flex h-full items-center">
            <a v-if="notification.start_work_notification" :href="getDocLink(notification.start_work_notification.file)"
              :download="notification.start_work_notification.name || 'start_work_notification.xlsx'"
              class="notification-link" target="_blank">
              <div class="tooltip-text">Уведомление о заключении договора cкачается при клике</div>
              <img src="/icons/doc7.svg" class="mr-2" />
            </a>
            <a v-if="notification.end_work_notification" :href="getDocLink(notification.end_work_notification.file)"
              :download="notification.end_work_notification.name || 'end_work_notification.xlsx'"
              class="notification-link" target="_blank">
              <div class="tooltip-text">Уведомление о заключении договора cкачается при клике</div>
              <img src="/icons/doc8.svg" />
            </a>
          </div>
        </td>
      </tr>
    </template>
  </UTable>

  <OmvdActions v-if="selectedNotifications.length" :ids="selectedNotifications" :withSelect="true" initialDocType="8"
    :disableBtns="loading" @switchStatus="updateMultipleStatuses" @clearChecked="checkedNotifications = []" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useUiStore } from '@/stores/ui'
// import * as Sentry from '@sentry/browser'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import { API } from '@/utils/API'
import axios from 'axios'
import Loader from '@/components/Loader/Loader.vue'
import DoctypeBtn from '@/components/Button/DoctypeBtn.vue'
import { UInput, UISelect, UMultiselect, UICheckbox, UTable } from 'unit-uikit'
import OmvdActions from './OmvdActions.vue'
import { useObjectsStore } from '@/stores/objects'
import debounce from '@/utils/debounce'
import { formatName, formatDate } from '@/utils/helpers'
import WarningMessage from '@/components/Messages/WarningMessage.vue'
import { SERVER_COUNTRIES } from '@/utils/consts'
import COUNTRIES from '@/utils/Countries'
import {
  EOmvdNotificationStatus,
  getNotificationsIds,
  notificationsStatusOptions,
  switchNotificationStatus,
  normalizeNotifications
} from './omvd-helpers'

const ACCEPTED_STATUSES = 'app_7=filed&app_8=exists,signed,not_signed,active,finished,terminated,converting_error,null&order=exp_date'

export default defineComponent({
  components: {
    EmptyPagePlaceholder,
    DoctypeBtn,
    Loader,
    WarningMessage,
    UInput,
    UISelect,
    UMultiselect,
    UICheckbox,
    UTable,
    OmvdActions
  },
  emits: ['updateCounter'],
  data() {
    return {
      objectsStore: useObjectsStore() as any,
      uiStore: useUiStore() as any,
      loading: false,
      notifications: [] as any,
      objects: [] as any,
      objectsOptions: [] as any,
      mvdSearch: '',
      searchFirstName: '',
      searchLastName: '',
      selectAll: false,
      checkedNotifications: [],
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      notificationsStatusOptions,
      citizenship: null,
      nationalities: [
        {
          id: '',
          name: 'Показать все'
        },
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        }
      ]
    }
  },
  created() {
    this.getNotificationsData()
    this.getObjectsData()
    this.uiStore.checkedNotifications = []
    this.uiStore.selectAllNotifications = !!this.selectAll
    this.uiStore.selectAllNotificationsQueryParams = `${ACCEPTED_STATUSES}`
  },
  methods: {
    getNotificationsIds,
    updateMultipleStatuses({ type, status }: any) {
      this.loading = true
      const ids: any = []
      this.selectedNotifications.forEach((n: any) => {
        if (n.status === 'not_signed') {
          return
        }
        if (type === '7' && n.start_work_notification) {
          ids.push(n.start_work_notification.id)
        }
        if (type === '8' && n.end_work_notification) {
          ids.push(n.end_work_notification.id)
        }
      })
      switchNotificationStatus(status, ids)
        .then(() => {
          this.scrollData.count = -1
          this.loadDocsWithParams()
          this.$emit('updateCounter')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          //
        })
    },
    switchStartNotificationStatus(event: any, notification: any) {
      this.loading = true
      const ids = [notification.start_work_notification?.id]
      const status = event?.value
      if (status === EOmvdNotificationStatus.rejected) {
        ids.push(notification.end_work_notification?.id)
      }
      switchNotificationStatus(status, ids)
        .then(() => {
          this.scrollData.count = -1
          this.loadDocsWithParams()
          this.$emit('updateCounter')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          //
        })
    },
    switchNotificationStatus(event: any, notification: any) {
      this.loading = true
      const ids = [notification.end_work_notification?.id]
      const status = event?.value

      switchNotificationStatus(status, ids)
        .then(() => {
          this.scrollData.count = -1
          this.loadDocsWithParams()
          this.$emit('updateCounter')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          //
        })
    },
    getDocLink(link: string) {
      if (link) {
        return link.replace(/^(http:\/\/)/, 'https://')
      }
      return ''
    },
    loadDocsWithParams() {
      this.scrollData.page = 0
      this.notifications = []
      this.loadDocs()
    },
    async loadDocs() {
      if (this.notifications.length < this.scrollData.count || this.scrollData.count === -1) {
        let queryParams = ''
        if (this.mvdSearch) {
          queryParams += `&omvd=${this.mvdSearch}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.searchFirstName) {
          queryParams += `&first_name=${this.searchFirstName}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.searchLastName) {
          queryParams += `&last_name=${this.searchLastName}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.objects.id) {
          queryParams += `&object=${this.objects.id}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.citizenship) {
          queryParams += `&citizenship=${this.citizenship}`

          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&count=${this.scrollData.size}&${ACCEPTED_STATUSES}` + queryParams
        this.uiStore.selectAllNotificationsQueryParams = `${ACCEPTED_STATUSES}` + queryParams

        const response = await axios.get(API.GET_DOCUMENTS_NOTIFICATIONS + queryString)

        const data = response.data
        if (data.results && data.count) {
          if (this.scrollData.count === -1) {
            this.scrollData.count = data?.count || 0
          }
          this.scrollData.requestCount = data.count
          const notifications = normalizeNotifications(data.results)
          this.notifications.push(...notifications)
        }
      }
    },
    formatName,
    formatDate,
    getCitizenship(citizenship: any) {
      return COUNTRIES[citizenship]
    },
    getCertificateLink(link: string) {
      const URL_CLEAR = process.env.VUE_APP_URL
      return `${URL_CLEAR}${link}`
    },
    async getObjectsData() {
      const _objects = await this.objectsStore.fetcObjects()
      this.objects = _objects
      this.objectsOptions = [
        {
          id: '',
          name: 'Показать все'
        },
        ..._objects
      ]
    },
    notificationNeedToUpdate(notification: any) {
      const expDate = notification?.exp_date
      if (expDate) {
        const finishDate = new Date(expDate).valueOf()
        const dateNow = new Date()
        dateNow.setHours(0)
        dateNow.setMinutes(0)

        const oneDay = 1000 * 60 * 60 * 24

        const days = Math.round((finishDate - dateNow.valueOf()) / oneDay)

        return days <= 14
      }
      return false
    },
    getRemainedDays(notification: any) {
      const expDate = notification?.exp_date
      if (expDate) {
        const finishDate = new Date(expDate).valueOf()
        const dateNow = new Date()
        dateNow.setHours(0)
        dateNow.setMinutes(0)

        const oneDay = 1000 * 60 * 60 * 24

        const days = Math.round((finishDate - dateNow.valueOf()) / oneDay)

        if (days === 1) {
          return `${days} день`
        } else if (days < 6) {
          return `${days} дня`
        } else {
          return `${days} дней`
        }
      }
      return ''
    },
    getNotificationsData() {
      this.loading = true
      const queryString = `?count=${this.scrollData.size}&page=1&${ACCEPTED_STATUSES}`

      axios
        .get(API.GET_DOCUMENTS_NOTIFICATIONS + queryString)
        .then((res: any) => {
          this.scrollData.count = res.data.count
          this.notifications = normalizeNotifications(res.data.results)
          this.loading = false
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    debounceWatcherCall() {
      // @ts-ignore
      if (this.debounceloadDocsWithParams) {
        // @ts-ignore
        this.debounceloadDocsWithParams()
      } else {
        // @ts-ignore
        this.debounceloadDocsWithParams = debounce(() => {
          this.loadDocsWithParams()
        })
        // @ts-ignore
        this.debounceloadDocsWithParams()
      }
    }
  },
  watch: {
    checkedNotifications() {
      this.uiStore.checkedNotifications = this.checkedNotifications
    },
    selectAll() {
      this.uiStore.selectAllNotifications = !!this.selectAll

      if (this.selectAll) {
        const ids = [] as any

        this.notifications.forEach((n: any) => {
          if (n.end_work_notification) {
            ids.push(this.getNotificationsIds(n, 2))
          }
        })

        this.checkedNotifications = ids
      } else {
        this.checkedNotifications = []
      }
    },
    'objects.id'() {
      this.debounceWatcherCall()
    },
    mvdSearch() {
      this.debounceWatcherCall()
    },
    searchFirstName() {
      this.debounceWatcherCall()
    },
    searchLastName() {
      this.debounceWatcherCall()
    },
    citizenship() {
      this.debounceWatcherCall()
    }
  },
  computed: {
    selectedNotifications() {
      return (
        this.notifications?.filter((n: any) => {
          const isSigned = n.status !== 'not_signed'
          // @ts-ignore
          return isSigned && (this.checkedNotifications?.includes(this.getNotificationsIds(n, 2)) || false)
        }) || []
      )
    }
  }
})
</script>

<style scoped lang="postcss">
:deep(table) {
  @apply table-fixed;
}

:deep(.multiselect__content) {
  @apply text-xs1;
}

:deep(.multiselect__option span) {
  @apply ml-0;
}

:deep(.multiselect__option::before),
:deep(.multiselect__option--selected::before),
:deep(.multiselect__option--selected::after) {
  @apply hidden;
}
</style>
