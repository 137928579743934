import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useUiStore = defineStore('ui', () => {
  const showSignUploadDocsPopup = ref(false)
  const showUploadsPopup = ref(false)

  const procurationModalShown = ref(false)

  const selectAllNotifications = ref('')
  const selectAllNotificationsQueryParams = ref('')

  const checkedNotifications = ref([])

  const filters = ref({
    project: null,
    object: null
  })

  function $reset() {
    showSignUploadDocsPopup.value = false
    showUploadsPopup.value = false
    procurationModalShown.value = false
    filters.value = {
      project: null,
      object: null
    }
    selectAllNotifications.value = ''
    selectAllNotificationsQueryParams.value = ''
    checkedNotifications.value = []
  }

  return {
    showSignUploadDocsPopup,
    showUploadsPopup,
    filters,
    procurationModalShown,
    selectAllNotifications,
    selectAllNotificationsQueryParams,
    checkedNotifications,
    $reset
  }
})
