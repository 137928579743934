import { ref, Ref } from 'vue'

export function useSmsTimer(delay = 60) {
  const timer = ref(delay)
  const timeout = ref(null) as Ref<null | ReturnType<typeof setInterval>>

  function startTimer() {
    if (timeout.value) {
      clearTimeout(timeout.value)
    }
    timer.value = delay
    timeout.value = setInterval(() => {
      timer.value--
      if (timer.value <= 0 && timeout.value) {
        clearTimeout(timeout.value)
        timeout.value = null
      }
    }, 1000)
  }

  return { timer, startTimer }
}
