<script setup lang="ts">
import { computed } from 'vue'
import { UModal } from 'unit-uikit'
const emits = defineEmits(['closeModal', 'successModal'])
const props = defineProps({
  show: Boolean,
  act: Boolean,
  agreement: Boolean,
  task: Boolean
})

const whatSign = computed(() => {
  if (props.task) {
    return 'договор, если он не подписан, и задание'
  } else if (props.act) {
    return 'акт'
  } else if (props.agreement) {
    return 'договор'
  }
})
</script>
<template>
  <UModal
    :show="show"
    @cancel="emits('closeModal')"
    @success="emits('successModal')"
    title="Все готово!"
    successLabel="Подтвердить подписание"
  >
    <template #content>
      <h2 class="text-sm font-medium leading-snug mb-1 mt-2">Вам необходимо подписать документ</h2>
      <div class="text-sm leading-snug mb-8">
        <p>Нажимая на кнопку “Подтвердить подписание”, вы подписываете {{ whatSign }} электронной подписью.</p>
        <p>Для ознакомления с текстом документа нажмите кнопку "Отмена" и откройте шаблон в разделе "Выбранные документы к заказу".</p>
        <p class="font-medium mt-2">Пожалуйста, подтвердите подписание документа.</p>
      </div>
    </template>
  </UModal>
</template>
<style scoped lang="postcss">
:deep(.modal),
:deep(.modal .-content) {
  @apply !gap-0;
}
</style>
