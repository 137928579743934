<template>
  <div class="flex justify-center border border-gray-200 border-solid rounded items-center bg-white label-wrapper">
    <div class="flex-grow overflow-hidden">
      <span class="input-title">{{ label }}</span>
      <h3 class="text-xs2">{{ file.name }}</h3>
    </div>
    <button type="button" class="file-remove" @click.prevent="removeFile">+</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FileItemView',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    label: { type: String },
    file: { type: File } as any
  },
  methods: {
    removeFile() {
      // @ts-ignore
      const files = this.$attrs.modelValue.filter((item: File) => {
        return item !== this.file
      })
      this.$emit('update:modelValue', files)
    }
  }
})
</script>

<style lang="postcss" scoped>
.label-wrapper {
  @apply h-11 px-2 hover:cursor-pointer;
}

.file-remove {
  @apply leading-10 text-grey1 font-light text-[36px] text-center;
  @apply rotate-45;
  &:hover,
  &:active,
  &:focus {
    @apply text-black;
  }
}

.input-title {
  @apply text-xs leading-none text-grey1 left-2 whitespace-nowrap z-[99];
}
</style>
