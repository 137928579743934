<template>
  <div class="worker-description">
    <h3 class="text-base font-medium relative">
      <span :class="{ 'text-error': isDisabled, 'opacity-30': isDisabledAdd }">
        {{ getWorkerName(worker) }}
      </span>
      <ErrorTooltip
        v-if="isDisabled"
        class="err-tooltip-worker"
        :tooltip="`С этим исполнителем будет заключен договор от ${startDate}, так как у него нет действующего договора. Пожалуйста, воспользуйтесь разделом Уведомления МВД для подачи уведомления 7 в контролирующий орган и отметьте статус подачи “Подано”. Без этого невозможно создать акт.`"
      />
    </h3>
    <p :class="{ 'opacity-30': isDisabledAdd }" class="font-normal text-xs1 left-3 text-grey mt-1">
      <span
        class="mr-1 text-sm leading-5 text-success"
        :class="{
          'text-error': worker && worker.personaldata && worker.personaldata['is_self_employed'] !== false
        }"
        >{{ getWorkerStatus(worker && worker.personaldata && worker.personaldata['is_self_employed']) }}</span
      >
      <span class="citizenship">{{ getCitizenship(worker?.personaldata) }}</span>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import COUNTRIES from '@/utils/Countries'
import ErrorTooltip from '@/components/Tooltips/ErrorTooltip.vue'

export default defineComponent({
  components: { ErrorTooltip },
  props: {
    worker: Object as any,
    startDate: String,
    isDisabledAdd: Boolean
  },
  methods: {
    getWorkerName(worker: any) {
      return `${worker?.last_name} ${worker?.first_name} ${worker?.middle_name || ''}`
    },
    getWorkerStatus(status: any) {
      if (status === true) {
        return 'Статус самозанятого'
      }
      return 'Нет статуса самозанятого'
    },
    getCitizenship(personaldata: any) {
      if (!personaldata) {
        return ''
      }
      if (!personaldata.citizenship) {
        return ''
      }
      return COUNTRIES[personaldata.citizenship]
    }
  },
  computed: {
    isDisabled() {
      const citizenship = this.getCitizenship(this.worker?.personaldata)
      const noAggrement = !this.worker?.agreement?.length

      return noAggrement && !['РФ', 'Республика Беларусь'].includes(citizenship)
    }
  }
})
</script>

<style lang="postcss" scoped>
.citizenship {
  @apply font-normal pl-[3px] text-sm leading-5 text-black;
}
.err-tooltip-worker {
  @apply !absolute ml-2;
}
.err-tooltip-worker.tooltip-icon:hover:after {
  @apply text-black border-0 font-normal leading-5 text-sm p-3 w-[200px] xl:w-[480px] shadow-main;
}

.err-tooltip-worker.tooltip-icon:after {
  @apply -top-4 left-12 xl:left-24;
}
</style>
