import { ref, Ref, computed, toValue, watchEffect } from 'vue'
import useValidate from '@vuelidate/core'
import { required, maxLength, minLength, between, decimal } from '@vuelidate/validators'

type TOrderForm = {
  name: string
  omvd: string
  description: string
  project: string
  object: string
  address: string
  quantity: number
  budget?: null | number
  unit_type?: null | string
  agreement_template?: null | string | number
  order_template?: null | string | number
  acceptance_certificate_template?: null | string | number
  start_date?: null | string
  finish_date?: null | string
  selectedCertificates: string[]
  equipment: string[]
  special_requirements: string
  job_certs_requirements?: null | string
  suborder: null | any[]
  photo_after_completion?: null | boolean
  photo_comments: string
}

export function useOrderForm(workersAmount = 1) {
  const _workersAmount = ref(1)

  watchEffect(() => {
    _workersAmount.value = toValue(workersAmount)
  })

  const rules = computed(() => {
    return {
      // first stage
      name: { required, maxLength: maxLength(200) },
      omvd: { maxLength: maxLength(102) },
      description: { required, maxLength: maxLength(1000) },
      project: { required },
      object: { required },
      address: { required },
      quantity: { required, between: between(0, 32767), decimal },
      budget: { required },
      unit_type: { required },
      agreement_template: { required },
      order_template: { required },
      acceptance_certificate_template: { required },
      start_date: { required },
      finish_date: { required },
      equipment: { required, minLength: minLength(0) },
      special_requirements: { required, maxLength: maxLength(100) },
      job_certs_requirements: { required },

      // end first stage
      suborder: { required, minLength: minLength(_workersAmount), maxLength: maxLength(_workersAmount) }
    }
  })

  const order = ref({
    name: '',
    omvd: '',
    description: '',
    project: '',
    object: '',
    address: '',
    quantity: 0,
    budget: null,
    unit_type: null,
    agreement_template: null,
    order_template: null,
    acceptance_certificate_template: null,
    start_date: null,
    finish_date: null,
    selectedCertificates: [],
    equipment: [],
    special_requirements: '',
    job_certs_requirements: null,
    suborder: null,
    photo_after_completion: false,
    photo_comments: ''
  }) as Ref<TOrderForm>

  // @ts-expect-error order type
  const v$ = useValidate(rules, order)

  return { order, v$ }
}
