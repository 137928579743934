<script setup lang="ts">
import { computed } from 'vue'
import { formatPrice } from '@/utils/helpers'
import { UTooltip } from 'unit-uikit'

const props = defineProps({
  limitWithTax: {
    type: [Number, String],
    default: 0
  },
  limitWithoutTax: {
    type: [Number, String],
    default: 0
  },
  tax: {
    type: Boolean,
    default: false
  },
  order: {
    type: Object,
    required: true
  }
})

const limit = computed(() => {
  const _l1 = props.limitWithTax || props.order?.month_limit || 0
  const _l2 = props.limitWithoutTax || props.order?.month_limit_without_tax || 0
  return props.tax ? _l1 : _l2
})

const limitError = computed(() => {
  const sum = ((props.order?.sum || props.order?.price || 0) + '').replaceAll(',', '.').replaceAll(' ', '')
  return limit.value - +sum < 0 ? 'error' : ''
})

const tooltipText = computed(() => {
  if (props.tax) {
    return `Данное ограничение считается по следующему принципу.<br>
Если у клиента не было выплат с удержанием налога за предыдущий месяц, то фактически лимит считается с 1 числа текущего месяца  по 12 число следующего.<br>
Если у клиента в предыдущем месяце были выплаты с удержанием налога, то после оплаты налога 12 числа текущего  месяца, лимит будет  рассчитывается с 12 число текущего месяца по 12 число следующего.<br>
Из максимально допустимого платежа за расчетный период  в 250 000 рублей вычитаются все выплаты с  автоматическим удержанием налога банком партнером за текущий расчетный период.<br>
Это ограничение банка-партнера. Такой платеж не пройдет. Пожалуйста, скорректируйте сумму или удалите исполнителя из заказа`
  }
  return `Максимально возможный платеж исполнителю включая налог без автоматического удержания налога банком партнером.<br>
Данное ограничение рассчитывается по следующему принципу:<br>
из максимально допустимой выплаты исполнителю в 600 000 рублей на текущий месяц вычитается сумма всех платежей исполнителю за текущий месяц`
})
</script>

<template>
  <div class="flex items-center gap-1 whitespace-nowrap" :class="limitError">
    <span>
      {{ formatPrice(limit) }}
    </span>
    <UTooltip
      v-if="tax"
      :color="limitError ? 'error' : 'primary'"
      :allowHTML="true"
      class="!static mr-2 min-w-fit"
      :tooltip="tooltipText"
    />
    <UTooltip v-else :color="limitError ? 'error' : 'primary'" :allowHTML="true" class="!static mr-2 min-w-fit" :tooltip="tooltipText" />
  </div>
</template>

.
<style lang="postcss" scoped>
:deep(.tooltip-icon img) {
  width: 18px;
}

.error {
  @apply text-error;
}
</style>
