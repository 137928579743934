import { EOrderStatus, ESuborderStatus } from '@/types/api-values'
import { ORDER_STATUS, ORDER_COLORS, SUBORDER_STATUS, SUBORDER_COLORS } from '@/utils/consts'
import { TProcuration, EProcurationStatus } from '@/types/api-values'

export function compareDates(d1: Date, d2: Date) {
  const difference = d1.getTime() - d2.getTime()
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24))
  return totalDays
}

export const normalizeProcuration = (p: TProcuration) => {
  const remainDays = compareDates(new Date(p.procuration_exp_date), new Date())
  let status = p.status
  if (remainDays < 31 && remainDays > 0 && p.status === EProcurationStatus.active) {
    status = EProcurationStatus.expiring
  }
  return {
    ...p,
    status: status
  }
}

export function getFormatDate(date: string) {
  if (date) {
    const formatDateArr = date.slice(0, 10).replaceAll('-', '.').split('.').reverse()
    if (formatDateArr && formatDateArr[2]) {
      formatDateArr[2] = formatDateArr[2].slice(2)
    }
    return formatDateArr.join('.')
  }
}

export function getStatus(status: EOrderStatus) {
  return status && ORDER_STATUS[status] ? ORDER_STATUS[status] : ''
}

export function getStatusColor(status: EOrderStatus) {
  return status && ORDER_COLORS[status] ? ORDER_COLORS[status] : '#27BBDC'
}

export function getSuborderStatus(status: ESuborderStatus) {
  return status && SUBORDER_STATUS[status] ? SUBORDER_STATUS[status] : ''
}

export function getSuborderStatusColor(status: ESuborderStatus) {
  return status && SUBORDER_COLORS[status] ? SUBORDER_COLORS[status] : '#27BBDC'
}
