<template>
  <Loader v-if="loading" />
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>
  <EmptyPagePlaceholder v-if="!personal.length && !loading" pageType="staff" title="Сотрудники" />
  <UTable v-else colspan="4" :callback="findPersonal">
    <template #thead>
      <tr>
        <th>Сотрудник</th>
        <th>Должность</th>
        <th>Дата добавления</th>
        <th></th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="doc in personal" :key="doc.id"
        :class="{ 'bg-gray-100': doc.showEditMenu, 'bg-white': !doc.showEditMenu }">
        <td>
          {{ formatName(doc.company_staff) }}
          <p class="font-normal text-xs1 left-3 text-grey mt-1">
            <span :class="doc.company_staff?.verified ? '' : '!text-error'">{{ formatStatus(doc.company_staff)
            }}</span>&nbsp;
            <span :class="doc.is_enable ? '' : '!text-error'">{{ formatSignStatus(doc) }}</span>
          </p>
        </td>
        <td>
          {{ doc.position }}
        </td>
        <td>
          {{ formatDate(doc?.created, true) }}
        </td>
        <td>
          <button type="button" class="edit-btn relative" @click="openEditMenu(doc.id)">
            <img class="" src="/icons/extra.svg" alt="Редактировать" />
            <div v-if="doc.showEditMenu" class="edit-menu">
              <button class="btn-transparent" @click="disableSign(doc.id)">Отключить от подписания</button>
              <button class="btn-transparent" @click="enableSign(doc.id)">Подключение к подписанию</button>
            </div>
          </button>
        </td>
      </tr>
    </template>
  </UTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import * as Sentry from '@sentry/browser'
import { API } from '@/utils/API'
import axios from 'axios'
import Loader from '@/components/Loader/Loader.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import { UTable } from 'unit-uikit'
import { formatName, formatTime, formatDate } from '@/utils/helpers'

export default defineComponent({
  components: {
    UTable,
    Loader,
    EmptyPagePlaceholder
  },
  data() {
    return {
      loading: true,
      personal: [] as any,
      personalCount: 0,
      timer: null,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      }
    }
  },
  created() {
    this.getCompanyStaff()
  },
  methods: {
    formatDate,
    formatTime,
    formatName,
    getCompanyStaff() {
      axios
        .get(API.GET_COMPANY_STAFF + '?page_size=20')
        .then((res: { data: any }) => {
          this.personal = res.data
          this.scrollData.count = res.data.length
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          Sentry.captureException(e)
        })
    },
    async findPersonal() {
      if (this.personal.length < this.scrollData.count) {
        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}`
        const response = await axios.get(API.GET_DOCS_EXPORT + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.scrollData.count = data.count
          const result = data.results
          this.personal.push(...result)
        }
      }
    },
    formatStatus(worker: any) {
      return worker.verified ? 'Верифицирован' : 'Проходит верификацию'
    },
    formatSignStatus(worker: any) {
      return worker.is_enable ? '' : 'Отключен от подписания'
    },
    closeEditMenu() {
      this.personal = this.personal.map((item: any) => {
        item.showEditMenu = false
        return item
      })
    },
    openEditMenu(id: string | number) {
      this.personal = this.personal.map((item: any) => {
        const itemId = item.id
        if (id + '' === itemId + '') {
          item.showEditMenu = !item.showEditMenu
        } else {
          item.showEditMenu = false
        }
        return item
      })
    },
    enableSign(id: string | number) {
      axios
        .patch(API.DISABLED_COMPANY_STAFF(id), {
          is_enable: true
        })
        .then(() => {
          this.getCompanyStaff()
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    disableSign(id: string | number) {
      axios
        .patch(API.DISABLED_COMPANY_STAFF(id), {
          is_enable: false
        })
        .then(() => {
          this.getCompanyStaff()
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }
})
</script>

<style lang="postcss" scoped>
.edit-menu {
  @apply top-full right-0 translate-x-0 translate-y-0;

  & .btn-transparent {
    @apply min-w-max;
  }
}
</style>
