<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  limitWithTax: {
    type: [Number, String],
    default: 0
  },
  limitWithoutTax: {
    type: [Number, String],
    default: 0
  },
  tax: {
    type: Boolean,
    default: false
  },
  orders: {
    type: Array,
    required: true
  }
})

const showWarning = computed(() => {
  const _worker = props.orders.find((w: any) => {
    return props.tax ? w.month_limit_without_tax <= 0 : w.month_limit <= 0
  })
  return !!_worker
})
</script>

<template>
  <span v-if="showWarning" class="mt-4 block text-error mb-6 font-medium">
    Есть исполнители с превышенным лимитом {{ tax ? limitWithTax : limitWithoutTax }} ₽ в месяц.
  </span>
</template>
