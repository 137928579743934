<script setup lang="ts">
import { ref, PropType, computed } from 'vue'
import d from '@/dictionary'

import { decimalCount } from '@/utils/helpers'
import { formatDateSuborderModal, formatPrice } from '@/utils/helpers'
import { UTooltip, UModal, UButton, UInput, UISelect, UDateRange, UTextarea } from 'unit-uikit'

import dayjs from 'dayjs'
import axios from 'axios'
import { API } from '@/utils/API'

import TOrder from '@/views/Orders/types'

const emits = defineEmits(['closeModal', 'successModal'])

const props = defineProps({
  show: Boolean,
  suborder: {
    type: Object as PropType<TOrder>,
    required: true
  },
  tax: Boolean,
  limitWithTax: {
    type: Number,
    default: 0
  },
  limitWithoutTax: {
    type: Number,
    default: 0
  },
  errors: {
    type: Array,
    default: []
  }
})

const dateReady = ref(false)
const unitTypesList = ref([])

const getStartDate = (task: TOrder) => {
  if (props.errors.includes('incorrectRangeDate') || props.errors.includes('incorrectDate')) {
    return null
  }
  return formatDateSuborderModal(task.workPeriod.work_started)
}
const getFinishDate = (task: TOrder) => {
  if (props.errors.includes('incorrectRangeDate') || props.errors.includes('incorrectDate')) {
    return null
  }
  return formatDateSuborderModal(task.workPeriod.work_ends)
}
const startDate = getStartDate(props.suborder)
const finishDate = getFinishDate(props.suborder)

const suborderDates = ref({
  start_date: startDate || null,
  finish_date: finishDate || null
}) as any
dateReady.value = true

const getQuantity = (task: TOrder) => {
  if (task.workAmount && +task.workAmount > 0) {
    return task.workAmount
  }
  return 0
}

const getPrice = (task: TOrder) => {
  const price = (task.sum + '').replaceAll(',', '.').replaceAll(' ', '')
  if (price && +price > 0) {
    return price
  }
  return '0'
}

const getUnitType = (task: TOrder) => {
  const name = task.unitType
  const result = unitTypesList.value?.find((i: any) => {
    return i.name === name
  })
  return result?.id
}

const newSuborder = ref({
  description: props.suborder?.taskDescription || '',
  name: props.suborder?.task || '',
  quantity: getQuantity(props.suborder),
  price: getPrice(props.suborder),
  unit_type: ''
}) as any

axios.get(API.GET_UNIT_TYPES).then((res) => {
  unitTypesList.value = res.data
  newSuborder.value.unit_type = getUnitType(props.suborder)
})

const successAction = () => {
  const start = suborderDates.value.start_date && suborderDates.value.start_date.valueOf()
  const end = suborderDates.value.finish_date && suborderDates.value.finish_date.valueOf()
  newSuborder.value.start_date = dayjs(start).format('DD.MM.YYYY')
  newSuborder.value.finish_date = dayjs(end).format('DD.MM.YYYY')
  emits('successModal', newSuborder)
}

const isDisabledBtn = computed(() => {
  const value = newSuborder.value
  const dataIsCorrect = value.price && value.quantity && value.unit_type && value.name && value.description

  return (
    !dataIsCorrect ||
    !suborderDates.value.start_date ||
    !suborderDates.value.finish_date ||
    value.description?.length > 1000 ||
    +value.price === 0
  )
})

const limitError = computed(() => {
  const sum = (newSuborder.value.price + '').replaceAll(',', '.').replaceAll(' ', '')

  if (props.tax && +props.suborder.month_limit) {
    return +sum > +props.suborder.month_limit
  }
  if (!props.tax && +sum > +props.suborder.month_limit_without_tax) {
    return true
  }
  return false
})

const unitTypeDoesntExist = computed(() => {
  const unitType = newSuborder.value.unit_type
  const result = unitTypesList.value?.find((i: any) => {
    return i.id + '' === unitType + ''
  })
  return !result
})
</script>

<template>
  <UModal :show="show" @cancel="emits('closeModal')" @success="successAction" title="Редактирование"
    successLabel="Сохранить" class="long-modal">
    <template #content>
      <h2 class="title">Информация об исполнителе</h2>

      <p class="error-text" v-if="suborder.checkErrors">{{ suborder.checkErrors }}</p>

      <div class="flex gap-3">
        <UInput class="flex-grow basis-1/5" label="Фамилия" :value="suborder?.data?.firstName" :readonly="true" />
        <UInput class="flex-grow basis-1/5" label="Имя" :value="suborder?.data?.lastName" :readonly="true" />
        <UInput class="flex-grow basis-1/5" label="Отчество" :value="suborder?.data?.middleName" :readonly="true" />
        <UInput class="flex-grow basis-1/5" label="Номер телефона" :value="suborder?.data?.phone || ''" mask="phone"
          :readonly="true" />
      </div>

      <div class="flex gap-3">
        <UInput class="flex-grow basis-1/5" label="ИНН" :value="suborder?.inn" mask="inn" :readonly="true" />
        <div class="flex-grow"></div>
        <div class="flex-grow"></div>
        <div class="flex-grow"></div>
      </div>

      <h2 class="title">Информация о заказе</h2>

      <div class="relative">
        <UInput class="flex-grow" label="Название заказа (попадет в чек оплаты)" :value="newSuborder.name"
          v-model="newSuborder.name" />
        <p class="absolute error-text" v-if="newSuborder.name?.length >= 250">Максимальная длина 250 символов</p>
        <p class="absolute error-text" v-if="!newSuborder.name">Обязательное поле</p>
      </div>

      <div class="flex gap-3">
        <div class="relative flex-grow basis-1/5">
          <template v-if="dateReady">
            <UDateRange class="!w-[210px]" title="Период работ" :start="suborderDates.start_date"
              :finish="suborderDates.finish_date"
              @update-start-date="(date: string) => { suborderDates.start_date = date }"
              @update-finish-date="(date: string) => { suborderDates.finish_date = date }" />
          </template>
        </div>
        <div class="relative flex-grow basis-1/5">
          <UInput class="flex-grow" label="Объем работ" :value="newSuborder?.quantity" v-model="newSuborder.quantity"
            mask="numbers" />
          <p class="absolute error-text" v-if="!newSuborder.quantity">Обязательное поле</p>
        </div>
        <div class="relative flex-grow basis-1/5">
          <UISelect id="unitTypeSelector" class="flex-grow" label="Тип единиц" :value="newSuborder?.unit_type"
            v-model="newSuborder.unit_type" :options="unitTypesList" />
          <p class="absolute error-text" v-if="unitTypeDoesntExist">Выберите значение из списка</p>
        </div>
        <div class="relative flex-grow basis-1/5">
          <UInput id="newSuborderPrice" class="flex-grow" :class="{
            'error-txt': limitError || (errors?.includes('wrongDecimal') && newSuborder.price && decimalCount(newSuborder.price) > 2)
          }" label="Стоимость" :value="newSuborder?.price.replaceAll('.', ',')" v-model="newSuborder.price"
            mask="valueCurrency" :showCurrency="true" />
          <UTooltip v-if="tax" percent :tooltip="d['payment-fee']" />
          <p class="absolute error-text" v-if="!newSuborder.price">Обязательное поле</p>
          <p class="absolute error-text"
            v-if="errors?.includes('wrongDecimal') && newSuborder.price && decimalCount(newSuborder.price) > 2">
            Значение должно содержать максимум две цифры после запятой
          </p>
        </div>
      </div>

      <div v-if="limitError && tax" class="error-wrapper relative mb-4">
        <span class="error-txt">Превышен максимально допустимый платеж исполнителю {{ formatPrice(limitWithTax) }}&nbsp;₽
          за текущий месяц.</span><br />
        Доступно для выплаты исполнителю на текущий момент {{ formatPrice(suborder?.month_limit) }}&nbsp;₽.<br />
        <span class="error-txt">Данное ограничение считается по следующему принципу.</span><br />
        Если у клиента не было выплат с удержанием налога за предыдущий месяц, то фактически лимит считается с 1 числа
        текущего месяца по 12
        число следующего.<br />
        Если у клиента в предыдущем месяце были выплаты с удержанием налога, то после оплаты налога 12 числа текущего
        месяца, лимит будет
        рассчитывается с 12 число текущего месяца по 12 число следующего.<br />
        Из максимально допустимого платежа за расчетный период в 250 000 рублей вычитаются все выплаты с автоматическим
        удержанием налога
        банком партнером за текущий расчетный период.<br />
        Это ограничение банка-партнера. Такой платеж не пройдет. Пожалуйста, скорректируйте сумму или удалите исполнителя
        из заказа
      </div>
      <div v-if="limitError && !tax" class="error-wrapper relative mb-4">
        <span class="error-txt">Превышен максимально допустимый платеж исполнителю {{ formatPrice(limitWithoutTax)
        }}&nbsp;₽ за текущий месяц.</span><br />
        Доступно для выплаты исполнителю на текущий момент {{ formatPrice(suborder?.month_limit_without_tax)
        }}&nbsp;₽.<br />
      </div>

      <div class="relative">
        <label class="input-wrapper !border-0 !h-[10rem]">
          <h3 class="input-wrapper__title">Описание заказа</h3>
          <UTextarea maxLength="1000" name="modalOrderdescription" id="modalOrderdescription"
            placeholder="Описание заказа" v-model="newSuborder.description" :value="newSuborder.description" />
        </label>
        <p class="absolute error-text" v-if="newSuborder.description?.length > 1000">Пожалуйста, введите не более 1000
          символов</p>
      </div>
    </template>
    <template #buttons>
      <div class="flex gap-3 justify-end">
        <UButton label="Отмена" color="secondary" @click="emits('closeModal')" />
        <UButton label="Сохранить" :disabled="isDisabledBtn" @click="successAction" />
      </div>
    </template>
  </UModal>
</template>

<style lang="postcss" scoped>
.long-modal :deep(.modal) {
  max-width: 780px;
}

.title {
  @apply not-italic font-medium text-base leading-snug text-black;
}

.error-text {
  @apply not-italic font-normal text-sm leading-snug text-error z-[1];
}

.error-txt,
:deep(.error-txt),
:deep(.error-txt input.input) {
  @apply text-error;
}

.error-wrapper {
  @apply text-sm font-normal leading-snug;
}
</style>
