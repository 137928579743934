<script setup lang="ts">
import { computed } from 'vue'
import { formatPrice } from '@/utils/helpers'

const props = defineProps({
  limitWithTax: {
    type: [Number, String],
    default: 0
  },
  limitWithoutTax: {
    type: [Number, String],
    default: 0
  },
  tax: {
    type: Boolean,
    default: false
  },
  order: {
    type: Object,
    required: true
  }
})

const limit = computed(() => {
  const _l1 = props.limitWithTax || props.order?.month_limit || 0
  const _l2 = props.limitWithoutTax || props.order?.month_limit_without_tax || 0
  return props.tax ? _l1 : _l2
})

const limitError = computed(() => {
  const sum = ((props.order?.sum || props.order?.price || 0) + '').replaceAll(',', '.').replaceAll(' ', '')
  return limit.value - +sum < 0 ? 'error' : ''
})
</script>

<template>
  <div class="flex items-center justify-start gap-1 whitespace-nowrap" :class="limitError">
    <span>
      {{ formatPrice(order.sum) }}
    </span>
  </div>
</template>

.
<style lang="postcss" scoped>
:deep(.tooltip-icon img) {
  width: 18px;
}

.error {
  @apply text-error;
}
</style>
