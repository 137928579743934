<template>
  <component :is="currentComponent">
    <template #view-switcher>
      <USwitch v-model="showSuborders" id="financeViewSwitcher" labelText="Показать счета" labelON="Скрыть" labelOFF="Показать" />
    </template>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import FinanceOrdersList from '@/views/Finance/Components/OrdersList.vue'
import SubordersList from '@/views/Finance/Components/SubordersList.vue'

import { USwitch } from 'unit-uikit'

export default defineComponent({
  name: 'FinanceOrders',
  data() {
    return {
      showSuborders: false
    }
  },
  components: {
    USwitch
  },
  computed: {
    currentComponent() {
      return this.showSuborders ? SubordersList : FinanceOrdersList
    }
  }
})
</script>
