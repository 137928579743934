<template>
  <div v-if="agreement">
    <p class="whitespace-nowrap">От {{ formatDate(agreement.created, false, false) }} до {{
      formatDate(agreement.exp_date, false, false) }}</p>
    <p class="font-normal text-xs1 left-3 text-grey mt-1">№ {{ agreement.id }}</p>
  </div>
  <div v-else>Отсутствует</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { formatDate } from '@/utils/helpers'

export default defineComponent({
  props: {
    worker: Object
  },
  methods: {
    formatDate
  },
  computed: {
    agreement() {
      const agreement = this.worker?.agreement
      return agreement && agreement[0]
    }
  }
})
</script>
