<template>
  <header class="flex flex-wrap items-center justify-between">
    <h1 class="text-3xl font-medium mr-auto break-words overflow-hidden">{{ title }}</h1>
    <slot></slot>
    <div class="flex">
      <button class="header-btn" @click.prevent="showNotifications = true">
        <img src="/icons/notification.svg" alt="Уведомления" />
        <span v-if="badge" class="ml-1 py-0 px-2 font-medium text-xs h-4 leading-4 rounded text-white bg-accent text-center">{{
          badge
        }}</span>
        <Notifications :showPopup="showNotifications" @close="close" @change-counter="changeCounter" />
      </button>
    </div>

    <HeaderWarnings v-if="isCompanySelected" />
    <HeaderAccessDeniedModal v-if="isAccessDenied" />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Notifications from '@/components/Messages/Notifications.vue'
import axios from 'axios'
import { API } from '@/utils/API'
import HeaderWarnings from './HeaderWarnings.vue'
import HeaderAccessDeniedModal from './HeaderAccessDeniedModal.vue'
import { useCompanyStore } from '@/stores/company'

export default defineComponent({
  props: ['title'],
  components: {
    Notifications,
    HeaderWarnings,
    HeaderAccessDeniedModal
  },
  data() {
    return {
      showNotifications: false,
      badge: 0
    }
  },
  async created() {
    this.getNotifications()
  },
  methods: {
    getNotifications() {
      axios
        .get(API.NOTIFICATIONS)
        .then((res: any) => {
          this.badge = res.data.count
        })
        .catch((e: any) => {
          console.error('error here', e)
        })
    },
    close() {
      setTimeout(() => {
        this.showNotifications = false
      }, 1)
    },
    changeCounter() {
      this.getNotifications()
    }
  },
  computed: {
    isCompanySelected() {
      const companyStore = useCompanyStore()
      return !!companyStore.activeCompany
    },
    isAccessDenied() {
      const companyStore = useCompanyStore()
      return companyStore.accessDenied
    }
  }
})
</script>

<style lang="postcss" scoped>
.header-btn {
  @apply flex items-center ml-2.5;
  &:hover img {
    @apply opacity-80 cursor-pointer;
  }
}

:deep(.modal) {
  @apply !gap-2 !max-w-[600px];
}

:deep(.modal .-content) {
  @apply !gap-0;
}
</style>
