<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import COUNTRIES from '@/utils/Countries'
import { API } from '@/utils/API'
import axios from 'axios'
import WorkerAggrementDesk from '@/components/Worker/WorkerAggrementDesk.vue'
import ConfirmSignModal from '@/components/Modals/ConfirmSignModal.vue'
import debounce from '@/utils/debounce'
import { formatToServerDateNotifications, formatName } from '@/utils/helpers'
import { UInput, UButton, UTable, UModal, UICheckbox, UISelect } from 'unit-uikit'
import { storeToRefs } from 'pinia'
import { useObjectsStore } from '@/stores/objects'
import { useProjectsStore } from '@/stores/projects'

const emits = defineEmits(['close', 'error', 'success'])

const step = ref(1)
const workers = ref([])
const signedNotifications = ref([])
const checkedNotifications = ref([])
const searchDate = ref('')
const selectAll = ref(false)
const selectAllSigned = ref(true)
const selectedTemplate = ref(null)
const searchFirstName = ref('')
const searchLastName = ref('')
const searchMvd = ref('')
const objectAdress = ref('')
const agreement_templates = ref([])
const showConfirmSignModal = ref(false)

const selectedObject = ref('allObjects')
const selectedProject = ref('allProjects')
const objectsStore = useObjectsStore()
const projectsStore = useProjectsStore()

objectsStore.fetcObjects()
projectsStore.fetchProjects()

const { objectOptions, projectFilter } = storeToRefs(objectsStore)
const { projectOptions } = storeToRefs(projectsStore)

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

const loadWorkers = async () => {
  if (workers.value.length < scrollData.count) {
    scrollData.page++

    let queryString = `?page=${scrollData.page}&count=${scrollData.size}&complete_data=true`
    if (searchFirstName.value) {
      queryString += `&first_name=${searchFirstName.value}`

      if (workers.value.length && !(workers.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (searchLastName.value) {
      queryString += `&last_name=${searchLastName.value}`

      if (workers.value.length && !(workers.value.length < scrollData.requestCount)) {
        return
      }
    }

    try {
      const response = await axios.get(API.ORDER_PREINVITED_WORKERS + queryString + '&verified=true')

      const data = response.data
      if (data.results && data.count) {
        scrollData.requestCount = data.count
        const _workers = data.results.map((item: any) => {
          item.id = item.personaldata?.user
          return item
        })
        workers.value.push(..._workers)
      }
    } catch (error) {}
  }
}

const loadWorkersWithParams = () => {
  scrollData.page = 0
  workers.value = []
  loadWorkers()
}

const debounceLoadWorkersWithParams = debounce(() => {
  loadWorkersWithParams()
})

const createNewNotifications = () => {
  axios
    .post(API.GET_DOCUMENTS_NOTIFICATIONS, {
      workers: checkedNotifications.value,
      agreement_template: selectedTemplate.value,
      mvd: searchMvd.value,
      start_date: formatToServerDateNotifications(searchDate.value),
      object: selectedObject.value,
      project: selectedProject.value,
      address: objectAdress.value
    })
    .then(() => {
      emits('success')
    })
    .catch((e: any) => {
      emits('error', e)
    })
}

const getCitizenship = (citizenship: any) => {
  return COUNTRIES[citizenship]
}

const getPreparationData = async () => {
  const prepData = await axios.get(API.GET_ORDER_PREPARATION)
  agreement_templates.value = prepData.data.agreement_templates
}

const createNotifications = () => {
  showConfirmSignModal.value = true
}

const confirmSign = () => {
  if (step.value === 1) {
    axios
      .post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, {
        workers: checkedNotifications.value
      })
      .then((res: any) => {
        showConfirmSignModal.value = false
        if (res.data && res.data.length) {
          signedNotifications.value = res.data
          step.value = 2
        } else {
          createNewNotifications()
        }
      })
      .catch((error: any) => {
        console.error(error)
        showConfirmSignModal.value = false
      })
  } else if (step.value === 2) {
    createNewNotifications()
  }
}

const filteredWorkers = computed(() => {
  return workers.value.filter((worker: any) =>
    signedNotifications.value.some((signedNotification: any) => worker.id === signedNotification.worker_id)
  )
})

const isDataReady = computed(() => {
  const addressIsReady = !!objectAdress.value
  const objectIsReady = !!(selectedObject.value && selectedObject.value !== 'allObjects')
  const projectIsReady = !!(selectedProject.value && selectedProject.value !== 'allProjects')
  const omvdIsReady = !!searchMvd.value
  const dateIsReady = searchDate.value.length === 10
  const templateIsReady = !!selectedTemplate.value

  return addressIsReady && objectIsReady && projectIsReady && omvdIsReady && dateIsReady && templateIsReady
})

getPreparationData()

axios
  .get(API.ORDER_PREINVITED_WORKERS + `?page=${scrollData.page}&count=${scrollData.size}&complete_data=true&verified=true`)
  .then((response: any) => {
    scrollData.count = response.data.count

    workers.value = response.data.results.map((item: any) => {
      item.id = item.personaldata?.user
      return item
    })
  })
  .catch((e: any) => {
    console.error(e)
  })

watch(selectedObject, (val) => {
  const result = objectOptions.value.find((i: any) => {
    return +i.id === +val
  })
  if (result && result.address) {
    objectAdress.value = result.address
  }
})

watch(selectedProject, () => {
  if (selectedProject.value !== 'allProjects') {
    projectFilter.value = selectedProject.value
    let isObjectSelectFlag = false
    objectOptions.value.find((item: any) => {
      if (!isObjectSelectFlag) {
        isObjectSelectFlag = item.id + '' === selectedObject.value.id + '' && item.project?.id + '' === selectedProject.value + ''
      }
      return isObjectSelectFlag
    })

    if (!isObjectSelectFlag) {
      selectedObject.value = 'allObjects'
    }
  } else {
    projectFilter.value = null
    selectedObject.value = 'allObjects'
  }
})

watch(selectAll, () => {
  if (selectAll.value) {
    checkedNotifications.value = workers.value.map((worker: any) => {
      return worker.id
    })
  } else {
    checkedNotifications.value = []
  }
})

watch([searchFirstName, searchLastName], debounceLoadWorkersWithParams)

watch(selectAllSigned, () => {
  if (selectAllSigned.value) {
    signedNotifications.value.forEach((item: any) => {
      if (!checkedNotifications.value.includes(+item)) {
        checkedNotifications.value.push(+item)
      }
    })
  } else {
    checkedNotifications.value = checkedNotifications.value.filter((item: any) => {
      return !signedNotifications.value.includes(item + '')
    })
  }
})
</script>

<template>
  <UModal :show="true" title="Создать договор с уведомлением МВД" v-if="step === 1">
    <template #content>
      <h4 class="text-sm">Выберите исполнителей для создания уведомлений</h4>
      <div class="flex">
        <UInput
          id="searchLastName"
          class="!w-1/2"
          placeholder="Введите фамилию исполнителя"
          label="Поиск по фамилии"
          v-model="searchLastName"
          :value="searchLastName"
        />
        <UInput
          id="searchFirstName"
          class="!w-1/2"
          placeholder="Введите имя исполнителя"
          label="Поиск по имени"
          v-model="searchFirstName"
          :value="searchFirstName"
        />
      </div>

      <UTable v-if="workers.length" class="modal-content" colspan="4" :callback="loadWorkers">
        <template #thead>
          <tr>
            <th><UICheckbox v-model="selectAll" id="selectAll" value="false" /></th>
            <th>Исполнитель ({{ checkedNotifications.length }})</th>
            <th>Гражданство</th>
            <th>Действующий договор</th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="worker in workers" :key="worker.id" class="bg-white modal-tr">
            <td>
              <UICheckbox v-model="checkedNotifications" :id="worker.id" :value="worker.id" />
            </td>
            <td>
              {{ formatName(worker) }}
            </td>
            <td>
              {{ getCitizenship(worker.personaldata?.citizenship) }}
            </td>
            <td>
              <WorkerAggrementDesk :worker="worker" />
            </td>
          </tr>
        </template>
      </UTable>
      <span class="text-sm block h-[190px]" v-else>Нет данных</span>

      <h4 class="mt-auto text-sm">В списке показаны исполнители с полными данными</h4>
      <div class="flex flex-col">
        <div class="flex w-full mb-3">
          <UISelect
            id="agreementTemplatesSelector"
            class="!w-1/2"
            label="Шаблон договора"
            unselectedLabel="Показать всё"
            v-model="selectedTemplate"
            :value="selectedTemplate"
            :options="agreement_templates"
          />
          <UInput class="!w-1/2" placeholder="Название МВД" label="МВД" v-model="searchMvd" :value="searchMvd" />
        </div>
        <div class="flex w-full mb-3">
          <UInput
            class="basis-1/3 !min-w-[auto]"
            placeholder="Впишите дату"
            label="Дата договора"
            mask="date"
            v-model="searchDate"
            :value="searchDate"
          />
          <UISelect
            id="projectSelector"
            class="basis-1/3 !min-w-[auto]"
            label="Проект"
            unselectedLabel="Показать всё"
            v-model="selectedProject"
            :value="selectedProject"
            :options="projectOptions"
          />
          <UISelect
            id="objectSelector"
            class="basis-1/3 !min-w-[auto]"
            label="Объект"
            unselectedLabel="Показать всё"
            v-model="selectedObject"
            :value="selectedObject"
            :options="objectOptions"
          />
        </div>
        <UInput
          class="!w-full !max-w-[100%]"
          placeholder="Адрес объекта"
          label="Впишите адрес"
          v-model="objectAdress"
          :value="objectAdress || ''"
        />
      </div>
    </template>
    <template #buttons>
      <div class="flex">
        <UButton class="w-full mr-3" color="secondary" label="Отмена" @click="$emit('close')" />
        <UButton class="w-full" label="Продолжить" @click="createNotifications" :disabled="!checkedNotifications.length || !isDataReady" />
      </div>
    </template>
  </UModal>

  <UModal :show="true" title="Эти исполнители уже имеют активный договор, вы уверены что хотите подписать новые?" v-if="step === 2">
    <template #content>
      <h4>
        Подписывая новый договор вы даете поручение платформе направить уведомление исполнителю об одностороннем отказе от действующего
        договора в случае его наличия.
      </h4>

      <UTable class="modal-content" colspan="4" :callback="loadWorkers">
        <template #thead>
          <tr>
            <th>
              <UICheckbox v-model="selectAllSigned" id="selectAll" />
            </th>
            <th>Исполнитель ({{ signedNotifications.length }})</th>
            <th>Гражданство</th>
            <th>Действующий договор</th>
          </tr>
        </template>
        <template #tbody>
          <tr v-for="worker in filteredWorkers" :key="worker.id" class="bg-white modal-tr">
            <td>
              <UICheckbox v-model="checkedNotifications" :id="worker.id" :value="worker.id" />
            </td>
            <td>
              {{ formatName(worker) }}
            </td>
            <td>
              {{ getCitizenship(worker.personaldata?.citizenship) }}
            </td>
            <td>
              <WorkerAggrementDesk :worker="worker" />
            </td>
          </tr>
        </template>
      </UTable>
    </template>
    <template #buttons>
      <div class="flex mt-auto">
        <UButton class="w-full mr-3" color="secondary" label="Отмена" size="xl" @click="this.step = 1" />
        <UButton class="w-full" label="Продолжить" @click="createNotifications" :disabled="!checkedNotifications.length || !isDataReady" />
      </div>
    </template>
  </UModal>
  <ConfirmSignModal agreement :show="showConfirmSignModal" @closeModal="showConfirmSignModal = false" @successModal="confirmSign" />
</template>

<style lang="postcss" scoped>
:deep(.modal) {
  @apply !overflow-y-auto !gap-3 !max-w-[650px] my-4;

  & .close-btn {
    @apply hidden;
  }

  &-content {
    @apply min-h-[50%] sm:min-h-fit max-h-[190px];
  }

  &-tr {
    > td {
      @apply bg-backgroundMainColor;
    }
  }
}

:deep(.list-table-wrap) {
  @apply mb-0;
}

:deep(table th) {
  @apply !pl-4;
}

:deep(.input-container),
:deep(.select) {
  @apply !mr-2;
}
</style>
