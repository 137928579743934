<script setup lang="ts">
import { ref, watch } from 'vue'
import d from '@/dictionary'

import { API } from '@/utils/API'
import axios from 'axios'
import { formatPrice, formatDate, formatTime, formatToServerDate } from '@/utils/helpers'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import Loader from '@/components/Loader/Loader.vue'
import BillInfo from '@/views/Finance/Components/BillInfo.vue'
import debounce from '@/utils/debounce'
import { EOrderStatus } from '@/types/api-values'
import { ORDER_STATUS } from '@/utils/consts'
import { UTable, UInput, UISelect, UDateRange, UTooltip } from 'unit-uikit'
import { getStatus, getStatusColor } from '@/views/Orders/utils'

const orders = ref([]) as any
const loading = ref(true)
const searchFirstName = ref('')
const searchLastName = ref('')
const selectedStatus = ref('')
const filterDates = ref({
  start_date: null,
  finish_date: null
}) as any

const statusOptions = [
  {
    id: '',
    name: 'Все'
  },
  {
    id: EOrderStatus.active,
    name: ORDER_STATUS[EOrderStatus.active]
  },
  {
    id: EOrderStatus.finished,
    name: ORDER_STATUS[EOrderStatus.finished]
  },
  {
    id: EOrderStatus.cancelled,
    name: ORDER_STATUS[EOrderStatus.cancelled]
  }
]

const scrollData = {
  count: 0,
  requestCount: 0,
  size: 20,
  page: 1
}

const loadOrders = async () => {
  if (orders.value.length < scrollData.count) {
    let queryParams = ''

    if (searchFirstName.value) {
      queryParams += `&first_name=${searchFirstName.value}`

      if (orders.value.length && !(orders.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (searchLastName.value) {
      queryParams += `&last_name=${searchLastName.value}`

      if (orders.value.length && !(orders.value.length < scrollData.requestCount)) {
        return
      }
    }
    const startDate = filterDates.value.start_date ? new Date(filterDates.value.start_date) : null
    const finishDate = filterDates.value.finish_date ? new Date(filterDates.value.finish_date) : null

    if (startDate) {
      queryParams += `&start_date=${formatToServerDate(startDate)}T00:00:00`

      if (orders.value.length && !(orders.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (finishDate) {
      queryParams += `&end_date=${formatToServerDate(finishDate)}T23:59:59.99`

      if (orders.value.length && !(orders.value.length < scrollData.requestCount)) {
        return
      }
    }
    if (selectedStatus.value) {
      queryParams += `&status=${selectedStatus.value}`

      if (orders.value.length && !(orders.value.length < scrollData.requestCount)) {
        return
      }
    }

    scrollData.page++
    const queryString = `?orders_with_bills=1&ordering=-created&page=${scrollData.page}&page_size=${scrollData.size}` + queryParams
    try {
      const response = await axios.get(API.GET_ORDER + queryString)
      const data = response.data

      if (data.results && data.count) {
        scrollData.requestCount = data.count
        const result = data.results
        orders.value.push(...result)
      }
    } catch (error) {
      //
    }
  }
}

const loadOrdersWithParams = () => {
  scrollData.page = 0
  orders.value = []
  loadOrders()
}

const debounceLoadOrdersWithParams = debounce(() => {
  loadOrdersWithParams()
})

watch(
  () => filterDates.value.start_date,
  () => {
    debounceLoadOrdersWithParams()
  }
)

watch(
  () => filterDates.value.finish_date,
  () => {
    debounceLoadOrdersWithParams()
  }
)

watch([searchFirstName, searchLastName, selectedStatus], debounceLoadOrdersWithParams)

// Get orders
axios
  .get(API.GET_ORDER + `?orders_with_bills=1&ordering=-created&page_size=${scrollData.size}`)
  .then((response: any) => {
    scrollData.count = response.data.count
    const _orders = response.data.results || []
    orders.value = _orders
    loading.value = false
  })
  .catch((e) => {
    console.error(e)
    loading.value = false
  })
</script>

<template>
  <div class="flex flex-wrap xl:flex-nowrap gap-3 mb-2">
    <UInput
      class="basis-full lg:basis-1/4 !min-w-[200px] lg:!max-w-[230px]"
      id="searchLastName"
      placeholder="Введите фамилию исполнителя"
      label="Поиск по фамилии"
      v-model="searchLastName"
      :value="searchLastName"
    />
    <UInput
      class="basis-full lg:basis-1/4 !min-w-[200px] lg:!max-w-[200px]"
      id="searchFirstName"
      placeholder="Введите имя исполнителя"
      label="Поиск по имени"
      v-model="searchFirstName"
      :value="searchFirstName"
    />
    <UDateRange
      class="basis-full lg:max-w-[230px] !min-w-[230px]"
      :start="filterDates.start_date"
      :finish="filterDates.finish_date"
      @update-start-date="(date: string) => { filterDates.start_date = date }"
      @update-finish-date="(date: string) => { filterDates.finish_date = date }"
      title="Время создания"
      startPlaceHolder="От"
      endPlaceHolder="До"
    />
    <UISelect
      class="basis-full lg:max-w-[170px] !min-w-[170px]"
      id="financeStatusSelector"
      label="Статус операции"
      unselectedLabel="Любой"
      v-model="selectedStatus"
      :value="selectedStatus"
      :options="statusOptions"
    />
    <div class="min-w-[190px]">
      <slot name="view-switcher" />
    </div>
  </div>
  <div class="finance-orders min-h-[300px] lg:min-h-[auto] relative flex-grow">
    <Loader v-if="loading" />
    <EmptyPagePlaceholder pageType="finance" title="Финансы" v-if="!orders.length && !loading" />
    <UTable v-else colspan="5" :callback="loadOrders">
      <template #thead>
        <tr>
          <th>Время</th>
          <th>Назначение платежа</th>
          <th>Статус</th>
          <th>
            <p>Платежи</p>
            <p class="font-normal text-xs1 left-3 text-grey mt-1">Доступно/Всего</p>
          </th>
          <th>Сумма</th>
        </tr>
      </template>

      <template #tbody>
        <tr v-for="order in orders" :key="order.id">
          <td>
            <p class="break-normal">{{ formatDate(order.created, true) }}</p>
            <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatTime(order.created) }}</p>
          </td>
          <td>
            <router-link class="overflow-auto" :to="'/finance/order/' + order.id">
              <b><img src="/icons/orders.svg" alt="Заказ" class="h-4 align-top inline-block" /> Реестр заказов №{{ order.id }}</b>
              <p class="font-normal text-xs1 left-3 text-grey mt-1 break-words">{{ order.name }}</p>
            </router-link>
          </td>
          <td>
            <span
              :class="{
                '!border-lightGreen, !text-success': order.status === 'finished' || order.status === 'pending_payment',
                '!border-lightGreen, !text-accent':
                  order.status === 'awaiting_signing' || order.status === 'in_work' || order.status === 'active',
                '!border-lightRed, !text-error opacity-40': order.status === 'cancelled'
              }"
              class="status-item"
              :style="{
                backgroundColor: getStatusColor(order.status)
              }"
              >{{ getStatus(order.status) }}</span
            >
          </td>
          <td>
            <BillInfo :orderId="order.id" />
          </td>
          <td>
            <div class="flex">
              <UTooltip v-if="order.withholding_tax" percent class="!static mr-3 self-center" :tooltip="d['payment-fee']" />
              <span class="whitespace-nowrap"> {{ formatPrice(order.budget) }}&nbsp;₽ </span>
            </div>
          </td>
        </tr>
      </template>
    </UTable>
  </div>
</template>

<style lang="postcss">
.finance-orders {
  @apply flex flex-col overflow-hidden;
}

.status-item {
  @apply text-white whitespace-nowrap font-normal leading-5 rounded text-sm py-1 px-[6px] inline-block border border-current w-fit text-center;
}
</style>
