<template>
  <main-page-header title="Сотрудники" class="page-title">
    <router-link :to="{ name: 'personal.add.new' }">
      <UButton label="Пригласить сотрудника" class="mr-2" />
    </router-link>
  </main-page-header>
  <PersonalListContent />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import PersonalListContent from './PersonalListContent.vue'
import { UButton } from 'unit-uikit'

export default defineComponent({
  name: 'personal',
  components: {
    MainPageHeader,
    UButton,
    PersonalListContent
  }
})
</script>

<style lang="postcss" scoped>
.workers-add-title {
  @apply text-sm text-grey1 font-medium h-5 leading-5 align-top -mt-10 mb-5;
}
</style>
