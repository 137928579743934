<template>
  <div v-if="companyInfo">
    <div class="flex gap-3 mb-6">
      <UCard
        :value="`${formatPrice(companyInfo.company_deposit)}&nbsp;₽`"
        label="Депозит компании"
        tooltip="Сумма на счету компании в данный момент времени"
      />
      <UCard
        :value="`${formatPrice(companyInfo.total_reserve)}&nbsp;₽`"
        label="Резерв всех заказов (в т.ч.налог + %)"
        tooltip="Сумма, на которую создан реестр и распределены неоплаченные заказы"
      />
      <UCard
        :value="`${formatPrice(getFreeBalance() || 0)}&nbsp;₽`"
        label="Свободный остаток"
        tooltip="Сумма, в рамках которой возможно создать реестр и распределить заказы"
      />
    </div>
    <h2 class="order-title mb-4">Бюджет заказа {{ budget.computedValue }}</h2>
    <div class="form flex items-stretch mb-4">
      <div class="flex flex-col justify-between min-w-[375px] lg:max-w-[450px] !overflow-visible px-0">
        <div class="lg:mr-4 relative mb-3">
          <UInput
            class="!max-w-none !w-auto"
            :value="budget.workersAmount"
            v-model="budget.workersAmount"
            :blur="countBlurWorkerAmount"
            placeholder="Кол-во исполнителей"
            label="Количество исполнителей"
            autocomplete="off"
            tooltip="Обязательное поле для расчета деталей бюджета"
            mask="positiveNumber"
          />
        </div>
        <div class="lg:mr-4 relative mb-3">
          <UInput
            id="allWorkersSum"
            class="!max-w-none !w-auto"
            :value="formatPrice(allWorkersSum)"
            v-model="allWorkersSum"
            placeholder="0,00 ₽"
            label='Всем исполнителям "на руки"'
            mask="valueCurrency"
            :showCurrency="true"
          />
        </div>
        <div class="flex w-full">
          <div class="mr-4 relative mb-3 w-2/4">
            <UInput
              class="!max-w-none !w-auto"
              :value="formatPrice(oneWorkerSum)"
              v-model="oneWorkerSum"
              placeholder="0,00 ₽"
              label='Исполнителю "на руки"'
              mask="valueCurrency"
              :showCurrency="true"
            />
          </div>
          <div class="lg:mr-4 relative mb-3 w-2/4">
            <UInput
              class="!max-w-none !w-auto"
              :value="formatPrice(workerWithTax)"
              placeholder="0,00 ₽"
              label="Исполнителю с налогом"
              mask="valueCurrency"
              :showCurrency="true"
              :disabled="true"
              tooltip="Конечная сумма, которую необходимо заплатить исполнителю. Отображается в чеке"
            />
          </div>
        </div>
        <div class="lg:mr-4 relative">
          <UInput
            id="orderBudgetPlusTaxes"
            class="!max-w-none !w-auto"
            :value="formatPrice(budgetPlusTaxes)"
            v-model="budgetPlusTaxes"
            placeholder="0,00 ₽"
            label="Бюджет заказа включая налог и %"
            mask="valueCurrency"
            :showCurrency="true"
            :disabled="true"
            tooltip="Необходимо пополнить р/с компании на данную сумму"
          />
          <p v-if="isOverDraftCompany" class="overdraft-text">Превышен свободный остаток бюджета компании!</p>
          <p v-if="isOverDraft" class="overdraft-text"></p>
        </div>
      </div>
      <div class="form__order-details">
        <div class="form__order-details--title">Детали бюджета</div>
        <ul class="form__order-details--list flex">
          <li class="form__order-details--list__item">
            <h6 class="form__order-details--list__item--label">Налог самозанятого 6%</h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(workerTax) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item">
            <h6 class="form__order-details--list__item--label">
              Комиссия платформы {{ formatPercentage(companyInfo.unitpay_commission) }}%
            </h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(platformComission) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item">
            <h6 class="form__order-details--list__item--label">Итого на исполнителя (налог + %)</h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(outcomeTax) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item">
            <h6 class="form__order-details--list__item--label">Всего налогов самозанятых 6%</h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(allWorkersTaxes) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item">
            <h6 class="form__order-details--list__item--label">
              Всего комиссия платформы {{ formatPercentage(companyInfo.unitpay_commission) }}%
            </h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(finalPlatformCommision) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item">
            <h6 class="form__order-details--list__item--label">Итого налог + % на всех</h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(fullOutcomeTax) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item form__order-details--list__item--black">
            <h6 class="form__order-details--list__item--label !font-bold">Бюджет объекта</h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(companyInfo.object_budget) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item form__order-details--list__item--black">
            <h6 class="form__order-details--list__item--label !font-bold">Резерв бюджета объекта</h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(companyInfo.object_reserve) }}&nbsp;₽</p>
          </li>
          <li class="form__order-details--list__item form__order-details--list__item--black">
            <h6 class="form__order-details--list__item--label !font-bold">Свободный остаток бюджета объекта</h6>
            <p class="form__order-details--list__item--sum">{{ formatPrice(freeBalance) }}&nbsp;₽</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Loader v-else></Loader>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { UCard, UInput } from 'unit-uikit'
import axios from 'axios'
import { API } from '@/utils/API'
import Loader from '@/components/Loader/Loader.vue'
import { formatPrice } from '@/utils/helpers'

export default defineComponent({
  components: {
    Loader,
    UCard,
    UInput
  },
  props: {
    order: { type: Object as any },
    budget: { type: Object as any },
    companyObject: { type: Object as any },
    specialErrors: { type: Object as any }
  },
  data() {
    return {
      companyInfo: null as any,
      // store budet inputs states
      budgetInputsBudgetPlusTaxes: false,
      budgetInputsOneWorkerSum: false,
      budgetInputsAllWorkersSum: false,
      // end states
      allWorkersSum: null as any,
      oneWorkerSum: null as any,
      budgetPlusTaxes: null as any
    }
  },
  methods: {
    formatPercentage(value: any) {
      if (value) {
        return ((value * 100).toFixed(2) + '').replace('.', ',')
      }
      return '0,00'
    },
    formatPrice,
    getFreeBalance() {
      return this.companyInfo.company_deposit - this.companyInfo.total_reserve
    },
    countBlurWorkerAmount() {
      if (this.budget.workersAmount === '') {
        this.budget.workersAmount = 1
      }
    }
  },
  created() {
    const objectId = this.order.object
    axios
      .get(API.GET_FINANCIAL_INFO(objectId))
      .then((response: any) => {
        this.companyInfo = response.data
        this.companyObject.info = response.data

        this.allWorkersSum = this.budget.allWorkersSum || null
        this.oneWorkerSum = this.budget.oneWorkerSum || null
        this.budgetPlusTaxes = this.budget.budgetPlusTaxes || null
        if (this.budgetPlusTaxes && !this.allWorkersSum && !this.oneWorkerSum) {
          const clearBudget =
            this.budget.budgetPlusTaxes * (1 - this.companyInfo.unitpay_commission) * (1 - this.companyInfo['self-employed_tax'])

          // Всем исполнителям на руки
          const allWorkersSum = clearBudget || 0
          this.allWorkersSum = allWorkersSum.toFixed(2)
          // Исполнтелю на руки
          this.oneWorkerSum = (allWorkersSum / this.budget.workersAmount).toFixed(2)
        }
      })
      .catch((e: any) => {
        console.error(e)
      })
  },
  computed: {
    isOverDraftCompany() {
      const _value = this.budget.budgetPlusTaxes - (this.companyInfo.company_deposit - this.companyInfo.total_reserve) > 0
      this.specialErrors.isOverDraftCompany = _value
      return _value
    },
    isOverDraft() {
      this.specialErrors.overdraft = this.budget.budgetPlusTaxes - this.freeBalance > 0
      return this.budget.budgetPlusTaxes - this.freeBalance > 0
    },
    workerWithTax() {
      const amount = +this.clearSoloBudget + +this.workerTax
      const workerWithTax = amount ? amount.toFixed(2) : 0
      this.budget.workerWithTax = workerWithTax
      return workerWithTax
    },
    outcomeTax() {
      // “Итого на исполнителя (налог + %)”=((Исполнителю “на руки“)/(0,94))/(0,9525) - ставка комиссии юнит (4,75% или иная привязана к профилю заказчика)
      if (this.companyInfo && this.clearSoloBudget) {
        // const tax = this.clearSoloBudget / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)
        const tax = this.budget.budgetPlusTaxes / this.budget.workersAmount
        const fullTaxes = tax ? tax.toFixed(2) : 0
        this.budget.fullTaxes = fullTaxes
        return fullTaxes
      }
      return 0
    },
    fullOutcomeTax() {
      // (Итого налог + % на всех)=(Всего налогов самозанятых 6%)+(Всего комиссия платформы 4,75%)
      return (+this.allWorkersTaxes + +this.finalPlatformCommision).toFixed(2)
    },
    platformComission() {
      // (Комиссия платформы 4,75%)=“Итого на исполнителя (налог + %)”*0,0475
      if (this.outcomeTax && this.companyInfo) {
        // @ts-ignore
        const commision = this.outcomeTax * this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    finalPlatformCommision() {
      // (Всего комиссия платформы 4,75%)=(((Всем исполнителям “на руки”)/(0,94))/(0,9525))*0,0475
      if (this.companyInfo && this.clearBudget) {
        const commision =
          // @ts-ignore
          (this.clearBudget / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)) *
          this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    workerTax() {
      // (Налог самозанятого 6%)=((Исполнителю “на руки“)/0,94)*0,06
      if (this.companyInfo && this.clearSoloBudget) {
        // @ts-ignore
        const tax = (this.clearSoloBudget / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return tax ? tax.toFixed(2) : 0
      }
      return 0
    },
    allWorkersTaxes() {
      // (Всего налогов самозанятых 6%)=((Всем исполнителям “на руки”)/0,94)*0,06
      if (this.clearBudget) {
        // @ts-ignore
        const taxes = (this.clearBudget / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return taxes ? taxes.toFixed(2) : 0
      }
      return 0
    },
    freeBalance() {
      return this.companyInfo ? this.companyInfo.object_budget - this.companyInfo.object_reserve : 0
    },
    clearBudget() {
      return this.budget.allWorkersSum ? (this.budget.allWorkersSum + '').replaceAll(' ', '').replaceAll(',', '.') : 0
    },
    clearSoloBudget() {
      return this.budget.oneWorkerSum ? (this.budget.oneWorkerSum + '').replaceAll(' ', '').replaceAll(',', '.') : 0
    }
  },
  watch: {
    allWorkersSum(value) {
      if (this.budgetInputsAllWorkersSum) {
        this.budgetInputsAllWorkersSum = false
        return
      }
      const clearValue = parseFloat((value + '').replaceAll(' ', '').replaceAll(',', '.'))
      if (+this.budget.allWorkersSum === +clearValue) {
        return
      }
      this.budgetInputsBudgetPlusTaxes = true
      this.budgetInputsOneWorkerSum = true
      this.budgetInputsAllWorkersSum = false

      this.budget.allWorkersSum = clearValue
    },
    oneWorkerSum(value) {
      if (this.budgetInputsOneWorkerSum) {
        this.budgetInputsOneWorkerSum = false
        return
      }
      const clearValue = parseFloat((value + '').replaceAll(' ', '').replaceAll(',', '.'))
      if (+this.budget.oneWorkerSum === clearValue) {
        return
      }
      this.budgetInputsBudgetPlusTaxes = true
      this.budgetInputsOneWorkerSum = false
      this.budgetInputsAllWorkersSum = true

      this.budget.oneWorkerSum = clearValue
    },
    budgetPlusTaxes(value) {
      if (this.budgetInputsBudgetPlusTaxes) {
        this.budgetInputsBudgetPlusTaxes = false
        return
      }
      const clearValue = parseFloat((value + '').replaceAll(' ', '').replaceAll(',', '.'))
      if (+this.budget.budgetPlusTaxes === clearValue) {
        return
      }
      this.budgetInputsBudgetPlusTaxes = false
      this.budgetInputsOneWorkerSum = true
      this.budgetInputsAllWorkersSum = true

      this.budget.budgetPlusTaxes = clearValue
    },
    'budget.oneWorkerSum'() {
      if (this.budgetInputsOneWorkerSum) {
        const clearValue = (this.budget.oneWorkerSum + '').replaceAll('.', ',')
        this.oneWorkerSum = clearValue || 0
        return
      }

      if (this.companyInfo) {
        // Всем исполнителям на руки
        this.budget.allWorkersSum = (this.budget.oneWorkerSum * this.budget.workersAmount).toFixed(2)
        // Бюджет заказа
        const budget = this.budget.allWorkersSum / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)
        this.budget.budgetPlusTaxes = budget.toFixed(2)
      }
    },
    'budget.allWorkersSum'() {
      if (this.budgetInputsAllWorkersSum) {
        const clearValue = (this.budget.allWorkersSum + '').replaceAll('.', ',')
        this.allWorkersSum = clearValue || 0
        return
      }

      if (this.companyInfo) {
        // Исполнтелю на руки
        this.budget.oneWorkerSum = (this.budget.allWorkersSum / this.budget.workersAmount).toFixed(2)
        // Бюджет заказа
        const budget = this.budget.allWorkersSum / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)
        this.budget.budgetPlusTaxes = budget.toFixed(2)
      }
    },
    'budget.budgetPlusTaxes'() {
      // (Бюджет заказа, включая налог +% )=(Всем исполнителям “на руки”)*(1+0,06)*(1+0,0475)

      if (this.budgetInputsBudgetPlusTaxes) {
        const clearValue = (this.budget.budgetPlusTaxes + '').replaceAll('.', ',')
        this.budgetPlusTaxes = clearValue || 0
        return
      }

      if (this.companyInfo) {
        const clearBudget =
          this.budget.budgetPlusTaxes * (1 - this.companyInfo.unitpay_commission) * (1 - this.companyInfo['self-employed_tax'])

        // Всем исполнителям на руки
        const allWorkersSum = clearBudget || 0
        this.budget.allWorkersSum = allWorkersSum.toFixed(2)
        // Исполнтелю на руки
        this.budget.oneWorkerSum = (this.budget.allWorkersSum / this.budget.workersAmount).toFixed(2)
      }
    },
    'budget.workersAmount'() {
      this.budgetInputsBudgetPlusTaxes = true
      this.budgetInputsOneWorkerSum = true
      this.budgetInputsAllWorkersSum = true

      if (this.companyInfo && this.budget.oneWorkerSum && this.budget.workersAmount) {
        const budget = this.budget.budgetPlusTaxes * (1 - this.companyInfo.unitpay_commission) * (1 - this.companyInfo['self-employed_tax'])
        this.budget.allWorkersSum = budget.toFixed(2)
        this.budget.oneWorkerSum = (this.budget.allWorkersSum / this.budget.workersAmount).toFixed(2)
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.form {
  @apply flex w-full !overflow-visible !flex-col lg:!flex-row gap-y-4 lg:gap-y-0;

  &__order-details {
    @apply shadow-main rounded-lg bg-white;
    padding: 12px 24px 8px;
    margin-bottom: 5px;

    &--list {
      @apply flex-wrap flex items-stretch;

      &__item {
        @apply w-1/3 mb-2 flex flex-col;

        &--black {
          h6 {
            @apply text-black not-italic font-normal text-xs1;
          }

          p {
            @apply text-black not-italic font-normal text-md leading-1.1;
          }
        }

        &--label {
          @apply leading-4 pointer-events-none not-italic text-grey text-xs1;
        }

        &--sum {
          @apply text-md leading-1.1 text-grey mt-auto;
        }
      }
    }

    &--title {
      @apply text-black not-italic font-medium leading-snug mb-3 text-lg;
    }
  }

  &__input-label {
    @apply top-1 left-2 text-xs text-grey1 font-semibold;
  }
}

.overdraft-text {
  @apply font-main not-italic font-normal text-xs text-error pt-1.5;
}

.total-price :deep(.price-item__title) {
  @apply font-bold;
}
</style>
