<template>
  <div class="close-edit-menu hidden" @click="closeEditMenu"></div>

  <Loader v-if="loading" />
  <div class="flex mb-2" v-if="documents && !loading">
    <documents-filter @filter="fetchFilter" @find="findDocumentsByFilter" @clear="clearFilters" />
  </div>

  <div class="absolute h-full flex justify-center items-center w-[calc(100%-240px)] z-50" v-if="isFilterFetch">
    <img class="w-4 h-4 animate-spin" src="/icons/status_wait.svg" alt="wait_icon" />
  </div>

  <EmptyPagePlaceholder pageType="documents" title="Документы" v-if="!documents.length && !loading && !isFilterFetch" />
  <UTable class="min-h-[300px] lg:min-h-[auto]" v-else-if="documents.length && !loading" colspan="6"
    :callback="loadDocuments">
    <template #thead>
      <tr>
        <th>Тип документа</th>
        <th>Исполнитель</th>
        <th>Номер и наименование реестра заказов</th>
        <th>Статус</th>
        <th>Дата создания</th>
        <th></th>
      </tr>
    </template>
    <template #tbody>
      <tr v-for="doc in documents" :key="doc.id">
        <td>
          <div class="flex gap-x-4">
            <a class="notification-link flex self-center" target="_blank" :href="doc.file" :download="doc.name">
              <div :class="{
                '!-right-20': doc.category === 'document' || doc.category === 'agreement' || doc.category === 'task_order',
                '!-right-14': doc.category === 'receipt' || doc.category === 'acceptance_certificate'
              }" class="tooltip-text">
                {{ getTooltipForDoc(doc.category) }}
              </div>
              <img :class="{ 'relative top-1 scale-[1.63]': doc.category === 'acceptance_certificate' }"
                class="hover:opacity-70 w-7 h-7" :src="`/icons/${getIconForDoc(doc.category)}`"
                :alt="getTooltipForDoc(doc.category)" />
            </a>
            <template v-if="doc.certificate">
              <a class="notification-link flex self-center" target="_blank" :download="doc.certificate.name"
                :href="doc.document_certificate_file">
                <div class="tooltip-text !-right-40">Скачать сертификат</div>
                <img class="hover:opacity-70 w-8 h-8" src="/icons/cert.svg" alt="Сертификат" />
              </a>
            </template>
          </div>
        </td>
        <td>
          <b>{{ formatName(doc.worker) }}</b>
          <p class="font-normal text-xs1 left-3 text-grey mt-1">
            {{ doc.worker && doc.worker?.username }}
          </p>
        </td>
        <td>
          <span v-if="doc.order.length === 0 && doc.suborder.length === 0">Нет заказа</span>
          <div v-else>
            <a :href="`/order/${doc.order[0].id}`" target="_blank">
              <b>#{{ doc.order && doc.order[0] && doc.order[0].id }} {{ doc.order && doc.order[0] && doc.order[0].name
              }}</b></a>
            <p class="font-normal text-xs1 left-3 text-grey mt-1">
              #{{ doc.suborder && doc.suborder[0] && doc.suborder[0].id }}
              {{ getTitleForSuborder(doc.suborder && doc.suborder[0]) }}
            </p>
          </div>
        </td>
        <td :class="getDocClass(doc.status)">
          <div v-if="doc.status === 'revoked'" class="notification-link">
            Отозван
            <span class="tooltip-text hover:cursor-pointer"> Документ был отозван по инициативе заказчика </span>
          </div>
          <span v-else class="break-normal">{{ formatDocStatus(doc.status) }}</span>
        </td>
        <td>
          <p class="break-normal">{{ formatDate(doc?.created, true) }}</p>
          <p class="font-normal text-xs1 left-3 text-grey mt-1">{{ formatTime(doc?.created) }}</p>
        </td>
        <td>
          <button v-if="doc.category === 'document' && doc.status === 'not_signed'" type="button"
            class="edit-btn relative" @click="openEditMenu(doc.id)">
            <img src="/icons/extra.svg" alt="Редактировать" />
            <div v-if="doc.showEditMenu" class="edit-menu flex flex-col">
              <button type="button" class="btn-transparent" @click="revokeDoc(doc.id)">Отозвать</button>
            </div>
          </button>
        </td>
      </tr>
    </template>
  </UTable>
  <UModal :show="showPopup" @cancel="showPopup = false" title="Создание выгрузки">
    <template #content>
      <p class="leading-5">Выгрузка документов будет выполнена в соответствии с заданными фильтрами и доступна в Истории
        экспорта</p>
      <UInput class="mb-2 !w-full !max-w-full" placeholder="Название" label="Название выгрузки" :value="uploadName"
        v-model="uploadName" />
    </template>
    <template #buttons>
      <div class="flex">
        <UButton class="w-full mr-3" label="Отмена" color="secondary" @click.prevent="showPopup = false" />
        <UButton class="w-full" label="Создать" :disabled="creatingInProcess" @click.prevent="createUploads" />
      </div>
    </template>
  </UModal>

  <UModal :show="showPopupSignUploadDocs" @cancel="clearUploadDocForm(false)" title="Подписать и отправить документы">
    <template #content>
      <h2>Заполните необходимые поля и загрузите документ в формате PDF.</h2>
      <div class="relative">
        <UInput maxlength="255" class="mb-2" placeholder="Название документа" :value="docName" v-model="docName" />
        <div class="symbols-left" :class="{ error: docName.length > 100 }">{{ docName.length || 0 }} / 100</div>
      </div>

      <div class="relative">
        <UMultiselect class="upload-docs-select" v-model="selectedWorker" :options="workersList"
          placeholder="Выбор исполнителя" :multiple="false" :searchable="true" :close-on-select="true" label="last_name"
          track-by="username">
          <template #singleLabel="{ option }">{{
            `${option.last_name} ${option.first_name} ${option.middle_name ? option.middle_name : ''} `
          }}</template>
          <template #option="{ option }">
            <p class="mb-2">{{ `${option.last_name} ${option.first_name} ${option.middle_name ? option.middle_name : ''}`
            }}</p>
            <div class="flex text-grey text-xs">
              <span class="!ml-0">{{ option.personaldata?.inn }}</span>
              <span class="!ml-5">{{ option.username }}</span>
            </div>
          </template>
          <template #noOptions>Список исполнителей пуст</template>
          <template #noResult>Ничего не найдено</template>
        </UMultiselect>
        <span class="absolute top-0 w-full h-full bg-white/70 cursor-not-allowed" v-if="loadWorkersList">
          <img class="animate-spin top-3 left-60 absolute" src="/icons/update.svg" />
        </span>
      </div>
      <UFileDrop class="mb-10" @add-file="addFile" accept-types="application/pdf" />
      <UUploadProgress :class="{ 'mb-9': !fileNameError }" v-if="fileName" :label="fileName" :percent="100" />
      <p class="text-error font-medium" v-if="fileNameError">
        Пожалуйста, загрузите документ в формате .pdf. Документ в других форматах подписать невозможно.
      </p>
      <p class="text-error font-medium" v-else-if="fileSizeError">
        Размер выбранного файла превышает {{ maxImgFileSize }} МБ. Пожалуйста, загрузите другой файл.
      </p>
      <p class="text-error font-medium" v-else-if="fileReadError">Не удаётся прочитать файл. Убедитесь, что документ
        корректный</p>
    </template>
    <template #buttons>
      <div class="flex gap-5">
        <UButton class="w-full" label="Отмена" color="secondary" @click.prevent="clearUploadDocForm(false)" />
        <UButton :disabled="disableSign" class="w-full" @click="signDoc()" label="Подписать и отправить" />
      </div>
    </template>
  </UModal>

  <UModal :show="successModalShow" @success="clearUploadDocForm(true)" @cancel="clearUploadDocForm(true)"
    title="Успешно!">
    <template #content>
      <p class="mb-7 break-words">Документ "{{ docName }}" был успешно подписан и отправлен. Вы можете продолжить работу.
      </p>
    </template>
  </UModal>

  <UModal :show="successCreated" @cancel="successCreated = false" @success="() => {
    $router.push('/documents/history')
  }
    " title="Выборка успешно создана!" successLabel="Перейти к истории экспорта">
  </UModal>

  <UModal :show="failedcreate" @cancel="() => {
    failedcreate = false
  }
    " @success="failedcreate = false" title="Извините, что-то пошло не так!">
    <template #content>
      <p class="leading-5">Попробуйте повторить попытку позже или свяжитесь со службой поддержки</p>
    </template>
  </UModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DocumentsFilter from '@/components/Filters/DocumentFilter.vue'
import * as Sentry from '@sentry/browser'
import Loader from '@/components/Loader/Loader.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import dayjs from 'dayjs'
import axios from 'axios'
import { API } from '@/utils/API'
import { DOCS_STATUS } from '@/utils/consts'
import { checkFileSize, formatName, formatTime, formatDate } from '@/utils/helpers'
import { EDocsStatus } from '@/types/api-values'
import { UInput, UButton, UTable, UModal, UMultiselect, UFileDrop, UUploadProgress } from 'unit-uikit'
import { mapStores } from 'pinia'
import { useUiStore } from '@/stores/ui'
import jsonToFormData from '@/utils/jsonToForm'
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  components: {
    UModal,
    UTable,
    UInput,
    UButton,
    UMultiselect,
    UFileDrop,
    UUploadProgress,
    EmptyPagePlaceholder,
    DocumentsFilter,
    Loader
  },
  data() {
    return {
      loading: true,
      isFilterFetch: false,
      loadWorkersList: false,
      startFiltering: false,
      v$: useValidate(),
      successCreated: false,
      failedcreate: false,
      showPopupSignUploadDocs: false,
      showPopup: false,
      filterData: {} as any,
      searchString: '',
      workerSecondName: '',
      documents: [] as any,
      filterDates: {
        start_date: null,
        finish_date: null
      },
      selectedDocType: [],
      selectedDocStatus: [],
      workersList: [] as any,
      selectedWorker: null as any,
      successModalShow: false,
      file: null as any,
      fileName: '',
      docName: '',
      uploadName: '',
      fileNameError: false,
      fileReadError: null as any,
      creatingInProcess: false,
      maxImgFileSize: 32,
      fileSizeError: false,
      scrollData: {
        count: 0,
        size: 20,
        page: 1
      }
    }
  },
  created() {
    this.loading = true
    this.startFiltering = false
    this.getAllDocs()

    this.loadWorkersList = true
    axios
      .get(API.ORDER_PREINVITED_WORKERS + '?count=1000&complete_data=true&personaldata__is_self_employed=true&verified=true')
      .then((res: any) => {
        const documents = res.data.results
        this.workersList.push(...documents)
        this.loading = false
        this.loadWorkersList = false
      })
      .catch((err: any) => {
        console.error(err)
        this.loading = false
        this.loadWorkersList = false
      })
  },
  methods: {
    formatDateForQueryString(date: string): string {
      const [day, month, year] = date.split('.')
      const formattedDate = `${year}-${month}-${day}`
      return formattedDate
    },
    getAllDocs() {
      axios
        .get(API.GET_ALL_DOCS + `?page_size=${this.scrollData.size}&page=${this.scrollData.page}`)
        .then((res: any) => {
          this.scrollData.count = res.data?.count || 0
          this.documents.push(
            ...res.data.results.filter((doc: any) => doc.category !== 'end_work_notification' && doc.category !== 'start_work_notification')
          )
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
          Sentry.captureException(e)
        })
    },
    formatTime,
    formatDate,
    formatName,
    checkFileSize,
    addFile(file: File) {
      this.fileReadError = false
      this.fileNameError = false
      this.fileSizeError = false

      this.fileName = file.name
      this.file = file

      this.fileNameError = !file.type.includes('pdf')

      const error = checkFileSize(file, this.maxImgFileSize)

      if (error) {
        this.fileSizeError = true
        return
      }

      if (this.fileNameError) {
        return
      }

      const reader = new FileReader()
      reader.onload = (e: Event) => {
        const _file = (e.target as FileReader)?.result
        const regex = new RegExp('%PDF-1.[0-7]')
        let data = (_file as string).substring(0, 8)
        if (!data.match(regex)) {
          this.fileReadError = true
          return
        }
        if (!((_file as string).lastIndexOf('%%EOF') > -1)) {
          this.fileReadError = true
          return
        }
      }
      try {
        reader.readAsText(file)
      } catch (error) {
        console.error('read file error', error)
        this.fileReadError = true
        return
      }
    },
    openEditMenu(id: string | number) {
      this.documents = this.documents.map((item: any) => ({
        ...item,
        showEditMenu: id + '' === item.id + '' ? !item.showEditMenu : false
      }))
    },
    closeEditMenu() {
      this.documents.forEach((item: any) => {
        item.showEditMenu = false
      })
    },
    clearUploadDocForm(isDocUploaded: boolean) {
      this.selectedWorker = null
      this.fileName = ''
      this.docName = ''
      this.showPopupSignUploadDocs = false
      this.successModalShow = false

      if (isDocUploaded) {
        window.location.reload()
      }
    },
    signDoc() {
      this.showPopupSignUploadDocs = false
      this.loading = true

      const formData = jsonToFormData({
        file: this.file,
        file_name: this.docName,
        worker: this.selectedWorker.personaldata.user
      })

      axios
        .post(API.SIGN_DOCS, formData)
        .then(() => {
          this.successModalShow = true
          this.loading = false
        })
        .catch((err: any) => {
          console.error(err)
          this.successModalShow = false
          this.loading = false
        })
    },
    revokeDoc(id: string | number) {
      this.loading = true
      axios
        .post(API.REVOKE_DOC(id), { status: 'revoked' })
        .then(() => {
          this.loading = false
          window.location.reload()
        })
        .catch((err: any) => {
          console.error(err)
          this.loading = false
          window.location.reload()
        })
    },

    async loadDocuments(isFilter: boolean = false) {
      if (isFilter) {
        this.isFilterFetch = true
      }
      if (this.documents.length < this.scrollData.count) {
        this.scrollData.page++
        let queryString = `?page_size=${this.scrollData.size}&page=${this.scrollData.page}`

        if (this.startFiltering) {
          for (const key in this.filterData) {
            let additionalQuery = `&${key}=${this.filterData[key]}`

            if (key === 'start_date') {
              const formattedDate = dayjs(this.filterData[key]).format('DD.MM.YYYY')
              additionalQuery = `&start_date=${this.formatDateForQueryString(formattedDate)}T00:00:00`
            }

            if (key === 'finish_date') {
              const formattedDate = dayjs(this.filterData[key]).format('DD.MM.YYYY')
              additionalQuery = `&end_date=${this.formatDateForQueryString(formattedDate)}T23:59:59.99`
            }

            if (key === 'worker_phone') {
              additionalQuery = `&worker_phone=${this.filterData[key].replace('+', '').replaceAll('-', '')}`
            }

            if (key === 'selectedDocType') {
              const categories = this.filterData.selectedDocType
                .map((type: any) => {
                  return type.value
                })
                .join(',')
              additionalQuery = `&category=${categories}`
            }

            if (key === 'selectedDocStatus') {
              const categories = this.filterData.selectedDocStatus
                .map((type: any) => {
                  return type.value
                })
                .join(',')
              additionalQuery = `&status=${categories}`
            }

            if (key === 'worker_first_name') {
              const surname = this.filterData[key].replaceAll(' ', '%20')
              additionalQuery = `&worker_first_name=${surname}`
            }

            if (key === 'worker_last_name') {
              const surname = this.filterData[key].replaceAll(' ', '%20')
              additionalQuery = `&worker_last_name=${surname}`
            }

            if (!additionalQuery.endsWith('=')) {
              queryString += additionalQuery
            }
          }
        }
        const response = await axios.get(API.GET_ALL_DOCS + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.scrollData.count = data.count || 0
          this.documents.push(
            ...data.results.filter((doc: any) => doc.category !== 'end_work_notification' && doc.category !== 'start_work_notification')
          )
          this.loading = false
          this.isFilterFetch = false
        }
        this.isFilterFetch = false
      }
    },
    createUploads() {
      this.creatingInProcess = true
      let queryString = 'page_size=10000'

      let periodBegin = null
      let periodEnd = null
      for (const key in this.filterData) {
        let additionalQuery = `&${key}=${encodeURIComponent(this.filterData[key])}`

        if (key === 'start_date') {
          const formattedDate = dayjs(this.filterData[key]).format('DD.MM.YYYY')
          periodBegin = this.formatDateForQueryString(formattedDate)
          additionalQuery = `&start_date=${periodBegin}T00:00:00`
        }

        if (key === 'finish_date') {
          const formattedDate = dayjs(this.filterData[key]).format('DD.MM.YYYY')
          periodEnd = this.formatDateForQueryString(formattedDate)
          additionalQuery = `&end_date=${periodEnd}T23:59:59.99`
        }

        if (key === 'selectedDocType') {
          const categories = this.filterData.selectedDocType
            .map((type: any) => {
              return type.value
            })
            .join(',')
          additionalQuery = `&category=${categories}`
        }

        if (key === 'selectedDocStatus') {
          const categories = this.filterData.selectedDocStatus
            .map((type: any) => {
              return type.value
            })
            .join(',')
          additionalQuery = `&status=${categories}`
        }

        if (key === 'worker_phone') {
          additionalQuery = `&worker_phone=${this.filterData[key].replace('+', '').replaceAll('-', '')}`
        }

        if (key === 'worker_first_name') {
          const surname = this.filterData[key].replaceAll(' ', '%20')
          additionalQuery = `&worker_first_name=${surname}`
        }

        if (key === 'worker_last_name') {
          const surname = this.filterData[key].replaceAll(' ', '%20')
          additionalQuery = `&worker_last_name=${surname}`
        }
        if (!additionalQuery.endsWith('=')) queryString += additionalQuery
      }

      axios
        .post(API.SET_DOCS_EXPORT + '?' + queryString, {
          query_link: API.GET_ALL_DOCS + '?' + queryString,
          name: this.uploadName,
          document_amount: this.scrollData.count,
          period_begin: periodBegin,
          period_end: periodEnd,
          add_certificates: true,
          add_agreements: true,
          category: [],
          all: false,
          has_procuration: false
        })
        .then(() => {
          this.showPopup = false
          this.successCreated = true
          this.creatingInProcess = false
        })
        .catch((e: any) => {
          Sentry.captureException(e)
          this.failedcreate = true
          this.showPopup = false
          this.creatingInProcess = false
        })
    },
    uploadDocs() {
      const checkFields = ['start_date', 'finish_date']

      let isInvalid = false

      checkFields.forEach((field) => {
        this.v$.filterDates[field].$touch()
        isInvalid = isInvalid || this.v$.filterDates[field].$invalid
      })

      if (isInvalid) {
        return
      }

      this.uploadName = `Выгрузка_${dayjs(new Date()).format('DD.MM.YY_HH:mm:ss')}`

      this.showPopup = true
    },
    fetchFilter(data: any) {
      this.filterData = data
    },
    clearFilters() {
      this.documents = []
      this.scrollData.count = 1
      this.scrollData.page = 0
      this.filterData = {}
      this.startFiltering = false
      this.loadDocuments(true)
    },
    findDocumentsByFilter() {
      this.documents = []
      this.scrollData.count = 1
      this.scrollData.page = 0
      this.startFiltering = true
      this.loadDocuments(true)
    },
    getTitleForSuborder(suborder: any) {
      if (!suborder || !suborder.name || suborder.name.length === 0) {
        return suborder?.initial_name || ''
      }
      return suborder.name
    },
    getDocClass(status: EDocsStatus): string {
      switch (status) {
        case EDocsStatus.signed:
        case EDocsStatus.active:
          return 'text-success'
        case EDocsStatus.terminated:
        case EDocsStatus.declined_by_self_employee:
        case EDocsStatus.rejected:
        case EDocsStatus.revoked:
          return 'text-error'
        case EDocsStatus.finished:
          return 'text-gray-500'
        case EDocsStatus.not_signed:
        case EDocsStatus.exists:
          return ''
        default:
          return ''
      }
    },
    formatDocStatus(status: string | undefined): string {
      return status ? DOCS_STATUS[status.toLowerCase() as EDocsStatus] || '' : ''
    },
    getIconForDoc(docCategory: string) {
      if (docCategory === 'receipt') {
        return 'receipt.svg'
      } else if (docCategory === 'document') {
        return 'file.svg'
      } else if (docCategory === 'task_order') {
        return 'task.svg'
      } else if (docCategory === 'acceptance_certificate') {
        return 'acceptance_cert.svg'
      } else if (docCategory === 'agreement') {
        return 'agreement.svg'
      }
    },
    getTooltipForDoc(docCategory: string) {
      if (docCategory === 'receipt') {
        return 'Чек'
      } else if (docCategory === 'document') {
        return 'Документ'
      } else if (docCategory === 'task_order') {
        return 'Задание'
      } else if (docCategory === 'acceptance_certificate') {
        return 'Акт'
      } else if (docCategory === 'agreement') {
        return 'Договор'
      }
    }
  },
  watch: {
    'uiStore.showSignUploadDocsPopup'(val) {
      this.showPopupSignUploadDocs = val
    },
    showPopupSignUploadDocs(val) {
      this.uiStore.showSignUploadDocsPopup = val
    },
    'uiStore.showUploadsPopup'(val) {
      this.showPopup = val
      this.uploadName = `Выгрузка_${dayjs(new Date()).format('DD.MM.YY_HH:mm:ss')}`
    },
    showPopup(val) {
      this.uiStore.showUploadsPopup = val
      this.uploadName = `Выгрузка_${dayjs(new Date()).format('DD.MM.YY_HH:mm:ss')}`
    }
  },
  validations() {
    return {
      filterDates: {
        start_date: { required },
        finish_date: { required }
      }
    }
  },
  computed: {
    ...mapStores(useUiStore),
    disableSign() {
      return (
        !this.docName.length ||
        this.docName.length > 100 ||
        !this.selectedWorker ||
        !this.fileName ||
        this.fileNameError ||
        this.fileSizeError ||
        this.fileReadError
      )
    }
  }
})
</script>

<style lang="postcss" scoped>
:deep(.modal) {
  @apply !gap-2 overflow-auto max-h-[95%];
}

:deep(.modal .-buttons button:first-child) {
  @apply hidden;
}

.symbols-left {
  @apply absolute -bottom-1.5 right-0 text-xs text-grey;

  &.error {
    @apply text-error;
  }
}

:deep(.upload-docs-select .multiselect__option::before),
:deep(.upload-docs-select .multiselect__option--selected::before),
:deep(.upload-docs-select .multiselect__option--selected::after) {
  @apply hidden;
}

:deep(.upload-docs-select .multiselect__placeholder) {
  @apply pt-0 text-grey text-sm;
}

:deep(.multiselect__input, .multiselect__single) {
  @apply bg-white;
}

.edit-menu {
  @apply top-full right-2/4;
  transform: translate(0);

  & .btn-transparent {
    @apply min-w-max pr-3.5;
  }
}

.notification-link img {
  @apply min-w-fit;
}
</style>
